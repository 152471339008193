(function () {
	angular.module('Plania').controller('ListAccountingController', ['$scope', 'Repository', '$state', 'TranslationService', '$modal', 'ListService', 'config', '$localStorage',
		function ($scope, repository, $state, translationService, $modal, listService, config, $localStorage) {
			$scope.selected = {};
			$scope.selectedIndex = -1;
			$scope.showFilter = false;
			$scope.showViewButton = false;

			var updateAccess = function () {
				$scope.restrictEdit = !repository.authService.hasCreateAccess($state.params.entityData.prefix);
			};
			updateAccess();

			var getItems = function () {
				$scope.dimensions = [];
				var filter = {};
				var columns = [];

				columns.push("Id");
				columns.push("Description");
				columns.push("DimensionNumber");
				columns.push("DataOwner.Dimension1");
				columns.push("DataOwner.Dimension2");
				columns.push("DataOwner.Dimension3");
				columns.push("DataOwner.Dimension4");
				columns.push("DataOwner.Dimension5");
				var page = 0;
				var pageSize = 500;
				repository.GetPaginated($state.params.entityData.url, page, pageSize, { UpdatedDate: 'desc' }, filter, null, JSON.stringify(columns)).then(
					function (result) {
						if (result.TotalCount > (pageSize * (page + 1))) {
							//repeat service call in order to get all accountingdimension
						}

						result.List.forEach(function (dimension) {
							var existingDimension = _.find($scope.dimensions, function (dim) { return dim.dimensionNumber === dimension.DimensionNumber; });
							if (existingDimension)
								existingDimension.data.push(dimension);
							else {
								var data = { dimensionNumber: dimension.DimensionNumber, data: [dimension] };
								$scope.dimensions.push(data);
							}
						});

						$scope.dimensions = _.sortBy($scope.dimensions, function (dim) { return dim.dimensionNumber; });
					});
			};

			getItems();

			$scope.addAccounting = function (dimensionNumber) {
				$modal.open({
					templateUrl: "app/accounting/views/editAccounting.html",
					controller: "AccountingModalController",
					resolve: {
						modalParams: function () {
							return { DimensionNumber: dimensionNumber };
						}
					}
				}).result.then(function (responseResult) {
					getItems();
				});
			};

			$scope.updateAccounting = function (dim) {
				$modal.open({
					templateUrl: "app/accounting/views/editAccounting.html",
					controller: "AccountingModalController",
					resolve: {
						modalParams: function () {
							return {
								dim: dim,
								isLastDimension: !_.some($scope.dimensions, { 'dimensionNumber': (dim.DimensionNumber + 1) })
							};
						}
					}
				}).result.then(function (responseResult) {
					getItems();
				});
			};

			$scope.removeAccounting = function (guid) {
				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-swal-accounting-message', 'Dimensjon vil bli permanent fjernet!'),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-accounting-button-confirm', 'Ja, fjern dimensjon!'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;
					repository.deleteSingle(repository.apiData.accounting.url, guid)
						.then(function (result) {
							swal(translationService.translate('web-swal-accounting-success', 'Dimensjonen ble fjernet!'), result, "success");
							getItems();
						});
				});
			};
		}


	]);
})();
