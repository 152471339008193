(function () {
    angular.module('Plania').filter('nl2br', ['$sce', filter]);

    function filter($sce) {
        return function (msg) {
            var breakTag = '<br />';
            msg = (msg + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
            return $sce.trustAsHtml(msg);
        };
    }
})();
