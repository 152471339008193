(function () {
	angular.module('Plania').filter('plLimitTo', ['$sce', filter]);

	function filter($sce) {
		return function (text, textLimit, rowLimit) {
			if (!text || text.length === 0)
				return text;

			newText = text.substring(0, textLimit);
			var lines = newText.split(/\r\n|\r|\n|<br \/>/);
			if (rowLimit && rowLimit > 0) {
				newText = lines.splice(0, rowLimit).join('\r\n');
			}
			return $sce.trustAsHtml(newText);
		};
	}
})();
