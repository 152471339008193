(function () {
    angular.module('Plania').controller('CreateBuildingSupplierController', ['$scope', '$modalInstance', 'params', 'Repository', 'ngTableParams', '$filter', controller]);

    function controller($scope, $modalInstance, params, repository, ngTableParams, $filter) {

        var filter = {};
        if (params.excludeGuids && params.excludeGuids.length > 0)
            filter.PropertyFilter = [{ Property: 'Guid', Operator: 'notIn', Value: params.excludeGuids.join(",") }];

        $scope.supplierTable = new ngTableParams({
            page: 1,
            count: 10,
            sorting: { description: 'asc' },
            filter: filter
        },
		{
		    total: 0,
		    counts: [10, 25, 50],
		    getData: function ($defer, params) {
		        repository.GetPaginated(repository.apiData.supplier.url, params.page() - 1, params.count(), params.sorting(), params.filter()).then(
					function (result) {
					    params.total(result.TotalCount);
					    var sortedResult = params.sorting() ? $filter('orderBy')(result.List, params.orderBy()) : result.List;
					    $defer.resolve(sortedResult);
					},
					function (error) {
					    repository.growl(error, 'danger');
					});
		    }
		});

        var selectedSuppliers = [];

        $scope.saveSupplier = function () {
            repository.createMultipleWithGuid(repository.apiData.buildingSupplier.url, params.guidBuilding, selectedSuppliers).then(function (result) {
                repository.growl('Leverandør har blitt lagt til bygg', 'success');
                $modalInstance.close('OK');
            });
        };

        $scope.addSupplier = function (guid) {
            if (!_.contains(selectedSuppliers, guid))
                selectedSuppliers.push(guid);
            else {
                for (var i = 0; i < selectedSuppliers.length; i++) {
                    var p = selectedSuppliers[i];
                    if (p === guid) {
                        selectedSuppliers.splice(i, 1);
                        return;
                    }
                }
            }
        };

        $scope.isSupplierSelected = function (guid) {
            return _.contains(selectedSuppliers, guid);
        };

        $scope.cancel = function () {
            $modalInstance.dismiss('canceled');
        };
    }

})();
