(function () {
	angular.module('Plania').controller('EquipmentOperatingHoursController', ['$scope', '$modalInstance', 'params', 'Repository', controller]);

	function controller($scope, $modalInstance, params, repository) {

		if (params.Guid) {
			var columns = ['EquipmentOperatingHourType.Id'];
			repository.getMainModel(repository.apiData.equipmentOperatingHours.url, params.Guid, JSON.stringify(columns)).then(
				function(response) {
					$scope.model = response.Data;
				});
		} else {
			$scope.model = params;
		}

		$scope.openCalendar = false;

		var success = function (result) {
			if ($scope.model.Guid)
				repository.growl('Driftstimer har blitt oppdatert', 'success');
			else
				repository.growl('Driftstimer har blitt lagt til anlegget', 'success');
			$modalInstance.close('created');
		};
		var error = function (result) {
			repository.growl(result, 'danger');
		};

		$scope.save = function () {
			if ($scope.model.Guid) {
				repository.updateSingleDictionary(repository.apiData.equipmentOperatingHours.url, $scope.model).then(success, error);
			}
			else {
				repository.createSingleDictionary(repository.apiData.equipmentOperatingHours.url, $scope.model).then(success, error);
			}
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('Canceled');
		};
	}
})();
