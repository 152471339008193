(function () {
	angular.module('Plania').controller('AnonymizationDashboardController', ['$scope', 'Repository', 'TranslationService', 'ngTableParams', 'Constants', 'ListService', 'EnumService', '$filter', '$modal', 'RegisteredFieldService', controller]);

	function controller($scope, repository, translationService, ngTableParams, constants, listService, enumService, $filter, $modal, registeredFieldService) {
		$scope.viewMode = true;
		$scope.anonymizationConfiguration = null;
		$scope.isConfigured = false;
		$scope.currentTab = 'anonymizationList';
		var showAnonymizationCommentModal = true;

		$scope.model = {};
		
		repository.getSingleWithNoParameter(repository.apiData.anonymizationConfiguration.url).then(
			function (result) {
				$scope.anonymizationConfiguration = result;
				initFieldTable();

				enumService.GetEnumValues('AnonymizationJobStatus').then(function (result) {
					$scope.AnonymizationJobStatuses = result;
					$scope.selectedAnonymizationJobStatus = _.find($scope.AnonymizationJobStatuses, function(enumValue) {
						return enumValue.StringValue.toString() === $scope.anonymizationConfiguration.AnonymizationJobStatus;
					}) ;
				});

				$scope.isConfigured = $scope.anonymizationConfiguration.AnonymizationIntervalInMonths > 0 || $scope.anonymizationConfiguration.DeletionIntervalInMonths > 0;
			},
			function (error) {
				if (error === 'Ingen data funnet') {
					$scope.isConfigured = false;
					setDefaultConfiguration();
					initFieldTable();
				} else {
					repository.growl(error, 'danger');
				}
			});

		registeredFieldService.getRegisteredField(repository.commonService.prefix.Customer).then(
			function (registeredFields) {
				var anonymizationCommentRule = _.filter(registeredFields, { DomainFieldName: 'AnonymizationComment' })[0];
				if (anonymizationCommentRule && anonymizationCommentRule.Hidden)
					showAnonymizationCommentModal = false;
			});

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
		};

		$scope.getLabelFromInterval = function (value) {
			var interval = _.find(constants.defaultAnonymizationConfigurationIntervals, { months: value });
			if (interval) return interval.label;
			return value + ' måneder';
		};

		$scope.setAnonymizationStatus = function(status) {
			$scope.anonymizationConfiguration.AnonymizationJobStatus = status.StringValue;
		};

		var addColumnIfNotExists = function (columns, newColumn) {
			if (_.some(columns, function (col) { return col.Property === newColumn.Property; }))
				return;

			columns.unshift(newColumn);

			for (var i = 0; i < columns.length; i++) {
				columns[i].Position = i + 1;
			}
		};

		var addAnonymizationColumns = function(listSetup) {
			addColumnIfNotExists(listSetup.Columns, {
				Title: translationService.translate('web-anonymization-table-anonymizationDate', 'Forventet AnonymiseringsDato'),
				Property: 'ExpectedAnonymizationDate',
				PropertyType: 'date'
			});
			addColumnIfNotExists(listSetup.Columns, {
				Title: translationService.translate('web-anonymization-table-anonymizationAction', 'Anonymiseringsaksjon'),
				Property: 'AnonymizationAction',
				PropertyType: 'enum.anonymizationaction'
			});
		};

		$scope.anonymizationList = new function () {
			var me = this;
			this.apiData = angular.copy(repository.apiData.housingCustomer);
			this.apiData.contextId = planiaUtils.contextIds.anonymizationHousingCustomer;
			this.allSelected = false;
			this.selectedItems = [];

			this.initTable = function () {
				if (me.table)
					return;

				me.table = new ngTableParams({
					page: 1,
					count: 50,
					sorting: { 'ExpectedAnonymizationDate': 'desc' },
					filter: {}
				},
					{
						total: 0,
						filterDelay: 10,
						counts: [10, 20, 50],
						getData: function ($defer, params) {
							var columns = [];
							params.filter().PropertyFilter = [
								{ Property: 'AnonymizationAction', Operator: "<>", Value: 0 }
							];

							if (!me.listSetup)
								return;

							me.listSetup.Columns.forEach(function (col) {
								columns.push(col.Property);
								if (col.Filter) {
									col.Filter.forEach(function (filter) {
										params.filter().PropertyFilter.push(filter);
									});
								}
							});

							addAnonymizationColumns(me.listSetup);

							repository.GetPaginated(me.apiData.url,
								params.page() - 1,
								params.count(),
								params.sorting(),
								params.filter(),
								null,
								JSON.stringify(columns)).then(
									function (result) {
										me.table.settings().total = result.TotalCount;
										me.table.settings().filterDelay = 500;
										$defer.resolve(result.List);
									},
									function (error) {
										repository.growl(error, 'danger');
									});
						}
					});


				this.selectAllRows = function () {
					if (this.allSelected) {
						this.clearSelectedItems();
					} else {
						this.table.data.forEach(function (row) {
							if (!me.isSelected(row)) me.selectItem(row);
						});
					}
					this.allSelected = !this.allSelected;
				};

				this.clearSelectedItems = function () {
					_.remove(this.selectedItems, function () { return true; });
				};

				this.selectItem = function (item) {
					if (_.some(this.selectedItems, function (i) { return i.Guid === item.Guid; })) {
						_.remove(this.selectedItems, function (i) { return i.Guid === item.Guid; });
					} else {
						this.selectedItems.push(item);
					}
				};

				this.isSelected = function (item) {
					return _.find(this.selectedItems, function (i) { return i.Guid === item.Guid; });
				};

				this.optionsMenu = function (item) {
					var optionsMenu = [];
					optionsMenu.push({
						html: '<a class="dropdown-item" href="" tabindex="-1" pl-translate="web-anonymization-options-navigateToHousingCustomer">Gå til leietaker</a>',
						click: function () {
							$scope.navigation.go('housingCustomer.edit', { guid: item.Guid, menuGuid: $scope.navigation.params.menuGuid }, event);
						}
					});

					if ($scope.userInfo.isSystemAdministrator) {
						optionsMenu.push({
							html: '<a class="dropdown-item" href="" tabindex="-1" pl-translate="web-anonymization-options-addToExceptionList">Legg til i unntakslisten</a>',
							click: function () {
								var success = function (anonymizationComment) {
									var params = {
										AnonymizationComment: anonymizationComment,
										CustomerGuids: []
									};
									me.selectedItems.length ? me.selectedItems.forEach(function (item) { params.CustomerGuids.push(item.Guid); }) : params.CustomerGuids.push(item.Guid);
									repository.runAction(repository.apiData.customer.url + "/addHousingCustomerExceptions", params, null, true).then(
										function (result) {
											repository.growl(translationService.translate('web-anonymization-dashboard-addHousingCustomerExceptions-success', 'Kundene har blitt lagt til unntakslisten'), 'success');
											me.clearSelectedItems();
											me.table.reload();
											$scope.exceptionList.table.reload();
										},
										function (error) {
											repository.growl(error, 'danger');
										});
								};
								if (showAnonymizationCommentModal) {
									$modal.open({
										templateUrl: 'app/customer/anonymization/dashboard/addToExceptionListModal.html',
										controller: 'AnonymizationCommentModalController',
										size: 'sm',
										resolve: {
											params: function () {
												return {};
											}
										}
									}).result.then(function (anonymizationComment) {
										success(anonymizationComment);
									});
								} else {
									success();
								}
							}
						});
					}

					if (item.ExpectedAnonymizationDate) {
						if (item.AnonymizationAction === "Delete") {
							optionsMenu.push({
								html: '<a class="dropdown-item" href="" tabindex="-1" pl-translate="web-anonymization-options-deleteNow">Kjør slettingsfunksjon</a>',
								click: function () {
									// run function either on selected items, or on current one if none selected
									var guids = [];
									me.selectedItems.length ? me.selectedItems.forEach(function (item) { guids.push(item.Guid); }) : guids.push(item.Guid);
									repository.runAction(repository.apiData.customer.url + "/deleteUnusedHousingCustomers", guids).then(
										function (result) {
											repository.growl(result, 'success');
											me.table.reload();
										},
										function (error) {
											repository.growl(error, 'danger');
										});
								}
							});
						}
						if (item.AnonymizationAction === "Anonymize") {
							optionsMenu.push({
								html: '<a class="dropdown-item" href="" tabindex="-1" pl-translate="web-anonymization-options-anonymizeNow">Kjør anonymiseringsfunksjon</a>',
								click: function () {
									// run function either on selected items, or on current one if none selected
									var guids = [];
									me.selectedItems.length ? me.selectedItems.forEach(function (item) { guids.push(item.Guid); }) : guids.push(item.Guid);
									repository.runAction(repository.apiData.customer.url + "/anonymizeHousingCustomers", guids).then(
										function (result) {
											repository.growl(result, 'success');
											me.table.reload();
										},
										function (error) {
											repository.growl(error, 'danger');
										});
								}
							});
						}
					}
					return optionsMenu;
				};

				var today = moment();
				var monthFromToday = moment(today).add(1, 'months');

				this.showStatusIcon = function (item, property) {
					if (item[property]) return moment(item[property]).isBefore(monthFromToday);
					return false;
				};

				this.getStatusColor = function (item, property) {
					if (!item[property]) return null;

					if (moment(item[property]).isBefore(today)) return 'text-danger';
					if (moment(item[property]).isBefore(monthFromToday)) return 'text-warning';
				};
			};



			this.getWebListViews = function (selectedSetup, overwriteSelected) {
				listService.getWebListViews(selectedSetup, me.apiData.prefix, false, planiaUtils.contextIds.anonymizationHousingCustomer, {}).then(function (result) {
					me.listOptions = result.listOptions;

					_.each(me.listOptions, function (listSetup) {
						addAnonymizationColumns(listSetup);
					});

					me.listSetup = result.selectedListSetup;
					me.initTable();
				});
			};

			me.getWebListViews();
		}();

		$scope.exceptionList = new function () {
			var me = this;
			this.apiData = repository.apiData.customer;
			this.allSelected = false;
			this.selectedItems = [];
			this.columns = [
				{ Position: 1, Title: translationService.translate('web-anonymization-dashboard-customer-id', 'Id'), Property: 'Id', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-anonymization-dashboard-customer-description', 'Navn'), Property: 'Description', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('web-anonymization-dashboard-customer-anonymizationComment', 'Kommentar'), Property: 'AnonymizationComment', PropertyType: 'string' },
			];

			this.initTable = function () {
				if (me.table)
					return;

				me.table = new ngTableParams({
					page: 1,
					count: 50,
					sorting: { 'Id': 'desc' },
					filter: { PropertyFilter: [{ Property: 'AnonymizationAction', Operator: '=', Value: 0}]}
				},
					{
						total: 0,
						filterDelay: 10,
						counts: [10, 20, 50],
						getData: function ($defer, params) {
							var columns = $scope.exceptionList.columns.map(function (col) {
								return col.Property;
							});

							repository.GetPaginated(me.apiData.url,
								params.page() - 1,
								params.count(),
								params.sorting(),
								params.filter(),
								null,
								JSON.stringify(columns)).then(
									function (result) {
										me.table.settings().total = result.TotalCount;
										me.table.settings().filterDelay = 500;
										$defer.resolve(result.List);
									},
									function (error) {
										repository.growl(error, 'danger');
									});
						}
					});


				this.selectAllRows = function () {
					if (this.allSelected) {
						this.clearSelectedItems();
					} else {
						this.table.data.forEach(function (row) {
							if (!me.isSelected(row)) me.selectItem(row);
						});
					}
					this.allSelected = !this.allSelected;
				};

				this.clearSelectedItems = function () {
					_.remove(this.selectedItems, function () { return true; });
				};

				this.selectItem = function (item) {
					if (_.some(this.selectedItems, function (i) { return i.Guid === item.Guid; })) {
						_.remove(this.selectedItems, function (i) { return i.Guid === item.Guid; });
					} else {
						this.selectedItems.push(item);
					}
				};

				this.isSelected = function (item) {
					return _.find(this.selectedItems, function (i) { return i.Guid === item.Guid; });
				};

				this.onClick = function (item) {
					if (!item.AnonymizationComment || !showAnonymizationCommentModal) return;
					$modal.open({
						templateUrl: 'app/customer/anonymization/dashboard/addToExceptionListModal.html',
						controller: 'AnonymizationCommentModalController',
						size: 'sm',
						resolve: {
							params: function () {
								return {
									anonymizationComment: item.AnonymizationComment,
								};
							}
						}
					}).result.then(function (anonymizationComment) {
						// Use same endpoint to update comment.
						var params = {
							AnonymizationComment: anonymizationComment,
							CustomerGuids: [item.Guid]
						};
						repository.runAction(repository.apiData.customer.url + "/addHousingCustomerExceptions", params, null, true).then(
							function (result) {
								repository.growl(translationService.translate('web-anonymization-dashboard-addHousingCustomerExceptions-updated', 'Kommentaren er oppdatert'), 'success');
								me.table.reload();
							},
							function (error) {
								repository.growl(error, 'danger');
							});
					});
				};

				this.optionsMenu = function (item) {
					var optionsMenu = [];
					optionsMenu.push({
						html: '<a class="dropdown-item" href="" tabindex="-1" pl-translate="web-anonymization-options-navigateToHousingCustomer">Gå til leietaker</a>',
						click: function () {
							$scope.navigation.go('housingCustomer.edit', { guid: item.Guid, menuGuid: $scope.navigation.params.menuGuid }, event);
						}
					});

					if (!!item.AnonymizationComment) {
						optionsMenu.push({
							html: '<a class="dropdown-item" href="" tabindex="-1" pl-translate="web-anonymization-options-viewAnonymizationComment">Se kommentar</a>',
							click: function () {
								me.onClick(item);
							}
						});
					}

					if ($scope.userInfo.isSystemAdministrator) {
						optionsMenu.push({
							html: '<a class="dropdown-item" href="" tabindex="-1" pl-translate="web-anonymization-options-removeFromExceptionList">Ta bort fra unntakslisten</a>',
							click: function () {
								var guids = [];
								me.selectedItems.length ? me.selectedItems.forEach(function (item) { guids.push(item.Guid); }) : guids.push(item.Guid);
								repository.runAction(repository.apiData.customer.url + "/removeHousingCustomerExceptions", guids).then(
									function (result) {
										repository.growl(translationService.translate('web-anonymization-dashboard-removeHousingCustomerExceptions-success', 'Kundene har blitt tatt ut av unntakslisten'), 'success');
										me.clearSelectedItems();
										me.table.reload();
										$scope.anonymizationList.table.reload();
									},
									function (error) {
										repository.growl(error, 'danger');
									});
							}
						});
					}
					return optionsMenu;
				};
			};

			this.initTable();
		}();

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.getLinkToJob = function (jobId) {
			if (!$scope.mapOfJobGuidsById) {
				$scope.mapOfJobGuidsById = {};
				var columns = ['Guid', 'Id'];
				// get a map of job GUIDs keyed by id
				repository.GetPaginated(repository.apiData.scheduledJob.url, 0, 50, {}, null, null, JSON.stringify(columns))
					.then(function (result) {
						result.List.forEach(function (row) {
							$scope.mapOfJobGuidsById[row.Id] = row.Guid;
						});
					});
			}

			var guid = $scope.mapOfJobGuidsById[jobId];
			if (!guid)
				return null;
			var link = $scope.navigation.href('scheduledJob.edit', { guid: guid });
			return link;
		};

		function setDefaultConfiguration() {
			$scope.newConfiguration = false;
			var defaultConfig = constants.defaultAnonymizationConfiguration;

			$scope.anonymizationConfiguration.AnonymizationIntervalInMonths = defaultConfig.AnonymizationIntervalInMonths;
			$scope.anonymizationConfiguration.DeletionIntervalInMonths = defaultConfig.DeletionIntervalInMonths;
		}

		$scope.save = function () {
			var configuration = $scope.anonymizationConfiguration;

			repository.updateSingle(repository.apiData.anonymizationConfiguration.url, configuration).then(
				function (result) {
					repository.growl('Lagring fullført', 'success');
					$scope.toggleViewMode();
				},
				function (error) {
					repository.growl(error, 'danger');
				});
		};

		function initFieldTable() {
			$scope.fieldTable = new ngTableParams({
				page: 1,
				count: 1000 /* do not paginate here */
			},
				{
					total: 0,
					getData: function ($defer, params) {
						repository.getSingleWithNoParameter(repository.apiData.anonymizationConfiguration.url + "/resolvedFieldRules").then(
							function (result) {
								var data = params.sorting() ? $filter('orderBy')(result, params.orderBy()) : result;
								$scope.fieldTable.settings().total = data.length;
								$defer.resolve(data);
							}, function (error) {
								swal('Feil ved henting av anonymiseringsfelter', error, 'error');
								$defer.reject('Error getting anonymization fields');
							}
						);
					}
				});
		}

		$scope.defaultIntervals = constants.defaultAnonymizationConfigurationIntervals;

	}
})();

(function () {
	angular.module('Plania').controller('AnonymizationCommentModalController', ['$scope', '$modalInstance', 'params', controller]);
	function controller($scope, $modalInstance, params) {
		$scope.anonymizationComment = params.anonymizationComment;

		$scope.cancelModal = function () {
			$modalInstance.dismiss('cancelled');
		};

		$scope.okModal = function () {
			$modalInstance.close($scope.anonymizationComment);
		};
	}
})();
