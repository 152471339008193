(function () {
	angular.module('Plania').controller('EditVideoXEntityController', ['$scope', 'Repository', 'params', 'authService','$modalInstance' ,controller]);

	function controller($scope, repository, params, authService, $modalInstance) {
		$scope.update = true;
		$scope.restrictEdit = !authService.hasEditAccess('Video');

		repository.getSingle(repository.apiData.video.url, params.guid).then(
			function (response) {
			    $scope.model = response.Data;
			},
			function (error) {
			    repository.growl(error.Data ? error.Data.message : error.Message, 'danger');
			});

		$scope.save = function () {
		    repository.updateSingleDictionary(repository.apiData.video.url, $scope.model).then(
				function () {
				    repository.growl('Videoen ble lagret', 'success');
				    $modalInstance.close('Ok');
				},
				function (error) {
					repository.growl(error.Data ? error.Data.message : error.Message, 'danger');
				});
		};


		$scope.cancel = function () {
		    $modalInstance.dismiss('canceled');
		};
	}
})();
