(function () {
	angular.module('Plania').controller('NamedSelectionController', ['$scope', 'Repository', '$stateParams', 'ngTableParams', '$modal', '$filter', 'authService', 'TranslationService', '$q', controller]);

	function controller($scope, repository, $stateParams, ngTableParams, $modal, $filter, authService, translationService, $q) {
		$scope.isUpdate = $scope.navigation.current.name === 'namedSelection.edit';
		$scope.activateAutocomplete = false;
		$scope.model = { Guid: $stateParams.guid };
		$scope.namedSelectionValues = [];

		var updateUserAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDo = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !authService.hasEditAccess(repository.commonService.prefix.NamedSelection, checkOtherDo);
				$scope.restrictDelete = !authService.hasDeleteAccess(repository.commonService.prefix.NamedSelection, checkOtherDo);
			} else {
				$scope.restrictEdit = !authService.hasCreateAccess(repository.commonService.prefix.NamedSelection);
			}
		};

		updateUserAccess();

		var getSelectionValues = function () {
			var columns = ['Value', 'IndexPosition'];
			repository.GetPaginated(repository.apiData.namedSelectionValue.url, 0, 20, { 'IndexPosition': 'asc' }, { PropertyFilter: [{ Property: 'GuidNamedSelection', Operator: '=', Value: $stateParams.guid }] }, '', JSON.stringify(columns)).then(function (result) {
				$scope.namedSelectionValues = result.List;
			});
		};

		if ($scope.isUpdate) {
			repository.getSingle(repository.apiData.namedSelection.url, $stateParams.guid).then(
				function (response) {
					$scope.model = response.Data;
					updateUserAccess();

					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
				},
				function (error) {
					repository.growl(error, 'danger');
				});

			getSelectionValues();

		} else {
			$scope.activateAutocomplete = true;
		}

		var updateNamedSelectionValue = function (value) {
			var deferred = $q.defer();

			repository.updateSingleDictionary(repository.apiData.namedSelectionValue.url, value).then(function (result) {
				deferred.resolve();
			}, function (error) {
				deferred.reject({ value: value, error: error });
			});

			return deferred.promise;
		};

		var createNamedSelectionValue = function (value) {
			var deferred = $q.defer();

			repository.createSingleDictionary(repository.apiData.namedSelectionValue.url, value).then(function (result) {
				deferred.resolve();
			}, function (error) {
				deferred.reject({ value: value, error: error });
			});

			return deferred.promise;
		};

		$scope.update = function (destination) {
			var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('update-namedSelection-success', 'Nedtrekkslisten har blitt oppdatert.') : translationService.translate('create-namedSelection-success', 'Nedtrekkslisten har blitt opprettet.'), 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				var promises = [];

				for (var i = 0; i < $scope.namedSelectionValues.length; i++) {
					$scope.namedSelectionValues[i].IndexPosition = i;
					delete $scope.namedSelectionValues[i].editMode;

					$scope.namedSelectionValues[i].GuidNamedSelection = guid;

					if ($scope.namedSelectionValues[i].Guid) {
						promises.push(updateNamedSelectionValue($scope.namedSelectionValues[i]));
					} else {
						promises.push(createNamedSelectionValue($scope.namedSelectionValues[i]));
					}
				}

				$q.all(promises).then(function () {
					$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
				}).catch(function (error) {
				});
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.isUpdate)
				repository.updateSingleDictionary(repository.apiData.namedSelection.url, $scope.model).then(success).catch(error);
			else
				repository.createSingleDictionary(repository.apiData.namedSelection.url, $scope.model).then(success).catch(error);
		};

		$scope.removeValue = function (item, index) {
			if (!item.Guid) {
				$scope.namedSelectionValues.splice(index, 1);
			} else {
				repository.deleteSingle(repository.apiData.namedSelectionValue.url, item.Guid).then(function (result) {
					$scope.namedSelectionValues.splice(index, 1);
					if (item.Guid === $scope.model.GuidDefaultNamedSelectionValue)
						$scope.model.GuidDefaultNamedSelectionValue = null;
				});
			}
		};

		$scope.cancel = function () {
			repository.commonService.setLastRegisterGuid('namedSelection.list', $scope.model.Guid);
			$scope.goBack('namedSelection.list', { menuGuid: $scope.navigation.params.menuGuid });
		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-namedSelection-message', 'Nedtrekkslisten vil bli permanent fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-namedSelection-button-confirm', 'Ja, slett nedtrekkslisten!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.namedSelection.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-namedSelection-success', 'Nedtrekkslisten ble fjernet!'), result, "success");
						$scope.goBack('namedSelection.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};

		$scope.addNewValue = function () {
			$scope.namedSelectionValues.push({ IndexPosition: $scope.namedSelectionValues.length, editMode: true });
		};

		$scope.$on($scope.events.newSelection, function () {
			updateUserAccess();
		});
	}
})();
