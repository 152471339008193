(function () {

	// IE 11 Polyfills - remove when IE 11 is not supported anymore
	if (!String.prototype.includes) {
		String.prototype.includes = function (search, start) {
			'use strict';
			if (typeof start !== 'number') {
				start = 0;
			}

			if (start + search.length > this.length) {
				return false;
			} else {
				return this.indexOf(search, start) !== -1;
			}
		};
	}

	if (!String.prototype.startsWith) {
		Object.defineProperty(String.prototype, 'startsWith', {
			value: function (search, pos) {
				pos = !pos || pos < 0 ? 0 : +pos;
				return this.substring(pos, pos + search.length) === search;
			}
		});
	}

	if (!String.prototype.capitalizeFirstLetter) {
		Object.defineProperty(String.prototype, 'capitalizeFirstLetter', {
			value: function () {
				return this.charAt(0).toUpperCase() + this.slice(1);
			}
		});
	}

	if (!Array.prototype.includes) {
		Array.prototype.includes = function (search, start) {
			'use strict';
			if (typeof start !== 'number') {
				start = 0;
			}

			if (start >= this.length)
				return false;
			else
				return this.indexOf(search, start) !== -1;
		};
	}

	if (!Array.prototype.find) {
		Object.defineProperty(Array.prototype, 'find', {
			value: function (predicate) {
				// 1. Let O be ? ToObject(this value).
				if (this === null) {
					throw new TypeError('"this" is null or not defined');
				}

				var o = Object(this);

				// 2. Let len be ? ToLength(? Get(O, "length")).
				var len = o.length >>> 0;

				// 3. If IsCallable(predicate) is false, throw a TypeError exception.
				if (typeof predicate !== 'function') {
					throw new TypeError('predicate must be a function');
				}

				// 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
				var thisArg = arguments[1];

				// 5. Let k be 0.
				var k = 0;

				// 6. Repeat, while k < len
				while (k < len) {
					// a. Let Pk be ! ToString(k).
					// b. Let kValue be ? Get(O, Pk).
					// c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
					// d. If testResult is true, return kValue.
					var kValue = o[k];
					if (predicate.call(thisArg, kValue, k, o)) {
						return kValue;
					}
					// e. Increase k by 1.
					k++;
				}

				// 7. Return undefined.
				return undefined;
			}
		});
	}

	if (!Number.isNaN) {
		Number.isNaN = function (value) {
			// even if not strictly alike, return global function
			return isNaN(value);
		};
	}

	var app = angular.module('Plania',
		[
			'ngStorage',
			'ngSanitize',
			'ngTable',
			'ngAnimate',
			'ngFileUpload',
			'ngCsvImport',
			'ngTagsInput',
			'ui.bootstrap',
			'ui.bootstrap.contextMenu',
			'ui.tree',
			'ui.router',
			'ui.tinymce',
			'uiCropper',
			'leaflet-directive',
			'angular-loading-bar',
			'localytics.directives',
			'slick',
			'Firestitch.angular-counter',
			'nouislider',
			'internationalPhoneNumber',
			'ngSentry'
		]);

	app.config(['$httpProvider', '$locationProvider', 'cfpLoadingBarProvider', '$tooltipProvider', '$localStorageProvider', '$sessionStorageProvider', '$modalProvider', 'ipnConfig', function ($httpProvider, $locationProvider, loadingBarConfig, tooltipProvider, localStorageProvider, sessionStorageProvider, $modalProvider, ipnConfig) {
		$locationProvider.html5Mode(true).hashPrefix('!');
		$httpProvider.interceptors.push('authInterceptorService');
		$httpProvider.interceptors.push('setSavingInterceptorService');
		loadingBarConfig.includeBar = false;

		//Disable tooltip on touch devices
		var parser = new UAParser();
		var result = parser.getResult();
		var touch = result.device && (result.device.type === 'tablet' || result.device.type === 'mobile');

		if (touch) {
			var options =
			{
				trigger: 'dontTrigger' // default dummy trigger event to show tooltips
			};
			tooltipProvider.options(options);
		}
		//end tooltip settings
		
		//serializing and deserializing localstorage
		var magicNumber = 42;
		var moveChars = function (key, n) {
			var chars = key.toString().split('');

			for (var i = 0; i < chars.length; i++) {
				var c = chars[i].charCodeAt(0);

				chars[i] = String.fromCharCode(c + n);

			}
			return chars.join('');
		};

		var serializer = function (value) {
			var stringValue = JSON.stringify(value);

			var length = Math.floor(Math.random() * 89) + 10;
			stringValue = moveChars(stringValue, Math.abs(length + magicNumber));
			stringValue = length + stringValue;
			return stringValue;
		};

		var deserializer = function (value) {

			var length = value.slice(0, 2);
			var stringValue = moveChars(value.slice(2, value.length), -Math.abs(Math.abs(length) + magicNumber));
			try {
				return JSON.parse(stringValue);
			} catch (error) {
				return JSON.parse(value);
			}
		};

		localStorageProvider.setSerializer(serializer);
		localStorageProvider.setDeserializer(deserializer);
		sessionStorageProvider.setSerializer(serializer);
		sessionStorageProvider.setDeserializer(deserializer);
		//end serializing settings

		//modal settings
		$modalProvider.options.backdrop = "static";
		//end modal settings

		//international-phone-number settings
		ipnConfig.utilsScript = 'scripts/libphonenumber/utils.js';
		ipnConfig.preferredCountries = ['no', 'dk', 'se'];
		ipnConfig.autoPlaceholder = false;
		//end international-phone-number settings
	}]);

	app.run(['$rootScope', '$state', 'authService', 'TranslationService', 'ModuleService', 'Constants', '$templateCache', 'datepickerConfig', 'datepickerPopupConfig', 'imageService', 'config', '$location','$sce', '$window', '$localStorage', '$modalStack', 'SignalRconfig', 'SignalR', main]);

	function main($rootScope, $state, authService, translationService, moduleService, constants, $templateCache, datepickerConfig, datepickerPopupConfig, imageService, config, $location, $sce, $window, $localStorage, $modalStack, signalRconfig, signalR) {
		$rootScope.navigation = $state;
		$rootScope.isPublicPage = true;
		$rootScope.isLoading = false;
		$rootScope.disableGlobalFiltering = false;
		$rootScope.translationMode = false;
		$rootScope.editPageHasBeenUpdatedMessage = null;

		$rootScope.customerLogoSrc = config.baseUrl + 'Customizations/Images/full-logo.png';
		$rootScope.imageApiUrl = config.baseUrlApi + 'image/';

		$rootScope.customization = {
			hasCustomerLogo: false,
			isCustomer: function (customerId) {
				if ($localStorage.generalOptions)
					return $localStorage.generalOptions.CustomerId === customerId.toString();
				// In case generalOptions is not loaded yet: allow one-time binding by returning undefined, which keeps the ::watch active
				return undefined;
			}
		};

		imageService.isImage($rootScope.customerLogoSrc).then(function (result) {
			// would be better if the customer logo was stored in DB -> could avoid the whole attempt to fetch the logo file
			$rootScope.customization.hasCustomerLogo = result;
		});

		$rootScope.events = {
			newSelection: 'newDataOwner',
			updateMenu: 'updateMenu',
			updatedAccess: 'updatedAccess',
			userInfoChangedEvent: 'userInfoChangedEvent'
		};

		var setGlobalUserInfo = function () {
			$rootScope.userInfo = authService.getUserData();
			$rootScope.home = {
				state: authService.getUserData().MenuContext || 'building.list',
				params: { menuGuid: authService.getUserData().GuidWebMenu || null }
			};
		};

		setGlobalUserInfo();

		$rootScope.$on($rootScope.events.userInfoChangedEvent, function () {
			setGlobalUserInfo();
		});

		if (translationService.getLocale()) {
			translationService.getTranslations();
		}

		$rootScope.dateOptions = constants.dateOptions;

		$rootScope.hasModule = function (module) {
			return moduleService.hasModule(moduleService.moduleFlags[module]);
		};

		$rootScope.hasReadAccess = function (entity, parentEntity, checkOtherDO) {
			if (!checkOtherDO) checkOtherDO = false;
			return authService.hasReadAccess(entity, checkOtherDO, parentEntity);
		};

		$rootScope.hasCreateAccess = function (entity, parentEntity) {
			return authService.hasCreateAccess(entity, parentEntity);
		};

		$rootScope.hasDeleteAccess = function (entity, parentEntity, checkOtherDO) {
			if (!checkOtherDO) checkOtherDO = false;
			return authService.hasDeleteAccess(entity, checkOtherDO, parentEntity);
		};

		$rootScope.hasEditAccess = function (entity, parentEntity, checkOtherDO) {
			if (!checkOtherDO) checkOtherDO = false;
			return authService.hasEditAccess(entity, checkOtherDO, parentEntity);
		};

		$rootScope.go = function (state, params, event) {
			if (event && event.ctrlKey) {
				var url = $state.href(state, params);
				$window.open(url, '_blank');
			} else {
				$state.go(state, params);
			}
		};

		$rootScope.goBack = function (defaultState, defaultParams) {
			if (defaultState && defaultState.indexOf('.edit') >= 0) {
				$state.go(defaultState, defaultParams, { reload: true });
				return;
			}

			$window.history.back();
		};

		$rootScope.getEntityCaption = function (entity) {
			if (!entity) return '';

			var id = entity.Id || entity.id || '';
			var description = entity.Description || entity.description || '';

			if (!id)
				return description;
			return id + (description ? ' - ' + description : '');
		};

		$rootScope.$on('$stateChangeStart', function (event, to, toParams) {
			var top = $modalStack.getTop();
			if (top) {
				$modalStack.dismiss(top.key);
			}

			if (!to.publicPage && !authService.getUserData().isAuthenticated) {
				event.preventDefault();
				var returnTo = JSON.stringify({ name: to.name, params: toParams });
				$state.go('login', { returnToState: returnTo, message: translationService.translate('web-notLoggedIn-Navigation-message', 'Du har ikke en aktiv innlogging, logg inn for å komme til ønsket side.') });
			}
		});

		var setHtmlTitleFromParams = function (to, toParams) {
			// web-topMenu is specified in PLANIA_TRANSLATE.JS parseConfigRoute(), and to.name is fallback value if not translated
			// web-topMenu-workorder.list
			var translatedStateName = translationService.getTranslationIfExists('web-topMenu-' + to.name, '');
			if (translatedStateName && translatedStateName !== to.name) {
				$rootScope.setHtmlTitleByText(translatedStateName);
				return;
			}

			if (toParams.entityData) {
				// web-topMenu-workorder
				var translatedMenuItem = translationService.getTranslationIfExists('web-topMenu-' + toParams.entityData.prefix);
				if (translatedMenuItem) {
					$rootScope.setHtmlTitleByText(translatedMenuItem);
					return;
				}
			}

			// Plania - workorder.list
			$rootScope.setHtmlTitleByText("Plania - " + to.name);
		};

		$rootScope.setHtmlTitleByText = function (title) {
			$rootScope.htmlTitle = title;
		};

		$rootScope.setHtmlTitleByModel = function (model) {
			$rootScope.setHtmlTitleByText(model ? model.Caption : '');
		};

		$rootScope.$on('$stateChangeSuccess', function (ev, to, toParams, from, fromParams) {
			window.scrollTo(0, 0);

			if (from.name && from.name !== to.name && from.excludeFromHistory) {
				// avoid previous page in history, replace by the new one https://stackoverflow.com/questions/12832317/history-replacestate-example
				$window.history.replaceState({}, 'any', $location.absUrl()); 
			}

			$rootScope.isPublicPage = to.publicPage;

			if (to.name.indexOf('.edit') > -1) {
				$rootScope.disableGlobalFiltering = true;
			} else {
				$rootScope.disableGlobalFiltering = false;
			}

			if (authService.getUserData().isAuthenticated) {
				signalRconfig.setEditPageListener(to, toParams, from, fromParams);
			}

			setHtmlTitleFromParams(to, toParams);
		});

		if ($window.history.scrollRestoration) {
			$window.history.scrollRestoration = 'manual';
		}

		if (authService.getUserData().isAuthenticated) {
			signalR.setQs({
				fingerprint: config.fingerprint,
				guidUser: authService.getUserData().guidUser
			});

			signalR.start();
		}

		// Sentry setup
		Sentry.configureScope(function (scope) {
			scope.addEventProcessor(function (event) {
				// Sentry is disabled on installation. Use option from GO instead of via PlaniaStatus since GO is updated via SignalR.
				if (!$localStorage.generalOptions || !$localStorage.generalOptions.SentryTrackIssues) return null;
				if (!$localStorage.planiaStatus || !$localStorage.planiaStatus.ApplicationVersion) return null;

				// Is in dev mode (from localhost).
				if ($localStorage.planiaStatus.ApplicationVersion.endsWith('.1001')) return null;
				if ($location.host().startsWith("localhost")) return null;

				// Set correct release
				event.release = $localStorage.planiaStatus.ApplicationVersion;

				// Sentry probably reads from window.location, but during navigation it shows the previous page.
				// Get correct url from $location
				if (event.request && event.request.url && event.request.url !== $location.absUrl()) {
					event.request.url = $location.absUrl();
				}

				return event;
			});
		});

		datepickerConfig.showWeeks = false;
		datepickerPopupConfig.showButtonBar = false;

		//Caching templates
		$templateCache.put('template/datepicker/day.html',
			"<table role=\"grid\" aria-labelledby=\"{{::uniqueId}}-title\" aria-activedescendant=\"{{activeDateId}}\" class=\"dp-table dpt-day\"><thead><tr class=\"tr-dpnav\"><th><button type=\"button\" class=\"pull-left btn-dp\" ng-click=\"move(-1)\" tabindex=\"-1\"><i class=\"zmdi zmdi-long-arrow-left\"></i></button></th><th colspan=\"{{::5 + showWeeks}}\"><button id=\"{{::uniqueId}}-title\" role=\"heading\" aria-live=\"assertive\" aria-atomic=\"true\" type=\"button\" ng-click=\"toggleMode()\" ng-disabled=\"datepickerMode === maxMode\" tabindex=\"-1\" class=\"w-100 btn-dp\"><div class=\"dp-title\">{{title}}</div></button></th><th><button type=\"button\" class=\"pull-right btn-dp\" ng-click=\"move(1)\" tabindex=\"-1\"><i class=\"zmdi zmdi-long-arrow-right\"></i></button></th></tr><tr class=\"tr-dpday\"><th ng-if=\"showWeeks\" class=\"text-center\"></th><th ng-repeat=\"label in ::labels track by $index\" class=\"text-center\"><small aria-label=\"{{::label.full}}\">{{::label.abbr}}</small></th></tr></thead><tbody><tr ng-repeat=\"row in rows track by $index\"><td ng-if=\"showWeeks\" class=\"text-center h6\"><em>{{ weekNumbers[$index] }}</em></td><td ng-repeat=\"dt in row track by dt.date\" class=\"text-center\" role=\"gridcell\" id=\"{{::dt.uid}}\" ng-class=\"::dt.customClass\"><button type=\"button\" class=\"w-100 btn-dp btn-dpday btn-dpbody\" ng-class=\"{'dp-today': dt.current, 'dp-selected': dt.selected, 'dp-active': isActive(dt)}\" ng-click=\"select(dt.date)\" ng-disabled=\"dt.disabled\" tabindex=\"-1\"><span ng-class=\"::{'dp-day-muted': dt.secondary, 'dp-day-today': dt.current}\">{{::dt.label}}</span></button></td></tr></tbody></table>"
		);

		$templateCache.put('template/datepicker/month.html',
			"<table role=\"grid\" aria-labelledby=\"{{::uniqueId}}-title\" aria-activedescendant=\"{{activeDateId}}\" class=\"dp-table\"><thead><tr class=\"tr-dpnav\"><th><button type=\"button\" class=\"pull-left btn-dp\" ng-click=\"move(-1)\" tabindex=\"-1\"><i class=\"zmdi zmdi-long-arrow-left\"></i></button></th><th><button id=\"{{::uniqueId}}-title\" role=\"heading\" aria-live=\"assertive\" aria-atomic=\"true\" type=\"button\" ng-click=\"toggleMode()\" ng-disabled=\"datepickerMode === maxMode\" tabindex=\"-1\" class=\"w-100 btn-dp\"><div class=\"dp-title\">{{title}}</div></button></th><th><button type=\"button\" class=\"pull-right btn-dp\" ng-click=\"move(1)\" tabindex=\"-1\"><i class=\"zmdi zmdi-long-arrow-right\"></i></button></th></tr></thead><tbody><tr ng-repeat=\"row in rows track by $index\"><td ng-repeat=\"dt in row track by dt.date\" class=\"text-center\" role=\"gridcell\" id=\"{{::dt.uid}}\" ng-class=\"::dt.customClass\"><button type=\"button\" class=\"w-100 btn-dp btn-dpbody\" ng-class=\"{'dp-selected': dt.selected, 'dp-active': isActive(dt)}\" ng-click=\"select(dt.date)\" ng-disabled=\"dt.disabled\" tabindex=\"-1\"><span ng-class=\"::{'dp-day-today': dt.current}\">{{::dt.label}}</span></button></td></tr></tbody></table>"
		);

		$templateCache.put('template/datepicker/year.html',
			"<table role=\"grid\" aria-labelledby=\"{{::uniqueId}}-title\" aria-activedescendant=\"{{activeDateId}}\" class=\"dp-table\"><thead><tr class=\"tr-dpnav\"><th><button type=\"button\" class=\"pull-left btn-dp\" ng-click=\"move(-1)\" tabindex=\"-1\"><i class=\"zmdi zmdi-long-arrow-left\"></i></button></th><th colspan=\"3\"><button id=\"{{::uniqueId}}-title\" role=\"heading\" aria-live=\"assertive\" aria-atomic=\"true\" type=\"button\" class=\"w-100 btn-dp\" ng-click=\"toggleMode()\" ng-disabled=\"datepickerMode === maxMode\" tabindex=\"-1\"><div class=\"dp-title\">{{title}}</div></button></th><th><button type=\"button\" class=\"pull-right btn-dp\" ng-click=\"move(1)\" tabindex=\"-1\"><i class=\"zmdi zmdi-long-arrow-right\"></i></button></th></tr></thead><tbody><tr ng-repeat=\"row in rows track by $index\"><td ng-repeat=\"dt in row track by dt.date\" class=\"text-center\" role=\"gridcell\" id=\"{{::dt.uid}}\"><button type=\"button\" class=\"w-100 btn-dp btn-dpbody\" ng-class=\"{'dp-selected': dt.selected, 'dp-active': isActive(dt)}\" ng-click=\"select(dt.date)\" ng-disabled=\"dt.disabled\" tabindex=\"-1\"><span ng-class=\"::{'dp-day-today': dt.current}\">{{::dt.label}}</span></button></td></tr></tbody></table>"
		);

		$templateCache.put('template/tabs/tabset.html',
			"<div class=\"clearfix\"><ul class=\"tab-nav\" ng-class=\"{'tn-vertical': vertical, 'tn-justified': justified, 'tab-nav-right': right}\" ng-transclude></ul><div class=\"tab-content\"><div class=\"tab-pane\" ng-repeat=\"tab in tabs\" ng-class=\"{active: tab.active}\" tab-content-transclude=\"tab\"></div></div></div>"
		);

		$templateCache.put('template/carousel/carousel.html',
			"<div ng-mouseenter=\"pause()\" ng-mouseleave=\"play()\" class=\"carousel\" ng-swipe-right=\"prev()\" ng-swipe-left=\"next()\"><ol class=\"carousel-indicators\" ng-show=\"slides.length > 1\"><li ng-repeat=\"slide in slides | orderBy:'index' track by $index\" ng-class=\"{active: isActive(slide)}\" ng-click=\"select(slide)\"></li></ol><div class=\"carousel-inner\" ng-transclude></div><span class=\"left carousel-control\" ng-click=\"prev()\" ng-show=\"slides.length > 1\"><span class=\"zmdi zmdi-chevron-left\"></span></span> <span class=\"right carousel-control\" ng-click=\"next()\" ng-show=\"slides.length > 1\"><span class=\"zmdi zmdi-chevron-right\"></span></a></div>"
		);


	}
})();
