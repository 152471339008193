(function () {
	angular.module('Plania').controller('ComponentSupplierController', ['$scope', 'Repository', 'params', '$modalInstance', controller]);

	function controller($scope, repository, params, $modalInstance) {
		$scope.isUpdate = params.isUpdate;
		$scope.activateAutocomplete = false;
		$scope.deliveryTimeUnits = [
			{ text: 'Ingen', value: -1 }, //undefined
			{ text: 'Dag', value: 0 },
			{ text: 'Uke', value: 1 },
			{ text: 'Måned', value: 2 },
			{ text: 'År', value: 3 },
			{ text: 'Timer', value: 7 }
		];

		if (params.supplier) {
			var columns = ['Supplier.Id', 'Supplier.Description', 'Supplier.Guid'];
			repository.getSingle(repository.apiData.componentSupplier.url, params.supplier.Guid, JSON.stringify(columns)).then(
				function (response) {
					$scope.model = response.Data;

					var selectedDeliveryTimeUnit = _.filter($scope.deliveryTimeUnits, { value: $scope.model.DeliveryTimePeriodUnit });
					if (selectedDeliveryTimeUnit.length > 0) {
						$scope.selectedDeliveryTimeUnit = selectedDeliveryTimeUnit[0];
					}

					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
				},
				function (error) {
					repository.growl(error, 'danger');
				});




		} else {
			$scope.model = {
				GuidComponent: params.guidComponent,
			};
			$scope.selectedDeliveryTimeUnit = $scope.deliveryTimeUnits[0];
			setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
		}




		$scope.saveAction = function () {
			if ($scope.selectedDeliveryTimeUnit) {
				$scope.model.DeliveryTimePeriodUnit = $scope.selectedDeliveryTimeUnit.value;
			}

			if ($scope.isUpdate) {
				repository.updateSingleDictionary(repository.apiData.componentSupplier.url, $scope.model).then(function (result) {
					repository.growl('Leverandør oppdatert', 'success');
					$modalInstance.close('ok');
				}).catch(function (error) {
					repository.growl(error, 'danger');
				});
			} else {
				repository.createSingleDictionary(repository.apiData.componentSupplier.url, $scope.model).then(function (result) {
					repository.growl('Leverandør opprettet', 'success');
					$modalInstance.close('ok');
				}).catch(function (error) {
					repository.growl(error, 'danger');
				});
			}
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}
})();
