(function () {
	angular.module('Plania').controller('PaymentOrderFormController', ['$scope', 'Repository', '$stateParams', '$modal', '$modalStack', 'ngTableParams', '$window', 'TranslationService', '$localStorage', 'ListService', controller]);

	function controller($scope, repository, $stateParams, $modal, $modalStack, ngTableParams, $window, translationService, $localStorage, listService) {
		$scope.isUpdate = $scope.navigation.current.name === 'paymentOrderForm.edit';
		$scope.model = { Guid: $stateParams.guid };
		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.PaymentOrderForm, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.PaymentOrderForm, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.PaymentOrderForm);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.PaymentOrderForm);
			}
		};

		var columns = ['Report.Name'];

		if ($scope.isUpdate) {
			repository.getSingle(repository.apiData.paymentOrderForm.url, $stateParams.guid, JSON.stringify(columns)).then(
				function (response) {
					$scope.model = response.Data;
					$scope.reload = true;
					updateAccess();
				}, function (error) {
					repository.growl(error, 'danger');
				}
			);
		}

		$scope.update = function (destination) {
			var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('web-paymentOrderForm-update-success', 'Fakturablankett har blitt oppdatert.') : translationService.translate('web-paymentOrderForm-create-success', 'Fakturablankett har blitt opprettet.'), 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.isUpdate) {
				repository.updateSingleDictionary(repository.apiData.paymentOrderForm.url, $scope.model).then(success, error);
			} else {
				repository.createSingleDictionary(repository.apiData.paymentOrderForm.url, $scope.model).then(success, error);
			}

		};

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "report":
					filter.PropertyFilter = [
						{ Property: 'ReportType', Operator: '=', Value: '1' },
						{ Property: 'ReportType', Operator: '=', Value: '2', Operand: 'Or' }
					];
					return filter;
			}
			return filter;
		};


		$scope.delete = function () {

		};
	}
})();
