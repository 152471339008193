(function () {
	angular.module('Plania').controller('HourTypeController', ['$scope', 'Repository', '$stateParams', 'TranslationService', controller]);

	function controller($scope, repository, $stateParams, translationService) {
		$scope.isUpdate = $scope.navigation.current.name === 'hourType.edit' || ($stateParams.isModal && $stateParams.isUpdate);
		$scope.activateAutocomplete = false;
		$scope.isModal = $stateParams.isModal;
		$scope.model = {};

		if ($scope.isModal && !$scope.isUpdate) {
			$scope.model.GuidProject = $stateParams.guidProject;
		}


		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.HourType, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.HourType, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.HourType);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.HourType);
			}
		};

		if ($scope.isUpdate) {
			repository.getSingle(repository.apiData.hourType.url, $stateParams.guid, null).then(
				function (response) {
					$scope.model = response.Data;
					updateAccess();
					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
				}, function (error) {
					repository.growl(error, 'danger');
				}
			);
		} else {
			$scope.activateAutocomplete = true;
		}

		$scope.cancel = function () {
			$stateParams.cancel();
		};

		$scope.ok = function () {
			$stateParams.ok();
		};

		$scope.update = function (destination) {
			var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('update-hourType-success', 'Time type har blitt oppdatert.') : translationService.translate('create-hour-type-success', 'Time type har blitt opprettet.'), 'success');
				if ($scope.isModal) {
					$scope.ok();
				} else {
					var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
					repository.commonService.setLastRegisterGuid(destination, guid);
					$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
				}
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.isUpdate) {
				repository.updateSingleDictionary(repository.apiData.hourType.url, $scope.model).then(success, error);
			} else {
				repository.createSingleDictionary(repository.apiData.hourType.url, $scope.model).then(success, error);
			}
		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-hourType-delete-message',
					'Time typen vil bli permanent fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-hourType-delete-button-confirm',
					'Ja, fjern time typen!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			},
				function () {
					window.onkeydown = null;
					window.onfocus = null;
					repository.deleteSingle(repository.apiData.hourType.url, $scope.model.Guid)
						.then(function (result) {
							swal(translationService.translate('web-swal-hourType-delete-success',
								'Time typen ble fjernet!'),
								result,
								"success");
							repository.growl(translationService.translate('web-workorder-hourType-removed',
								'Time typen er fjernet'),
								'success');
							if ($scope.isModal) {
								$modalStack.dismissAll();
							} else {
								$scope.goBack('hourType.list', { menuGuid: $scope.navigation.params.menuGuid });
							}
						});
				});
		};
	}

})();
