(function () {
	angular.module('Plania').controller('SingleSelectModalController', ['$scope', '$modalInstance', 'modalParams', 'Repository', 'ngTableParams', 'ListService', controller]);

	function controller($scope, $modalInstance, modalParams, repository, ngTableParams, listService) {

		$scope.modalParams = modalParams;

		$scope.getPropertyValue = function (row, column) {
			var propertyColumn = { Property: column.Property };
			if (column.PropertyType) {
				propertyColumn.PropertyType = column.PropertyType;
			}
			return listService.GetPropertyValue(row, propertyColumn);
		};

		// Convert to Capitalized in order to use pl-table-header for row sorting
		var capitalizedColumns = [];
		$scope.modalParams.columns.forEach(function (col) {
			var keys = Object.keys(col);
			var newCol = {};
			keys.forEach(function (key) {
				newCol[key.charAt(0).toUpperCase() + key.slice(1)] = col[key];
			});
			capitalizedColumns.push(newCol);
		});

		var columns = _.map(capitalizedColumns, 'Property');
		$scope.visibleColumns = _.filter(capitalizedColumns, function (c) { return !c.Hidden; });

		$scope.itemTable = new ngTableParams({
			page: 1,
			count: 10,
			sorting: modalParams.sorting,
			filter: modalParams.filter
		},
			{
				total: 0,
				counts: [10, 25, 50],
				getData: function ($defer, params) {
					var filter = angular.copy(params.filter());
					_.remove(filter.PropertyFilter,
						function(prop) {
							return prop.isDisabled;
						});
					repository.GetPaginated(modalParams.url, params.page() - 1, params.count(), params.sorting(), filter, null, JSON.stringify(columns)).then(
						function (result) {
							params.total(result.TotalCount);
							$defer.resolve(result.List);
						},
						function (error) {
							repository.growl(error, 'danger');
						});
				}
			});

		$scope.save = function (item) {
			$modalInstance.close(item);
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}

})();
