(function () {
	angular.module('Plania').controller('HelpTextModalController', ['$scope', 'params', '$modalInstance', 'TranslationService', '$sce', controller]);

	function controller($scope, params, $modalInstance, translationService, $sce) {
		$scope.model = {
			translatedLabel: params.translatedLabel
		};

		translationService.getHelpText(params.systemName).then(function (result) {
			var html = decodeHtml(result);
			$scope.model.helpText = $sce.trustAsHtml(html);
		});

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
		function decodeHtml (html) {
			var tmpElement = document.createElement('textarea');
			tmpElement.innerHTML = html;
			return tmpElement.value;
		}
	}
})();
