(function () {
    angular.module('Plania').controller('CreateSparePartCountingListModalController', ['$scope', 'Repository', 'params', '$modalInstance', controller]);

    function controller($scope, repository, params, $modalInstance) {
        $scope.model = {};
        $scope.notUniqueName = false;

        if (params.sparePartCountingList) {
            $scope.model.Guid = params.sparePartCountingList.Guid;
            $scope.model.Id = params.sparePartCountingList.Id;
        }

        $scope.model = params.sparePartCountingList ? params.sparePartCountingList : {};

        if (!params.sparePartCountingList && params.sparePartCounting) {
            $scope.model.GuidSparePartCounting = params.sparePartCounting.Guid;
        }

        var success = function (result) {
            repository.growl($scope.model.Guid ? 'Varetellingsliste oppdatert' : 'Varetellingsliste opprettet', 'success');
            if (params.sparePartCounting) {
                $modalInstance.close(result.Guid);
            } else {
                $modalInstance.close($scope.model);
            }
        };

        var error = function (error) {
            if (typeof (error) === "string") {
                repository.growl(error, 'danger');
            } else {
                repository.growl(error.Data.Message, 'danger');
            }
        };

        $scope.update = function () {
            if (!params.sparePartCountingList && _.find(params.listNames, function (o) { return o === $scope.model.Id; })) {
                $scope.notUniqueName = true;
                return;
            }

            if ($scope.model.Guid) {
                repository.updateSingleDictionary(repository.apiData.sparePartCountingList.url, $scope.model).then(success, error);
            } else {
                repository.createSingleDictionary(repository.apiData.sparePartCountingList.url, $scope.model).then(success, error);
            }
        };

        $scope.cancel = function () {
            $modalInstance.dismiss('canceled');
        };
    }
})();
