(function () {
    angular.module('Plania').controller('DepartmentController', ['$scope', 'Repository', '$stateParams', 'TranslationService', controller]);

    function controller($scope, repository, $stateParams, translationService) {
        $scope.isUpdate = $scope.navigation.current.name === 'department.edit';
        $scope.activateAutocomplete = false;

        var updateAccess = function () {
            if ($scope.isUpdate) {
                var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
                $scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.Department, checkOtherDO);
                $scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Department, checkOtherDO);
            } else {
                $scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.Department);
                $scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Department);
            }
        };

        if ($scope.isUpdate) {
            var columns = ['PurchaseOrderForm.Description'];
            repository.getSingle(repository.apiData.department.url, $stateParams.guid, JSON.stringify(columns)).then(
                   function (response) {
                       $scope.model = response.Data;
                       updateAccess();
                       setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
                }, function (error) {
                    repository.growl(error, 'danger');
                }
            );
        } else {
            $scope.activateAutocomplete = true;
        }

        $scope.update = function (destination) {
            var success = function (result) {
                repository.growl($scope.isUpdate ? translationService.translate('update-department-success', 'Avdeling har blitt oppdatert.') : translationService.translate('create-department-success', 'Avdeling har blitt opprettet.'), 'success');
                var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
                repository.commonService.setLastRegisterGuid(destination, guid);
                $scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
            };

            var error = function (error) {
                if (typeof (error) === "string") {
                    repository.growl(error, 'danger');
                } else {
                    repository.growl(error.Data.Message, 'danger');
                }
            };

            delete $scope.model.PurchaseOrderForm;
            if ($scope.isUpdate) {
                repository.updateSingleDictionary(repository.apiData.department.url, $scope.model).then(success, error);
            } else {
                repository.createSingleDictionary(repository.apiData.department.url, $scope.model).then(success, error);
            }

        };

        $scope.delete = function () {
            swal({
                title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
                text: translationService.translate('web-swal-department-message', "Avdeling vil bli permanent fjernet!"),
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f44336",
                confirmButtonText: translationService.translate('web-swal-department-button-confirm', 'Ja, fjern avdeling'),
                cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
            }, function () {
                window.onkeydown = null;
                window.onfocus = null;

                repository.deleteSingle(repository.apiData.department.url, $scope.model.Guid)
					.then(function (result) {
					    swal(translationService.translate('web-swal-department-success', 'Avdeling ble fjernet!'), result, "success");
					    $scope.goBack('department.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
            });
        };

        $scope.$on($scope.events.newSelection, function () {
            updateAccess();
        });
    }

})();
