(function () {
	angular.module('Plania').directive('plPrintExcelListDirective', function () {
		return {
			restrict: 'E',
			scope: {
				domainName: '=domainName', //domainname of object to be printed
				table: '=table', //ngTableParams list for easy access to values
				selectedListSetup: '=selectedListSetup', //selectedListSetup for easy access to columns and their underlying filters
			},
			controller: ['$scope', 'Repository', '$window', 'TranslationService', 'config', printController],
			replace: true, // replaces the plPrintExcelListDirective element with its template.
			template: '<li ng-show="isSystemAdministrator" class="dropdown" dropdown on-toggle="toggled(open)"><a href="" tooltip-append-to-body="true" tooltip="{{\'Last ned som excel\' | translate: \'web-printExcelList-tooltip\' }}" dropdown-toggle class="dropdown-toggle"> <span class="zmdi-hc-stack zmdi-actions-stack-fix"><i class="zmdi zmdi-file zmdi-hc-stack-2x c-white"></i><i class="zmdi zmdi-download zmdi-hc-stack-1x c-blue p-t-5"></i></span> </a> <ul class="dropdown-menu dropdown-menu-right"> <li ng-repeat="option in options"> <a href="" ng-click="option.onClick()"> <span>{{option.text}}</span> </a> </li> </ul></li>'
		};
	});

	var printController = function ($scope, repository, $window, translationService, config) {
		$scope.options = [];
		$scope.isSystemAdministrator = repository.authService.getUserData().isSystemAdministrator;

		$scope.dropdownMenu = new function () {
			var me = this;
			this.addDefaultPrintOptions = function (options) {
				options.push({ text: translationService.translate('web-printExcelList-exportCurrentPageToExcel', 'Last ned gjeldende side som excel fil'), onClick: function () { $scope.printToExcel(); } });
				if ($scope.table.total() > $scope.table.count()) {
					options.push({ text: translationService.translate('web-printExcelList-exportEveryPageToExcel', 'Last ned alle sider som excel fil'), onClick: function () { $scope.printToExcel(true); } });
				}
			};

			this.updateOptions = function () {
				var options = [];
				if (!$scope.isSystemAdministrator) return;

				var getOptions = me[$scope.domainName];

				if (getOptions) {
					getOptions(options);
				}
				me.addDefaultPrintOptions(options);

				$scope.options = options;
			};
		};

		$scope.toggled = function (open) {
			if (open) {
				$scope.dropdownMenu.updateOptions();
			}
		};

		$scope.printToExcel = function (getAll) {
			if (!$scope.isSystemAdministrator) return;

			var url = config.baseUrlApi + "exportExcel?prefix=" + $scope.domainName;

			var tmpfilter = repository.getFilter($scope.table.filter());

			if (!tmpfilter.PropertyFilter) tmpfilter.PropertyFilter = [];

			$scope.selectedListSetup.Columns.forEach(function (col) {
				if (col.Filter)
					tmpfilter.PropertyFilter = tmpfilter.PropertyFilter.concat(col.Filter);
			});

			if (tmpfilter.PropertyFilter) {
				tmpfilter.PropertyFilter.forEach(function (f) {
					if (f.Value) {
						f.Value = repository.GetKeyWordFilters(f.Value);
					}
				});

				_.remove(tmpfilter.PropertyFilter, function (f) { return f.Value === '@GuidCurrentBuilding'; });
			}

			var columns = [];
			$scope.selectedListSetup.VisibleColumns.forEach(function (col) {
				columns.push({
					Property: col.Property,
					PropertyType: col.PropertyType,
					Title: col.Title
				});
			});

			url += "&access_token=" + repository.authService.getPlaniaToken().access;
			url += "&page=" + ($scope.table.page() - 1);
			if (getAll)
				url += "&pageSize=" + -1;
			else
				url += "&pageSize=" + $scope.table.count();
			url += "&sorting=" + JSON.stringify($scope.table.sorting());
			url += "&filter=" + encodeURIComponent(JSON.stringify(tmpfilter));
			url += "&webListViews=" + angular.toJson(columns);
			url += "&webListTitle=" + $scope.selectedListSetup.Id;
			url += repository.getUrlParameters('&');

			if (url.length >= 8000) {
				swal(translationService.translate('web-printExcelList-swal-urlTooLong-title', 'Feil ved eksport'), translationService.translate('web-printExcelList-swal-urlTooLong-text', 'Det er for mange kolonner, eller for komplisert filtre for å kunne eksportere til excel.'), "error");
				return;
			}

			$window.open(url, '_self');
		};
	};

})();
