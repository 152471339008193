(function () {
	//Programatic scrolling to elements not using the entire window scroll
	angular.module('Plania').factory('ScrollService', ['$window', '$timeout', function ($window, $timeout) {
		var service = {};

		service.scrollToElement = function (elementId) {
			if (typeof (elementId) !== 'string') elementId = elementId.toString();
			$timeout(function () {
				var element = $window.document.getElementById(elementId);
				var parentScrollElement = $(element).parents().filter(function () {
					return $(this).css('overflow') === 'auto';
				}).first();

				parentScrollElement[0].scrollTop = element.offsetTop;
			});
		};

		return service;
	}]);
})();
