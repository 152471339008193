(function () {

	angular.module('Plania').controller('MobileMenuProfileController', ['$scope', 'Repository', '$stateParams', 'ngTableParams', '$modal', 'TranslationService', 'ModuleService', 'ListService', '$state', '$localStorage', listController]);

	function listController($scope, repository, $stateParams, ngTableParams, $modal, translationService, moduleService, listService, $state, $localStorage) {
		$scope.isUpdate = $scope.navigation.current.name === 'mobileMenuProfile.edit';
		$scope.model = {};
		$scope.mobileVersion = 'new';

		var updateAccess = function () {
			// since mobile menu profile is not ruled by data owner, no test for other data owner is relevant
			$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.MobileMenuProfile);
			$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.MobileMenuProfile);
		};
		updateAccess();

		// New mobile menu items
		// Key needs to be same as enum value.
		// IndexPosition is used for list view in Web.
		$scope.activityPageItems = [
			{
				key: 0,
				indexPosition: 0,
				title: translationService.translate('web-enum-MobileMenuProfileItems-MyWorkOrders', 'Mine arbeidsordre'),
				available: moduleService.hasModule(moduleService.moduleFlags.Activity),
				visible: moduleService.hasModule(moduleService.moduleFlags.Activity) ? !$scope.isUpdate : false,
				group: translationService.translate('mobile-activityPage-section-tasks', 'Oppgaver'),
				icon: 'zmdi-account',
				popoverText: translationService.translate('web-mobilemenuprofile-myWorkOrder-popoverText', 'Arbeidsordre hvor innlogget person står som ansvarlig. Dersom mobilmenyen "Mine rutiner" er aktiv for bruker, vil arbeidsordrer som er knyttet til en periodisk rutine ikke være tilgjengelig på dette menypunktet.')
			},
			{
				key: 10,
				indexPosition: 1,
				title: translationService.translate('web-enum-MobileMenuProfileItems-myFVWorkOrders', 'Mine rutiner'),
				available: moduleService.hasModule(moduleService.moduleFlags.Activity),
				visible: moduleService.hasModule(moduleService.moduleFlags.Activity) ? !$scope.isUpdate : false,
				group: translationService.translate('mobile-activityPage-section-tasks', 'Oppgaver'),
				icon: 'zmdi-account',
				popoverText: translationService.translate('web-mobilemenuprofile-myFVWorkOrders-popoverText', 'Arbeidsordre hvor innlogget person står som ansvarlig og filtert på arbeidsordre som kommer fra periodiske rutiner.')
			},
			{
				key: 8,
				indexPosition: 2,
				title: translationService.translate('web-enum-MobileMenuProfileItems-MyResourceResponsibleWorkOrders', 'Mitt fagansvar'),
				available: moduleService.hasModule(moduleService.moduleFlags.Activity),
				visible: moduleService.hasModule(moduleService.moduleFlags.Activity) ? !$scope.isUpdate : false,
				group: translationService.translate('mobile-activityPage-section-tasks', 'Oppgaver'),
				icon: 'zmdi-account',
				popoverText: translationService.translate('web-mobilemenuprofile-MyResourceResponsibleWorkOrders-popoverText', 'Arbeidsordre hvor innlogget person står som fagansvarlig.')
			},
			{
				key: 2,
				indexPosition: 3,
				title: translationService.translate('web-enum-MobileMenuProfileItems-MyResourceGroupWorkorders', 'Ressursgruppens arbeidsordre'),
				available: moduleService.hasModule(moduleService.moduleFlags.Activity),
				visible: moduleService.hasModule(moduleService.moduleFlags.Activity) ? !$scope.isUpdate : false,
				group: translationService.translate('mobile-activityPage-section-tasks', 'Oppgaver'),
				icon: 'zmdi-accounts',
				popoverText: translationService.translate('web-mobilemenuprofile-MyResourceGroupWorkorders-popoverText', 'Arbeidsordre som deler samme ressursgruppe som innlogget person.')
			},
			{
				key: 1,
				indexPosition: 4,
				title: translationService.translate('web-enum-MobileMenuProfileItems-AllWorkorders', 'Alle arbeidsordre'),
				available: moduleService.hasModule(moduleService.moduleFlags.Activity),
				visible: moduleService.hasModule(moduleService.moduleFlags.Activity) ? !$scope.isUpdate : false,
				group: translationService.translate('mobile-activityPage-section-tasks', 'Oppgaver'),
				icon: 'zmdi-case',
				popoverText: translationService.translate('web-mobilemenuprofile-AllWorkorders-popoverText', 'Alle arbeidsordre som innlogget bruker har tilgang til. Listen vil være filtrert om bruker er knyttet til en gitt leverandør, kunde eller er låst til et utvalg.')
			},
			{
				key: 3,
				indexPosition: 5,
				title: translationService.translate('web-enum-MobileMenuProfileItems-MySparePartCounting', 'Mine varetellinger'),
				available: moduleService.hasModule(moduleService.moduleFlags.InventoryControl),
				visible: moduleService.hasModule(moduleService.moduleFlags.InventoryControl) ? !$scope.isUpdate : false,
				group: translationService.translate('mobile-activityPage-section-tasks', 'Oppgaver'),
				icon: 'zmdi-shopping-cart',
				popoverText: translationService.translate('web-mobilemenuprofile-MySparePartCounting-popoverText', 'Tellelister som innlogget bruker er del av, og som ikke er knyttet til en fullført varetelling.')
			},
			{
				key: 4,
				indexPosition: 6,
				title: translationService.translate('web-enum-MobileMenuProfileItems-MyCreatedRequests', 'Mine innmeldte saker'),
				available: moduleService.hasModule(moduleService.moduleFlags.Activity),
				visible: moduleService.hasModule(moduleService.moduleFlags.Activity) ? !$scope.isUpdate : false,
				group: translationService.translate('mobile-activityPage-section-requests', 'Innmelding'),
				icon: 'zmdi-account',
				popoverText: translationService.translate('web-mobilemenuprofile-MyCreatedRequests-popoverText', 'Saker som er opprettet av innlogget bruker eller hvor innlogget person er satt som kontaktperson.')
			},
			{
				key: 9,
				indexPosition: 7,
				title: translationService.translate('web-enum-MobileMenuProfileItems-MyDepartmentCreatedRequests', 'Min avdelings innmeldte saker'),
				available: moduleService.hasModule(moduleService.moduleFlags.Activity),
				visible: moduleService.hasModule(moduleService.moduleFlags.Activity) ? !$scope.isUpdate : false,
				group: translationService.translate('mobile-activityPage-section-requests', 'Innmelding'),
				icon: 'zmdi-accounts',
				popoverText: translationService.translate('web-mobilemenuprofile-MyDepartmentCreatedRequests-popoverText', 'Innmeldte saker hvor meldingene er tilknyttet den samme avdelingen som innlogget person er del av.')
			},
			{
				key: 6,
				indexPosition: 8,
				title: translationService.translate('web-enum-MobileMenuProfileItems-MyResponsibleRequests', 'Mine meldinger'),
				available: moduleService.hasModule(moduleService.moduleFlags.Activity),
				visible: moduleService.hasModule(moduleService.moduleFlags.Activity) ? !$scope.isUpdate : false,
				group: translationService.translate('mobile-activityPage-section-process', 'Behandle'),
				icon: 'zmdi-account',
				popoverText: translationService.translate('web-mobilemenuprofile-MyResponsibleRequests-popoverText', 'Meldinger hvor innlogget person står som ansvarlig.')
			},
			{
				key: 11,
				indexPosition: 9,
				title: translationService.translate('web-enum-MobileMenuProfileItems-BBYMyResponsibleRequests', 'Mine meldinger (Tilpasset)'),
				available: moduleService.hasModule(moduleService.moduleFlags.Activity) && $localStorage.generalOptions.IsBBY,
				visible: moduleService.hasModule(moduleService.moduleFlags.Activity) ? !$scope.isUpdate : false,
				group: translationService.translate('mobile-activityPage-section-process', 'Behandle'),
				icon: 'zmdi-account',
				popoverText: translationService.translate('web-mobilemenuprofile-BBYMyResponsibleRequests-popoverText', 'Viser meldinger hvor innlogget person står som hovedansvarlig, og meldinger som mangler hovedansvarlig, men hvor innlogget person er angitt som sekundæransvarlig.')
			},
			{
				key: 7,
				indexPosition: 10,
				title: translationService.translate('web-enum-MobileMenuProfileItems-MyResourceGroupRequests', 'Ressursgruppens meldinger'),
				available: moduleService.hasModule(moduleService.moduleFlags.Activity),
				visible: moduleService.hasModule(moduleService.moduleFlags.Activity) ? !$scope.isUpdate : false,
				group: translationService.translate('mobile-activityPage-section-process', 'Behandle'),
				icon: 'zmdi-accounts',
				popoverText: translationService.translate('web-mobilemenuprofile-MyResourceGroupRequests-popoverText', 'Meldinger som deler samme ressursgruppe som innlogget person.')
			},
			{
				key: 5,
				indexPosition: 11,
				title: translationService.translate('web-enum-MobileMenuProfileItems-AllRequests', 'Alle meldinger'),
				available: moduleService.hasModule(moduleService.moduleFlags.Activity),
				visible: moduleService.hasModule(moduleService.moduleFlags.Activity) ? !$scope.isUpdate : false,
				group: translationService.translate('mobile-activityPage-section-process', 'Behandle'),
				icon: 'zmdi-email',
				popoverText: translationService.translate('web-mobilemenuprofile-AllRequests-popoverText', 'Alle meldinger som innlogget bruker har tilgang til. Listen vil være filtrert om bruker er knyttet til en gitt leverandør, kunde eller er låst til et utvalg.')
			},
		];
		$scope.activityPageItems = _.sortBy($scope.activityPageItems, function (item) { return item.indexPosition; }).filter(function (item) { return item.available; });

		$scope.newMobileTabs = [
			{
				key: 0,
				title: translationService.translate('web-enum-MobileMenuProfileTabs-Activity', 'Aktiviteter'),
				available: (moduleService.hasModule(moduleService.moduleFlags.Activity) || moduleService.hasModule(moduleService.moduleFlags.SparePart)),
				visible: (moduleService.hasModule(moduleService.moduleFlags.Activity) || moduleService.hasModule(moduleService.moduleFlags.SparePart)) ? !$scope.isUpdate : false,
				icon: 'zmdi-case'
			},
			{
				key: 1,
				title: translationService.translate('web-enum-MobileMenuProfileTabs-Objects', 'Objekter'),
				available: moduleService.hasModule(moduleService.hasModule(moduleService.moduleFlags.SparePart)),
				visible: moduleService.hasModule(moduleService.hasModule(moduleService.moduleFlags.SparePart)) ? !$scope.isUpdate : false,
				icon: 'zmdi-layers'
			},
			{
				key: 2,
				title: translationService.translate('web-enum-MobileMenuProfileTabs-QrScan', 'Scan'),
				available: moduleService.hasModule(moduleService.moduleFlags.Core),
				visible: moduleService.hasModule(moduleService.moduleFlags.Core) ? !$scope.isUpdate : false,
				icon: 'zmdi-fullscreen'
			}
		];
		$scope.newMobileTabs = $scope.newMobileTabs.filter(function (tab) { return tab.available; });

		$scope.getVisibleTabs = function () {
			return $scope.newMobileTabs.filter(function (t) { return t.visible; });
		};

		//IMPORTANT: This list has to be in sync with same list on homecontroller in Plania.Mobile. order needs to be exactly the same. Changing order will break existing mobilemenues saved in database
		$scope.oldMobileAvailableMenus = [
			{ key: 'MyWorkOrders', title: translationService.translate('mobile-menu-myWorkOrders', 'Mine Arbeidsordre'), featured: true, available: moduleService.hasModule(moduleService.moduleFlags.Activity), imgSrc: 'images/icons/ionicons/briefcase.png' },
			{ key: 'WorkOrders', title: translationService.translate('mobile-menu-allWorkOrders', 'Alle Arbeidsordre'), featured: false, available: moduleService.hasModule(moduleService.moduleFlags.Activity), imgSrc: 'images/icons/ionicons/list-outline.png' },
			{ key: 'WorkOrderHistory', title: translationService.translate('mobile-menu-workOrderHistory', 'Arbeidsordre Historikk'), featured: false, available: moduleService.hasModule(moduleService.moduleFlags.Activity), imgSrc: 'images/icons/ionicons/filing.png' },
			{ key: 'CreateWorkOrder', title: translationService.translate('mobile-menu-newWorkOrder', 'Ny Arbeidsordre'), featured: false, available: moduleService.hasModule(moduleService.moduleFlags.Activity), imgSrc: 'images/icons/ionicons/plus-circled.png' },
			{ key: 'CreateRequest', title: translationService.translate('mobile-menu-newRequest', 'Ny Melding'), featured: false, available: moduleService.hasModule(moduleService.moduleFlags.Activity), imgSrc: 'images/icons/ionicons/chatbubble.png' },
			{ key: 'Barcode', title: translationService.translate('mobile-menu-barcodes', 'QR-koder'), featured: false, available: moduleService.hasModule(moduleService.moduleFlags.Core), imgSrc: 'images/icons/ionicons/qr-scanner.png' },
			{ key: 'MyRequests', title: translationService.translate('mobile-menu-myMessages', 'Mine Meldinger'), featured: false, available: moduleService.hasModule(moduleService.moduleFlags.Activity), imgSrc: 'images/icons/ionicons/chatbubbles.png' },
			{ key: 'Inventory', title: translationService.translate('mobile-menu-inventory', 'Varelager'), featured: false, available: moduleService.hasModule(moduleService.moduleFlags.SparePart), imgSrc: 'images/icons/ionicons/box.png' },
			{ key: 'SparePartCounting', title: translationService.translate('mobile-menu-sparePartCounting', 'Varetelling'), featured: false, available: moduleService.hasModule(moduleService.moduleFlags.InventoryControl), imgSrc: 'images/icons/ionicons/clipboard.png' },
		];

		$scope.selectedMenus = [];
		if ($scope.isUpdate) {
			repository.getSingle(repository.apiData.mobileMenuProfile.url, $stateParams.guid).then(
				function (result) {
					$scope.model = result.Data;
					// Mobile version 2 setup
					if ($scope.model.ActivityMenuList) {
						$scope.model.ActivityMenuList.split(";").map(Number).forEach(function (key) {
							var menuItem = $scope.activityPageItems.find(function (item) { return item.key === key; });
							if (menuItem) {
								menuItem.visible = true;
							}
						});
					}

					if ($scope.model.TabList) {
						$scope.model.TabList.split(";").map(Number).forEach(function (key) {
							var tabItem = $scope.newMobileTabs.find(function (tab) { return tab.key === key; });
							if (tabItem) {
								tabItem.visible = true;
							}
						});
					}

					// Mobile version 1 setup
					// get selected menus from menu list and featured menu list strings
					var menuIndexes = $scope.model.MenuList.split(";").map(Number);
					// set element in selected menus only if its index is in the menu list
					for (var i = 0; i < menuIndexes.length; i++)
						$scope.selectedMenus.push($scope.oldMobileAvailableMenus[menuIndexes[i]]);
					menuIndexes = $scope.model.FeaturedMenuList.split(";").map(Number);
					// set element as featured only if its index is in the featured menu list
					for (i = 0; i < $scope.oldMobileAvailableMenus.length; i++)
						$scope.oldMobileAvailableMenus[i].featured = (menuIndexes.indexOf(i) !== -1);

					updateAccess();
				},
				function (error) {
					repository.growl(error, 'danger');
				});
		} else {
			$scope.oldMobileAvailableMenus.forEach(function (menu) {
				if (menu.available) {
					$scope.selectedMenus.push(menu);
				}
			});
		}

		$scope.update = function (destination) {
			var success = function (result) {
				repository.growl($scope.isUpdate ? 'Web profil har blitt lagret' : 'Web profil har blitt opprettet', 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (result) {
				repository.growl(result, 'danger');
			};

			// Version 2 mobile setup
			$scope.model.ActivityMenuList = $scope.activityPageItems.filter(function (item) { return item.available && item.visible; }).map(function (item) { return item.key; }).join(";");
			$scope.model.TabList = $scope.newMobileTabs.filter(function (tab) { return tab.available && tab.visible; }).map(function (tab) { return tab.key; }).join(";");

			// Version 1 mobile setup
			// prepare menu list and featured menu list strings from selected menus
			var availableMenuKeys = $scope.oldMobileAvailableMenus.map(function (element) { return element.key; });
			var menuList = [];
			for (var i = 0; i < $scope.selectedMenus.length; i++)
				menuList.push(availableMenuKeys.indexOf($scope.selectedMenus[i].key));

			$scope.model.MenuList = menuList.join(";");
			$scope.model.FeaturedMenuList = menuList.filter(function (element) { return $scope.oldMobileAvailableMenus[element].featured === true; }).join(";");

			if ($scope.isUpdate)
				repository.updateSingleDictionary(repository.apiData.mobileMenuProfile.url, $scope.model).then(success, error);
			else
				repository.createSingleDictionary(repository.apiData.mobileMenuProfile.url, $scope.model).then(success, error);
		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-mobileMenuProfile-message', 'Mobilmenyprofilen vil bli fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-mobileMenuProfile-button-confirm', 'Ja, fjern mobilmenyprofilen!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.mobileMenuProfile.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-mobileMenuProfile-success', 'Mobilmenyprofilen ble fjernet!'), result, "success");
						$scope.goBack('mobileMenuProfile.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};

		$scope.removeMenu = function (index, menu) {
			$scope.selectedMenus.splice(index, 1);
		};

		$scope.addMenu = function () {
			var menus = [];
			$scope.oldMobileAvailableMenus.forEach(function (menu) {
				if (!_.find($scope.selectedMenus, { key: menu.key })) {
					if (menu.available)
						menus.push(menu);
				}
			});

			$modal.open({
				templateUrl: 'app/mobileMenuProfile/views/addMobileMenuModal.html',
				controller: 'AddMobileMenuModalController',
				resolve: {
					params: function () { return { menus: menus }; }
				}
			}).result.then(function (selectedMenu) {
				$scope.selectedMenus.push(selectedMenu);
			}, function () {
				//cancel
			});
		};

		// User table
		if ($scope.isUpdate) {
			$scope.selectedUsers = [];

			$scope.addSelectedUser = function (entity) {
				if (!_.find($scope.selectedUsers, function (o) { return entity.Guid === o.Guid; }))
					$scope.selectedUsers.push(entity);
				else {
					for (var i = 0; i < $scope.selectedUsers.length; i++) {
						if ($scope.selectedUsers[i].Guid === entity.Guid) {
							$scope.selectedUsers.splice(i, 1);
							return;
						}
					}
				}
			};

			$scope.isUserSelected = function (guid) {
				return _.find($scope.selectedUsers, function (o) { return guid === o.Guid; });
			};

			$scope.userColumns = [
				{ Position: 1, Title: translationService.translate('web-user-realName', 'Navn'), Property: 'RealName', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-user-username', 'Username'), Property: 'Username', PropertyType: 'string' },
			];

			$scope.userTableSearch = {
				show: false
			};

			$scope.userTable = new ngTableParams({
				page: 1,
				count: 25,
				sorting: { 'RealName': 'asc' },
				filter: { PropertyFilter: [{ Property: 'GuidMobileMenuProfile', operator: '=', value: $stateParams.guid }] }
			}, {
				total: 0,
				filterDelay: 10,
				counts: [25, 50, 100, 250],
					getData: function ($defer, params) {
					var columns = [];
					$scope.userColumns.forEach(function (col) {
						columns.push(col.Property);
					});

					repository.GetPaginated(repository.apiData.user.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(function (result) {
						$scope.userTable.settings().total = result.TotalCount;
						$scope.userTable.settings().filterDelay = 500;
						$defer.resolve(result.List);
					}, function (error) {
						repository.growl(error, 'danger');
					});
				}
			});

			$scope.addUsers = function () {
				$modal.open({
					templateUrl: 'app/common/views/multipleSelectModal.html',
					controller: 'MultipleSelectModalController',
					resolve: {
						modalParams: function () {
							return {
								title: 'Legg til brukere',
								url: repository.apiData.user.url,
								sorting: { 'Username': 'asc' },
								columns: [
									{ Title: translationService.translate('web-user-username'), Property: 'Username', PropertyType: 'string' },
									{ Title: translationService.translate('web-user-realname'), Property: 'RealName', PropertyType: 'string' },
									{ Title: translationService.translate('web-user-existingMobileMenu', 'Eksisterende meny'), Property: 'MobileMenuProfile.Id', PropertyType: 'string' }
								],
								filter: {
									LimitToMobileUsers: true,
									PropertyFilter: [{ Property: 'GuidMobileMenuProfile', operator: '<>', value: $stateParams.guid }]
								},
								useObjects: false
							};
						}
					}
				}).result.then(function (selectedGuids) {
					var selectedEntities = [];
					selectedGuids.forEach(function (guid) {
						selectedEntities.push(JSON.stringify({ Guid: guid, GuidMobileMenuProfile: $scope.model.Guid }));
					});
					repository.createMultiple(repository.apiData.massUpdate.url, selectedEntities, 'Prefix=' + repository.apiData.user.prefix).then(function (result) {
						repository.growl("Brukerene har blitt lagt til", 'success');
						$scope.userTable.reload();
					});
				}, function (error) {

				});
			};

			$scope.removeUser = function (user) {
				if (!user && $scope.selectedUsers.length === 0) {
					swal('Ingen valg', translationService.translate('web-mobilemenuprofile-delete-selectedUsers-empty',
							'Ingen valg. Velg en eller flere rader fra listen for å koble fra brukere fra mobilmenyen.'),
						'error');
					return;
				}

				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: user ? translationService.translate('web-swal-mobilemenuprofile-user-delete-message', "Brukeren vil bli fjernet fra mobilmenyen!") : translationService.translate('web-swal-mobilemenuprofile-selectedUsers-delete-message', "Brukerene vil bli fjernet fra mobilmenyen!"),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: user ? translationService.translate('web-swal-mobilemenuprofile-user-delete-button-confirm', 'Ja, fjern brukeren') : translationService.translate('web-swal-mobilemenuprofile-selectedUsers-delete-button-confirm', 'Ja, fjern brukerene'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;
					var users = [];
					if (user) {
						users.push(JSON.stringify({ Guid: user.Guid, GuidMobileMenuProfile: null }));
					} else {
						$scope.selectedUsers.forEach(function (usr) {
							users.push(JSON.stringify({ Guid: usr.Guid, GuidMobileMenuProfile: null }));
						});
					}
					repository.createMultiple(repository.apiData.massUpdate.url, users, 'Prefix=' + repository.apiData.user.prefix).then(function (result) {
						if (user) {
							swal('Suksess!', translationService.translate('web-swal-mobilemenuprofile-user-delete-success', 'Brukeren ble fjernet!'), "success");
						} else {
							swal('Suksess!', translationService.translate('web-swal-mobilemenuprofile-selectedUsers-delete-success', 'Brukerene ble fjernet!'), "success");
						}
						$scope.userTable.reload();
					});
				});
			};

			$scope.userMenuOptions = function (user) {
				var options = [];
				if (!$scope.restrictEdit) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-delete invisible zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-mobilemenuprofile-user-remove', 'Koble fra bruker') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							$scope.removeUser(user);
						}
					});
				}
				return options;
			};

			$scope.goToUser = function (user) {
				$state.go('user.edit', { guid: user.Guid });
			};
		}

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});

		$scope.getPropertyValue = function (row, column) {
			return listService.GetPropertyValue(row, column);
		};
	}
})();
