(function () {
	angular.module('Plania').controller('EmailTemplatePreviewModalController', ['$scope', '$modalInstance', 'params', 'TranslationService', 'Repository', 'NgTableParams', 'ListService', '$sce', '$filter', controller]);

	function controller($scope, $modalInstance, params, translationService, repository, ngTableParams, listService, $sce, $filter) {
		$scope.prefix = params.prefix;
		$scope.body = params.body;

		var propertiesFromBody = [];
		var userProperties = [];
		var columnsFromBody = [];
		var dottedColumns = [];
		var customProperties = [];

		propertiesFromBody = $scope.body.match(/{{\s*[\w\.]+\s*}}/g);
		userProperties = $scope.body.match(/{{\@\s*[\w\.]+\s*}}/g);
		customProperties = $scope.body.match(/{{\#\s*[\w\.]+\s*}}/g);

		if (propertiesFromBody) {
			columnsFromBody = propertiesFromBody.map(function (x) { return x.match(/[\w\.]+/)[0]; });
			dottedColumns = _.filter(columnsFromBody, function (o) { return o.indexOf('.') !== -1; });
		}

		//Wizard Setup
		$scope.tabs = [
			{ heading: translationService.translate('web-emailPreview-selectEntity', 'Velg objekt'), step: 0 },
			{ heading: translationService.translate('web-emailPreview-preview', 'Forhåndsvisning'), step: 1 },
		];

		$scope.steps = ['one', 'two'];

		$scope.step = 0;

		$scope.isCurrentStep = function (step) {
			return $scope.step === step;
		};

		$scope.setCurrentStep = function (step) {
			$('.tab-content').css('overflow', 'hidden');
			setTimeout(function () {
				$('.tab-content').css('overflow', 'visible');
			}, 500);
			$scope.step = step;
		};

		//End Wizard setup
		var camelCasePrefix = _.camelCase(params.prefix);

		var url = repository.apiData[camelCasePrefix].url;

		$scope.entityColumns = [
			{ Position: 1, Title: 'Id', Property: 'Id' },
			{ Position: 2, Title: 'Beskrivelse', Property: 'Description' },
		];

		$scope.getPropertyValue = function (row, column) {
			return listService.GetPropertyValue(row, column);
		};

		$scope.entityTable = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { Id: 'desc' },
			filter: { active: true }
		}, {
				total: 0,
				counts: [10, 25, 50],
				paginationMaxBlocks: 10,
				getData: function ($defer, params) {
					var columns = [];
					$scope.entityColumns.forEach(function (column) {
						columns.push(column.Property);
					});

					repository.GetPaginated(url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(
						function (result) {
							params.total(result.TotalCount);
							$defer.resolve(result.List);
						}, function (error) {
							repository.growl(error, 'danger');
						});
				}
			});

		$scope.selectEntity = function (entity) {
			if ($scope.isSaving) return;

			var selectedEntity = entity;

			repository.getSingle(url, selectedEntity.Guid, JSON.stringify(dottedColumns)).then(function (response) {
				$scope.model = response.Data;
				$scope.setCurrentStep(1);
			});
		};

		var customPropertyFunctions = [
			{
				property: '#web_base_url',
				getValue: function () { return window.location.protocol + '//' + window.location.host; }
			}
		];

		$scope.getEmailBodyWithValues = function (string) {
			if (!string) return '';
			var properties = propertiesFromBody;

			if (!properties)
				properties = [];

			var mappedProperties = properties.map(function (x) { return x.match(/[\w\.]+/)[0]; });

			var tmpString = string;

			for (var i = 0; i < properties.length; i++) {
				tmpString = tmpString.replace(properties[i], getPropertyValue(mappedProperties[i]));
			}

			if (userProperties && userProperties.length > 0) {
				var mappedUserProperties = userProperties.map(function (x) { return x.match(/[\@\w\.]+/)[0]; });

				for (var j = 0; j < userProperties.length; j++) {
					tmpString = tmpString.replace(userProperties[j], $scope.userInfo[mappedUserProperties[j].replace('@CurrentUser.', '')]);
				}
			}

			if (customProperties && customProperties.length > 0) {
				var mappedCustomProperties = customProperties.map(function (x) { return x.match(/[\#\w\.]+/)[0]; });
				for (var k = 0; k < customProperties.length; k++) {
					var prop = _.find(customPropertyFunctions, { property: mappedCustomProperties[k] });
					if (prop) {
						tmpString = tmpString.replace(customProperties[k], prop.getValue());
					}
				}
			}

			return $sce.trustAsHtml(tmpString);
		};

		var getUserInfoProperty = function (property) {
			return $scope.userInfo[property];
		};

		var getPropertyValue = function (property) {
			var properties = property.split('.');
			var propertyValue = $scope.model;

			properties.forEach(function (prop) {
				if (propertyValue) {
					propertyValue = propertyValue[prop];
				}
			});

			if (typeof (propertyValue) === 'string' && isNaN(Number(propertyValue)) && moment(propertyValue).isValid()) {
				propertyValue = $filter('date')(propertyValue, 'dd.MM.yyyy');
			}

			return propertyValue ? propertyValue : '';
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}
})();
