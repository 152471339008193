(function () {
	angular.module('Plania').controller('MultipleCleaningCompletionsController', ['$scope', 'Repository', 'params', '$modalInstance', 'config', 'TranslationService', '$filter', 'ListService', controller]);

	function controller($scope, repository, params, modalInstance, config, translationService, $filter, listService) {
		var user = repository.authService.getUserData();

		$scope.person = {
			Caption: user.realName,
			Guid: user.guidPerson
		};
		$scope.activateAutocomplete = false;

		$scope.cleaningTasks = [];
		$scope.cleaningTasksAreaGrouped = [];
		$scope.cleaningTasksCleaningTypeGrouped = [];
		$scope.cleaningTaskGroupBy = 'none';
		$scope.isAllTasksSelected = false;
		$scope.isRunningAction = false;

		var columns = [
			'AverageCleaningPerYear',
			'CleaningTask.Id', 'CleaningTask.CleaningType', 'CleaningTask.AverageCleaningPerYear', 'CleaningTask.ExplanatoryText',
			'Area.Id', 'Area.Description',
			'DelayedDate', 'ExitCleaningRequestStatus', 'ExitCleaningConfirmedDate', 'ExitCleaningDecisionDate', 'NextCleaningDate'
		];

		var filter = {
			showTodaysTasks: true,
			PropertyFilter: [
				{ Property: 'GuidArea', Operator: 'in', Value: params.guids.join(',') }
			]
		};

		repository.GetPaginated(repository.apiData.areaXCleaningTask.url, 0, -1, {}, filter, null, JSON.stringify(columns)).then(function (result) {
			$scope.cleaningTasks = result.List;

			$scope.cleaningTasks.sort(sortByCleaningType);

			$scope.cleaningTasks.forEach(function (task) {
				var areaGroup = _.find($scope.cleaningTasksAreaGrouped, function (grouped) { return grouped.header === task.Area.Caption; });
				var cleaningTypeGroup = _.find($scope.cleaningTasksCleaningTypeGrouped, function (grouped) { return grouped.header === task.CleaningTask.CleaningType; });
				if (!areaGroup)
					$scope.cleaningTasksAreaGrouped.push({ header: task.Area.Caption, items: [task] });
				else
					areaGroup.items.push(task);

				if (!cleaningTypeGroup)
					$scope.cleaningTasksCleaningTypeGrouped.push({ header: task.CleaningTask.CleaningType, items: [task] });
				else
					cleaningTypeGroup.items.push(task);

				if (task.CleaningTask.CleaningType === 'StandardCleaning')
					$scope.selectedTasks.push(task.Guid);
			});

			$scope.cleaningTasksAreaGrouped.sort(function (a, b) { return ('' + a.header).localeCompare(b.header); });
			checkSelectedTasks();
			setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
		}).catch(function (error) {
			repository.growl(error, 'danger');
		});

		$scope.selectedTasks = [];

		var checkSelectedTasks = function (skipGroup) {
			if ($scope.selectedTasks.length === $scope.cleaningTasks)
				$scope.isAllTasksSelected = true;
			else
				$scope.isAllTasksSelected = false;
			if (!skipGroup) {
				$scope.cleaningTasksAreaGrouped.forEach(function (task) {
					var guids = task.items.map(function (item) { return item.Guid; });
					var selected = $scope.selectedTasks.filter(function (guidTask) {
						return guids.indexOf(guidTask) >= 0;
					});

					task.allTasksSelected = selected.length === guids.length;
				});

				$scope.cleaningTasksCleaningTypeGrouped.forEach(function (task) {
					var guids = task.items.map(function (item) { return item.Guid; });
					var selected = $scope.selectedTasks.filter(function (guidTask) {
						return guids.indexOf(guidTask) >= 0;
					});

					task.allTasksSelected = selected.length === guids.length;
				});
			}
		};

		$scope.selectTask = function (task) {
			if (!_.contains($scope.selectedTasks, task.Guid)) {
				$scope.selectedTasks.push(task.Guid);
				checkSelectedTasks();
			}
			else {
				for (var i = 0; i < $scope.selectedTasks.length; i++) {
					var p = $scope.selectedTasks[i];
					if (p === task.Guid) {
						$scope.selectedTasks.splice(i, 1);
						checkSelectedTasks();
						return;
					}
				}
			}
		};

		$scope.isSelected = function (guid) {
			return _.contains($scope.selectedTasks, guid);
		};

		$scope.selectMultipleTasks = function () {
			$scope.selectedTasks = [];
			if (!$scope.isAllTasksSelected) 
				$scope.cleaningTasks.forEach(function (task) { $scope.selectedTasks.push(task.Guid); });

			$scope.isAllTasksSelected = !$scope.isAllTasksSelected;
			$scope.cleaningTasksAreaGrouped.forEach(function (group) { group.allTasksSelected = $scope.isAllTasksSelected; });
			$scope.cleaningTasksCleaningTypeGrouped.forEach(function (group) { group.allTasksSelected = $scope.isAllTasksSelected; });
		};

		$scope.selectMultipleGroupedTasks = function (group) {
			if (!group || $scope.cleaningTaskGroupBy === 'none') return;
			var list = $scope.cleaningTaskGroupBy === 'area' ? $scope.cleaningTasksAreaGrouped : $scope.cleaningTasksCleaningTypeGrouped;
			var guids = _.find(list, function (l) { return l.header === group.header; }).items.map(function (item) { return item.Guid; });

			if (group.allTasksSelected) {
				$scope.selectedTasks = $scope.selectedTasks.filter(function (guidTask) {
					return guids.indexOf(guidTask) < 0;
				});
			}
			else {
				guids.forEach(function (guid) {
					if ($scope.selectedTasks.indexOf(guid) < 0)
						$scope.selectedTasks.push(guid);
				});
			}
			group.allTasksSelected = !group.allTasksSelected;

			checkSelectedTasks(true);
		};

		$scope.getNextCleaningDateString = function (task) {
			return listService.getNextCleaningDateString(task, true);
		};

		var sortByCleaningType = function (left, right) {
			if (left.CleaningTask.CleaningType === 'StandardCleaning') return -1;
			else if (right.CleaningTask.CleaningType === 'StandardCleaning') return 1;
			if (left.CleaningTask.CleaningType === 'InfectionCleaning') return -1;
			else if (right.CleaningTask.CleaningType === 'InfectionCleaning') return 1;
			if (left.CleaningTask.CleaningType === 'ExitCleaning') return -1;
			else if (right.CleaningTask.CleaningType === 'ExitCleaning') return 1;
			if (left.CleaningTask.CleaningType === 'AdditionalCleaning') return -1;
			else if (right.CleaningTask.CleaningType === 'AdditionalCleaning') return 1;
			if (left.CleaningTask.CleaningType === 'PeriodicCleaning') return -1;
			else if (right.CleaningTask.CleaningType === 'PeriodicCleaning') return 1;
			if (left.CleaningTask.CleaningType === 'Undefined') return -1;
			else if (right.CleaningTask.CleaningType === 'Undefined') return 1;
			else if (left.CleaningTask.CleaningType === right.CleaningTask.CleaningType) return 0;
		};


		$scope.autocompleteFilter = function (filterName) {
			switch (filterName) {
			case "person":
				return {
					PropertyFilter: [
						{ Property: 'DOES_CLEANING_TASKS', Operator: '=', Value: '1' }
					]
				};
			}
			return null;
		};

		$scope.save = function () {
			var completionList = [];

			$scope.selectedTasks.forEach(function (taskGuid) {
				var task = _.find($scope.cleaningTasks, function (o) { return o.Guid === taskGuid; });
				var cleaningCompletion = {
					GuidCleaningTask: task.GuidCleaningTask,
					GuidArea: task.GuidArea,
					GuidPerson: $scope.person.Guid,
					ExplanatoryText: task.ExplanatoryText,
					ExtraTime: task.ExtraTime,
					EstimatedTime: Math.ceil(task.EstimatedTime * 60),
					CompletionStatus: 'Completed'
				};

				if (task.CleaningTask.CleaningType === 'ExitCleaning') {
					cleaningCompletion.ExitCleaningDecisionDate = task.ExitCleaningDecisionDate;
					cleaningCompletion.ExitCleaningConfirmedDate = task.ExitCleaningConfirmedDate;
					cleaningCompletion.ExitCleaningRequestStatus = task.ExitCleaningRequestStatus;
				}
				completionList.push(JSON.stringify(cleaningCompletion));
			});
			$scope.isRunningAction = true;
			repository.createMultiple(repository.apiData.cleaningCompletion.url, completionList).then(function (result) {
				$scope.isRunningAction = false;
				repository.growl('Rengjøringen har blitt lagret', 'success');
				$scope.ok();
			}, function (error) {
				$scope.isRunningAction = false;
				repository.growl(error, 'danger');
			});
		};


		$scope.delayCleaning = function () {
			for (var i = 0; i < $scope.selectedTasks.length; i++) {
				var selectedTask = _.find($scope.cleaningTasks, { Guid: $scope.selectedTasks[i] });
				if (selectedTask.CleaningTask.CleaningType !== 'StandardCleaning' && selectedTask.CleaningTask.CleaningType !== 'Undefined') {
					swal({
						title: translationService.translate('web-swal-cleaningCompletion-delay-nonStandard-title', 'Kan ikke utsette engangsoppgaver'),
						text: translationService.translate('web-swal-cleaningCompletion-delay-nonStandard-message', "Fjern kryssene på valgte engangsoppgaver for å utsette daglig renhold"),
						type: "warning",
						confirmButtonColor: "#f44336",
						confirmButtonText: translationService.translate('web-swal-button-ok', 'Ok'),
						closeOnConfirm: false
					});
					return;
				}
			}

			var changeList = [];
			$scope.selectedTasks.forEach(function (taskGuid) {
				changeList.push(JSON.stringify({ Guid: taskGuid, DelayedDate: new Date().toISOString() }));
			});
			$scope.isRunningAction = true;
			repository.patch(repository.apiData.areaXCleaningTask.url, null, changeList).then(function (result) {
				$scope.isRunningAction = false;
				repository.growl(translationService.translate('web-cleaningTask-delayedTask', 'Rengjøring utsatt'), 'success');
				$scope.ok('delayed');
			}, function (error) {
				$scope.isRunningAction = false;
				repository.growl(error, "danger");
			});
		};

		$scope.disableCompletion = function () {
			return _.find($scope.selectedTasks, function (guid) {
				var selectedTask = _.find($scope.cleaningTasks, function (task) { return guid === task.Guid; });
				return selectedTask && !selectedTask.Recurrence && !selectedTask.ExtraTime && selectedTask.CleaningTask.CleaningType !== 'ExitCleaning';
			});
		};

		$scope.disableCleaningCompletionForm = function (form) {
			if ($scope.selectedTasks.length < 1)
				return true;
			if ($scope.disableCompletion())
				return true;
			if (form && !form.$valid)
				return true;
			return false;
		};

		$scope.ok = function (result) {
			modalInstance.close(result);
		};

		$scope.cancel = function () {
			modalInstance.dismiss('cancel');
		};
	}
})();
