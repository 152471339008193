(function() {

    angular.module('Plania').directive('plPrint', function() {
        return {
            restrict: 'A',
            scope: {
                columns: '=columns', //selectedListSetup.Columns
                table: '=table', //table to print
                guids: '=guids', //selectedGuids
                domainName: '=domainName', //domainname of object to be printed
                sorting: '=sorting', //sorting column and direction stating which order to show items on report
            },
			controller: ['$scope', '$rootScope', '$window', '$localStorage', '$timeout', 'TranslationService', printController],
            link: linkFunction,
            template: '<a href="" tooltip-append-to-body="true" tooltip="{{\'Print liste\' | translate: \'print-tooltip-list\' }}" ng-click="printList()"><i class="zmdi zmdi-print"></i></a>'
        };
    });

    var printController = function ($scope, $rootScope, $window, $localStorage, $timeout, translationService) {

        $scope.printList = function () {
            var columns = [];

            $scope.columns.forEach(function (col) {
                columns.push({ Title: col.Title, Property: col.Property });
            });

            if ($scope.guids.length === 0) {
				$scope.table.data.forEach(function (row) {
					if (row.Guid) {
						if (!_.contains($scope.guids, row.Guid)) {
							$scope.guids.push(row.Guid);
						}
					} else if (row.data) {
						row.data.forEach(function(data) {
							if (data.Guid) {
								if (!_.contains($scope.guids, data.Guid)) {
									$scope.guids.push(data.Guid);
								}
							}
						});
					}
				});
			}

			var systemName = 'print-' + $scope.domainName.toLowerCase() + '-list-header';

			var translatedHeader = translationService.translate(systemName);

			if (translatedHeader === systemName) {
				var prefixSystemName = 'web-enum-prefix-' + $scope.domainName.toLowerCase();
				translatedHeader = translationService.translate('web-enum-prefix-' + $scope.domainName);
				if (translatedHeader === prefixSystemName) //if prefix is not translated return the systemname to translate instead of prefix systemname
					translatedHeader = systemName;
			}


	        $localStorage.reportParams = JSON.stringify({
            	DomainName: $scope.domainName,
            	Header: translatedHeader,
            	Guids: JSON.stringify($scope.guids),
            	Columns: JSON.stringify(columns),
				Sorting: JSON.stringify($scope.sorting),
            });

	        var url = $rootScope.navigation.href('report.print',
	        {
	        	reportName: 'Plania.API.Reports.EntityPrintList, Plania.API',
	        });

	        $timeout(function () {
	        	$window.open(url, '_blank');
	        }, 100);
        };
    };

    var linkFunction = function (scope, element, attrs) { };

})();

