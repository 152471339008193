//This directive displays a menu (list or anchors) for my profile, plania mobile qr code, logout etc
(function () {

    var app = angular.module('Plania');

    app.directive('userProfileMenu', function () {
		return {
			templateUrl: function (elem, attr) {
				return '/app/common/directives/views/userProfileMenu' + attr.userProfileMenu + '.html';
			}
        };
    });
})();
