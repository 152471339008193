(function () {
	angular.module('Plania').controller('CleaningCompletionController', ['$scope', 'Repository', '$state', '$modal', 'config', 'TranslationService', '$filter', 'ListService', controller]);

	function controller($scope, repository, $state, $modal, config, translationService, $filter, listService) {
		var user = repository.authService.getUserData();
		$scope.model.GuidPerson = user.guidPerson;
		$scope.person = user.realName;

		$scope.images = [];
		$scope.isCorrecting = true;
		$scope.activateAutocomplete = false;
		$scope.doesCleaningTasks = false;
		$scope.isRunningAction = false;

		$scope.hiddenDailyCleaningTasks = [];

		repository.getSingle(repository.apiData.person.url, $scope.model.GuidPerson).then(function (result) {
			$scope.doesCleaningTasks = result.Data.DoesCleaningTasks;
		});

		var getArea = function (guidArea) {
			repository.getSingle(repository.apiData.area.url, guidArea).then(function (area) {
				$scope.area = area.Data;
				$scope.showGoToDrawingButton = $scope.area.GuidDrawing && $state.current.name !== 'dwg.view';

				if ($scope.area && $scope.area.MustCorrectDrawing) {
					$scope.model.MustCorrectDrawing = $scope.area.MustCorrectDrawing;
					$scope.model.MustCorrectDrawingComment = $scope.area.MustCorrectDrawingComment;

					$scope.isCorrecting = $scope.model.MustCorrectDrawing === true ? true : false;

				} else {
					$scope.isCorrecting = false;
				}
				$scope.reload = true;
				setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
			});

			getCleaningTasks(guidArea);
		};

		$scope.selectedTasks = [];
		$scope.selectedInfoTasks = [];
		$scope.selectedObjectInfoTasks = [];

		$scope.selectTask = function (task) {
			if (!_.contains($scope.selectedTasks, task.Guid)) {
				$scope.selectedTasks.push(task.Guid);
				$scope.selectedObjectInfoTasks.push(task);
			}
				
			else {
				for (var i = 0; i < $scope.selectedTasks.length; i++) {
					var p = $scope.selectedTasks[i];
					if (p === task.Guid) {
						$scope.selectedTasks.splice(i, 1);
						$scope.selectedObjectInfoTasks.splice(i, 1);
						return;
					}
				}
			}
		};

		$scope.isSelected = function (guid) {
			return _.contains($scope.selectedTasks, guid);
		};

		$scope.selectInfoTask = function (task) {
			if (!_.contains($scope.selectedInfoTasks, task.Guid)) {
				$scope.selectedInfoTasks.push(task.Guid);
			}

			else {
				for (var i = 0; i < $scope.selectedInfoTasks.length; i++) {
					var p = $scope.selectedInfoTasks[i];
					if (p === task.Guid) {
						$scope.selectedInfoTasks.splice(i, 1);
						return;
					}
				}
			}
		};
		$scope.isInfoSelected = function (task) {
			return _.contains($scope.selectedInfoTasks, task.Guid);
		};

		var getCleaningTasks = function (guidArea) {
			var columns = ['EstimatedTime', 'CleaningTask.Id', 'CleaningTask.CleaningType', 'CleaningTask.EstimatedTime', 'CleaningTask.ExplanatoryText','DelayedDate', 'ExitCleaningRequestStatus', 'ExitCleaningConfirmedDate', 'ExitCleaningDecisionDate', 'NextCleaningDate'];
			var filter = {
				showTodaysTasks: true,
				PropertyFilter: [
					{ Property: 'GuidArea', Operator: '=', Value: guidArea }
				]
			};

			repository.GetPaginated(repository.apiData.areaXCleaningTask.url, 0, 50, {}, filter, null, JSON.stringify(columns))
				.then(function (result) {
					$scope.cleaningTasks = result.List;

					$scope.exitCleaningTask = _.find($scope.cleaningTasks, function (task) {
						return task.CleaningTask.CleaningType === 'ExitCleaning';
					});
					

					if ($scope.exitCleaningTask) {
						$scope.cleaningTasks.sort(function (x, y) {
							return x.CleaningTask.CleaningType === 'ExitCleaning' ? -1 : y.CleaningTask.CleaningType === 'ExitCleaning' ? 1 : 0;
						});

						if ($scope.exitCleaningTask.ExitCleaningRequestStatus === 'Confirmed' || $scope.exitCleaningTask.ExitCleaningRequestStatus === 'Discharged') {
							$scope.selectedTasks = [];
							$scope.selectTask($scope.exitCleaningTask);

							$scope.hiddenDailyCleaningTasks = $scope.cleaningTasks.filter(function (task) {
								if (task.CleaningTask.CleaningType === 'StandardCleaning')
									return task;
							});

							$scope.cleaningTasks = $scope.cleaningTasks.filter(function (task) {
								if (task.CleaningTask.CleaningType !== 'StandardCleaning')
									return task;
								
							});
						}	
					}
						
					else if ($scope.task && _.find($scope.cleaningTasks, { Guid: $scope.task.Guid }))
						$scope.selectTask(_.find($scope.cleaningTasks, { Guid: $scope.task.Guid }));
					else {
						if ($scope.cleaningTasks.length === 1)
							$scope.selectTask($scope.cleaningTasks[0]);
					}

				}).catch(function (error) {
					repository.growl(error, 'danger');
				});
		};

		$scope.deleteImage = function (image, index) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-image-message', "Bildet vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-image-button-confirm', 'Ja, fjern bildet'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.image.url, image.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-image-success', 'Bildet ble fjernet!'), result, "success");
						$scope.area.Images.splice(index, 1);
					});
			});
		};

		$scope.init = function (guidArea, task) {
			$scope.model = {
				GuidArea: guidArea,
				GuidPerson: user.guidPerson
			};
			$scope.task = task;
			getArea(guidArea);
		};

		$scope.autocompleteFilter = function (filterName) {
			switch (filterName) {
				case "cleaningTask":
					return {
						PropertyFilter: [
							{ Property: 'IS_ADDITIONAL_TASK', Operator: '=', Value: '1' },
						]
					};
				case "person":
					return {
						PropertyFilter: [
							{ Property: 'DOES_CLEANING_TASKS', Operator: '=', Value: '1' }
						]
					};
			}
			return null;
		};

		$scope.onPersonSelect = function (person) {
			if (!person) {
				$scope.model.GuidPerson = "";
				return;
			}

			$scope.model.GuidPerson = person.Guid;
		};

		$scope.addNewImage = function () {
			$modal.open({
				templateUrl: 'app/document/views/createImageModal.html',
				controller: 'CreateDocumentXEntityController',
				size: 'lg',
				resolve: {
					params: function () {
						return { guidArea: $scope.model.GuidArea };
					}
				}
			}).result.then(function (images) {
				if (!images) return;

				images.forEach(function (image) {
					$scope.area.Images.push(image);
				});
			}, function (msg) {

			});
		};

		$scope.delayCleaning = function () {
			for (var i = 0; i < $scope.selectedTasks.length; i++) {
				var selectedTask = _.find($scope.cleaningTasks, { Guid: $scope.selectedTasks[i] });
				if (selectedTask.CleaningTask.CleaningType !== 'StandardCleaning' && selectedTask.CleaningTask.CleaningType !== 'Undefined') {
					swal({
						title: translationService.translate('web-swal-cleaningCompletion-delay-nonStandard-title', 'Kan ikke utsette engangsoppgaver'),
						text: translationService.translate('web-swal-cleaningCompletion-delay-nonStandard-message', "Fjern kryssene på valgte engangsoppgaver for å utsette daglig renhold"),
						type: "warning",
						confirmButtonColor: "#f44336",
						confirmButtonText: translationService.translate('web-swal-button-ok', 'Ok'),
						closeOnConfirm: false,
						showLoaderOnConfirm: true
					});
					return;
				}
			}

			var changeList = [];
			$scope.selectedTasks.forEach(function (taskGuid) {
				changeList.push(JSON.stringify({ Guid: taskGuid, DelayedDate: new Date().toISOString() }));
			});

			$scope.isRunningAction = true;
			repository.patch(repository.apiData.areaXCleaningTask.url, null, changeList).then(function (result) {
				$scope.isRunningAction = false;
				repository.growl(translationService.translate('web-cleaningTask-delayedTask', 'Rengjøring utsatt'), 'success');
				$scope.ok('delayed');
			}, function (error) {
				$scope.isRunningAction = false;
				repository.growl(error, "danger");
			});
		};

		$scope.save = function () {
			var completionList = [];
			var completingExitCleaningTask = false;

			$scope.selectedTasks.forEach(function (taskGuid) {
				var task = _.find($scope.cleaningTasks, function (o) { return o.Guid === taskGuid; });
				var cleaningCompletion = {
					GuidCleaningTask: task.GuidCleaningTask,
					GuidArea: task.GuidArea,
					GuidPerson: $scope.model.GuidPerson,
					ExplanatoryText: task.ExplanatoryText,
					ExtraTime: task.ExtraTime,
					MustCorrectDrawing: $scope.model.MustCorrectDrawing,
					MustCorrectDrawingComment: $scope.model.MustCorrectDrawingComment,
					EstimatedTime: Math.ceil(task.EstimatedTime * 60),
					CompletionStatus: 'Completed'
				};

				if (task.CleaningTask.CleaningType === 'ExitCleaning') {
					completingExitCleaningTask = true;
					cleaningCompletion.ExitCleaningDecisionDate = task.ExitCleaningDecisionDate;
					cleaningCompletion.ExitCleaningConfirmedDate = task.ExitCleaningConfirmedDate;
					cleaningCompletion.ExitCleaningRequestStatus = task.ExitCleaningRequestStatus;
				}
				completionList.push(JSON.stringify(cleaningCompletion));
			});

			if (completingExitCleaningTask) {
				$scope.hiddenDailyCleaningTasks.forEach(function (task) {
					var cleaningCompletion = {
						GuidCleaningTask: task.GuidCleaningTask,
						GuidArea: task.GuidArea,
						GuidPerson: $scope.model.GuidPerson,
						ExplanatoryText: task.ExplanatoryText,
						ExtraTime: task.ExtraTime,
						MustCorrectDrawing: $scope.model.MustCorrectDrawing,
						MustCorrectDrawingComment: $scope.model.MustCorrectDrawingComment,
						CompletionStatus: 'CompletedByExitCleaning',
						//EstimatedTime: Math.ceil(task.EstimatedTime * 60)
					};
					completionList.push(JSON.stringify(cleaningCompletion));
				});
			}
			$scope.isRunningAction = true;
			repository.createMultiple(repository.apiData.cleaningCompletion.url, completionList).then(function (result) {
				$scope.isRunningAction = false;
				repository.growl('Rengjøringen har blitt lagret', 'success');
				$scope.ok();
			}, function (error) {
				$scope.isRunningAction = false;
				repository.growl(error, 'danger');
			});
		};

		$scope.disableCompletion = function () {
			return _.find($scope.selectedTasks, function (guid) {
				var selectedTask = _.find($scope.cleaningTasks, function (task) { return guid === task.Guid; });
				return selectedTask && !selectedTask.Recurrence && !selectedTask.ExtraTime && selectedTask.CleaningTask.CleaningType !== 'ExitCleaning';
			});
		};

		$scope.disableCleaningCompletionForm = function (form) {
			if ($scope.selectedTasks.length < 1)
				return true;
			if ($scope.disableCompletion())
				return true;
			if (($scope.model.MustCorrectDrawing && $scope.isCorrecting) && !$scope.model.MustCorrectDrawingComment)
				return true;
			if (form && !form.$valid)
				return true;
			return false;
		};

		$scope.goToDrawing = function () {
			if ($scope.doesCleaningTasks)
				$scope.navigation.go('dwg.view', { guid: $scope.area.GuidDrawing, selectedTheme: 'cleaning', guidArea: $scope.model.GuidArea });
			else
				$scope.navigation.go('dwg.view', { guid: $scope.area.GuidDrawing, theme: 'cleaningMaintenance', guidArea: $scope.model.GuidArea });
		};

		$scope.getNextCleaningDateString = function (task) {
			return listService.getNextCleaningDateString(task, true);
		};

		$scope.filterCleaningInfo = function (task) {
			var show = false;
			if (task.AttributeValues.length > 0)
				show = true;
			if (task.ResourceGroup)
				show = true;
			if (task.CleaningTask && task.CleaningTask.ExplanatoryText)
				show = true;
			return show;
		};

		$scope.$watch('parentReload', function (newVal, oldVal) {
			if (newVal === oldVal) return;
			if ($scope.model.GuidArea)
				getCleaningTasks($scope.model.GuidArea);
		});
	}
})();
