angular.module('Plania').directive('controlListSection', ['$timeout', function ($timeout) {
	return {
		restrict: 'A',
		scope: {
			isCollapsed: '=isCollapsed'
		},
		link: function link(scope, element, attrs) {
			$timeout(function () {
				var thisId = element.parent()[0].id;
				var headerElements = $('.control-list-item .control-list-section-header').closest('.control-list-item');

				var nextHeaderId, foundCurrent;
				headerElements.each(function (index) {
					if (nextHeaderId) return;
					if (thisId === headerElements[index].id) {
						foundCurrent = true;
						return;
					} else if (foundCurrent) {
						nextHeaderId = headerElements[index].id;
					}
				});

				var controlListItems = $('#' + thisId).nextUntil('#' + nextHeaderId);
				controlListItems.wrapAll("<div class='control-list-section' />");

				$('#' + thisId + ' .control-list-section-header > h2').click(function () {
					var icon = $('#' + thisId).find('a > i.zmdi');
					if (icon.hasClass('zmdi-chevron-up')) {
						icon.removeClass('zmdi-chevron-up');
						icon.addClass('zmdi-chevron-down');
					} else {
						icon.addClass('zmdi-chevron-up');
						icon.removeClass('zmdi-chevron-down');
					}

					var mandatoryIndicator = $('#' + thisId).find('.mandatory-fields-indicator');
					var description = $('#' + thisId).find('small');
					if (description.css('display') === 'none') {
						description.css('display', 'block');
						mandatoryIndicator.css('display', 'none');
					} else {
						$timeout(function () {
							description.css('display', 'none');
						}, 200);
						mandatoryIndicator.css('display', 'inline-block');
					}

					var maxHeight = 0;
					controlListItems.each(function (index) {
						maxHeight += $(controlListItems[index]).height() + 30;
					});

					var section = $('#' + thisId).next('.control-list-section');
					if (section.hasClass('section-hidden')) {
						scope.isCollapsed = false;
						section.removeClass('section-hidden');
						section.css('max-height', maxHeight);
						$timeout(function () {
							section.css('max-height', 'none');
						}, 200);
					} else {
						scope.isCollapsed = true;
						section.addClass('section-hidden');
						section.css('max-height', maxHeight);
						$timeout(function () {
							section.css('max-height', 0);
						}, 1);
					}
				});
			});

		}
	};
}]);
