//This directive displays a table of documents and a add button for adding to specified document_x_object with correct column data.
(function () {

	var app = angular.module('Plania');

	app.directive('plEnum', function () {
		return {
			restrict: 'E',
			scope: {
				enumType: '=enumType',
				enumValue: '=ngModel'
			},
			controller: ['$scope', 'EnumService', 'TranslationService', controller],
			template: '<span>{{translatedEnumValue}}</span>'
		};
	});

	function controller($scope, enumService, translationService) {

		var enumNumberToString = function (enumValue) {
			if (!enumValue || !$scope.enumType) return;

			if (typeof enumValue === 'number') {
				enumService.EnumNumberToString($scope.enumType, enumValue).then(function (res) {
					$scope.translatedEnumValue = translationService.translate('web-enum-' + $scope.enumType + '-' + res, res);
				});
			} else {
				$scope.translatedEnumValue = translationService.translate('web-enum-' + $scope.enumType + '-' + enumValue, enumValue);
			}
		};

		if (!$scope.translatedEnumValue) {
			enumNumberToString($scope.enumValue);
		}

		$scope.$watch('enumValue', function (newValue, oldValue) {
			if (newValue === oldValue) return;
			enumNumberToString(newValue);
		});

	}

})();
