(function () {
	angular.module('Plania').controller('EntityPermissionController', ['$scope', '$stateParams', 'Repository', controller]);

	function controller($scope, $stateParams, repository) {
		$scope.model = {};
		$scope.update = $scope.navigation.current.name === 'entityPermission.edit';
		$scope.activateAutocomplete = false;

		if ($scope.update) {
			repository.getSingle(repository.apiData.entityPermissionProfile.url, $stateParams.guid).then(function (result) {
				$scope.permissionProfile = result.Data;
				$scope.fieldRules = result.FieldRules;
				$scope.reload = true;

				var filter = {
					PropertyFilter: [{
						Property: 'GuidEntityPermissionProfile',
						Operator: '=',
						Value: $scope.permissionProfile.Guid
					}]
				};

				var numberOfAwaitedReturns = 2;
				var returned = function () {
					numberOfAwaitedReturns--;
					if (!numberOfAwaitedReturns) {
						// now that both permissions and group/type relations are loaded, sort the records
						$scope.buildEntityTypeTree();
						setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
					}
				};

				repository.GetPaginated(repository.apiData.entityPermission.url, 0, 500, {}, filter).then(function (result) {
					$scope.permissions = result.List;
					// sort permissions by type (and parent types for DocumentXEntity) in a map
					$scope.mapOfPermissions = {};
					$scope.permissions.forEach(function (permission) {
						$scope.mapOfPermissions[permission.EntityType] = permission;
					});

					returned();
				});

				repository.GetPaginated("entityTypeInfo/", 0, 500, {}, filter).then(function (result) {
					$scope.entityTypeGroups = result;
					returned();
				});
			});
		} else {
			repository.getSingle(repository.apiData.registeredField.url, repository.commonService.prefix.EntityPermissionProfile).then(function (result) {
				$scope.fieldRules = result;
				$scope.reload = true;
			});
		}

		$scope.setRight = function (permission, property) {
			permission[property] = !permission[property];
			if (property === 'HasAllRights') {
				permission.HasOpenRights = permission[property];
				permission.HasApproveRights = permission[property];
				permission.HasDeactivateRights = permission[property];
				permission.HasDeleteRights = permission[property];
				permission.HasEditRights = permission[property];
				permission.HasInsertRights = permission[property];
				permission.HasLastRights = permission[property];
				permission.HasLinkRights = permission[property];
			}
		};

		$scope.hasRight = function (permission, property) {
			return permission[property];
		};

		//Run when clicking the table-header checkbox
		$scope.setPropertyOnAll = function (property) {
			var boolValue = $scope.allChecked(property);
			$scope.permissions.forEach(function (permission) {
				permission[property] = !boolValue;
			});
		};

		//Used for the "checked" attribute on the tableheader checkbox
		$scope.allChecked = function (property) {
			if (!$scope.permissions)
				return false;

			if (_.find($scope.permissions, function (o) { return !o[property]; }))
				return false;
			else
				return true;
		};

		$scope.save = function (destination) {
			repository.updateSingle(repository.apiData.entityPermissionProfile.url, $scope.permissionProfile).then(function (result) {
				repository.growl("Rettighetsprofilene har blitt oppdatert", "success");
			}, repository.showError);

			//repository.updateSingle(repository.apiData.entityPermission.url, $scope.model).then(function (result) {
			//    repository.growl("Rettighetsprofilene har blitt oppdatert", "success");
			//}, function (error) {
			//    if (typeof (error) === "string") {
			//        repository.growl(error, "danger");
			//    } else {
			//        repository.growl(error.Data.Message, "danger");
			//    }
			//});
		};

		$scope.cancel = function () {
			$scope.goBack('entityPermission.list', { menuGuid: $scope.navigation.params.menuGuid });
		};

		// groups
		$scope.enumEntityTypeGroup =
		{
			SystemIntern: 0,
			System: 1,
			Administration: 2,
			Core: 3,
			Activity: 4,
			Budget: 5,
			SparePart: 6,
			Document: 7,
			Energy: 8,
			Area: 9,
			Cleaning: 10,
			CleaningWeb: 11,
			Key: 12,
			Rent: 13,
			Invoice: 14,
			InternalRent: 15,
			Housing: 16,
			DutyLog: 17,
		};

		$scope.getModuleIcon = function (entityTypeGroupName) {
			return repository.moduleService.getModuleIcon(entityTypeGroupName);
		};

		/*!
		Builds entity type tree by dispatching entity permissions in their respective group, according to their indexes
		\return	.
		*/
		$scope.buildEntityTypeTree = function () {
			this.entityTypeGroups.forEach(function (entityTypeGroup) {
				entityTypeGroup.permissions = [];
				entityTypeGroup.Prefixes.forEach(function (entityType) {
					var permission = $scope.mapOfPermissions[entityType];
					if (permission)
						entityTypeGroup.permissions.push(permission);
				});
			});

			//var index = 0;
			//for (var i = 0; i < this.entityTypeGroups.length; i++) {
			//	var entityTypeGroup = this.entityTypeGroups[i];
			//	entityTypeGroup.permissions = [];
			//	for (var j = 0; j < entityTypeGroup.Prefixes.length; j++) {
			//		var permission = this.mapOfPermissions[entityTypeGroup.Prefixes[j]];
			//		if (permission)
			//			entityTypeGroup.permissions.push(permission);
			//	}
			//	// if current record belongs to the next group, update group index
			//	if (this.entityTypeGroups[index].group !== entityTypeInfo.Group) {
			//		for (index = 0; index < this.entityTypeGroups.length && this.entityTypeGroups[index].group !== entityTypeInfo.Group; index++)
			//			;// just loop (should be only one loop)
			//		if (index === this.entityTypeGroups.length) {
			//			index = 0;
			//			continue;// module not found -> skip this record
			//		}
			//	}

			//	var group = this.entityTypeGroups[index];
			//	// set the matching entity permission in the array (create it if needed) of entity permissions
			//	if (!group.entityTypes)
			//		group.entityTypes = [];
			//	var permission = this.mapOfPermissions[entityTypeInfo.Type];
			//	if (permission)
			//		group.entityTypes.push(permission);
		};
	}
})();
