(function () {
	angular.module('Plania').controller('AddMobileMenuModalController', ['$scope', '$modalInstance', 'params', controller]);

	function controller($scope, $modalInstance, params, repository, ngTableParams, $filter) {
		$scope.availableMenus = params.menus;

		$scope.selectMenu = function (menu) {
			$modalInstance.close(menu);
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}

})();
