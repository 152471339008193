(function () {
	angular.module('Plania').controller('EditDrawingModalController', ['$scope', 'Repository', '$modalInstance', '$localStorage', 'params', 'TranslationService', controller]);

	function controller($scope, repository, $modalInstance, $localStorage, params, translationService) {

		$scope.showUpload = $localStorage.generalOptions.CanSaveDrawingInDatabase;

        var columns = ['Building.Id', 'Building.Description'];
		var isNew = true;
		if (params.Guid) {
			isNew = false;
			repository.getSingle(repository.apiData.drawing.url, params.Guid, JSON.stringify(columns)).then(function(result) {
				$scope.model = result.Data;
			});
		} else {
			$scope.model = {};
		}
		$scope.files = [];
		$scope.onFileSelect = function ($files) {
			$scope.files = $scope.files.concat($files);
		};


		$scope.dragOverClass = function ($event) {
			var items = $event.dataTransfer.items;
			var hasFile = false;
			if (items) {
				for (var i = 0; i < items.length; i++) {
					if (items[i].kind === 'file') {
						hasFile = true;
						break;
					}
				}
			} else {
				hasFile = true;
			}
			return hasFile ? "drop-box-dragover" : "";
		};

		repository.getSingle(repository.apiData.webClientSettings.url, "").then(function (result) {
			$scope.maxFileLength = result.MaxRequestLength;
		});

		var getFilesToUpload = function (allFiles) {
			var files = _.reject(allFiles, function (file) {
				// If retrying upload, remove the files already uploaded
				return file.progressType === 'success';
			});

			files.forEach(function (file) {
				// Generate unique id for files used for upload progress update
				file.id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 16);
				file.count = 0;
				file.progressType = null;
			});
			return files;
		};


		$scope.filesizeToLarge = false;

		$scope.removeFile = function (index) {
			$scope.files.splice(index, 1);
		};

		var saveMetaData = function () {
			if (!isNew) {
				repository.updateSingleDictionary(repository.apiData.drawing.url, $scope.model).then(
					function(result) {
						repository.growl('Tegningen har blitt lagret', 'info');
						//todo burde vi lukke direkte etter lagring ? eller holde modalen åpen ?
					})
					.catch(function(error) {
						repository.growl(error, 'danger');

					});
			}
		};

		var savedFiles = [];
		$scope.saveDrawing = function (destination) {


			if ($scope.files.length < 1) {
				saveMetaData();
				return;
			}

			if ($scope.isUploading) return;

			var filesToUpload = getFilesToUpload($scope.files);

			$scope.isUploading = true;
			$scope.model.prefix = repository.apiData.drawing.prefix;
			if (!$scope.model.GuidDataOwner) {
				$scope.model.GuidDataOwner = repository.commonService.getFilterData().selectedDataOwner.Guid;
			}

			var updateFeedback = function(file) { //UPDATE
				var identifiedFile = _.find($scope.files, function(f) { return f.id === file.id; });
				if (identifiedFile) {
					identifiedFile.total = file.total;
					identifiedFile.count = file.count;
					identifiedFile.progressType = file.count >= file.total ? 'success' : 'info';
				}
			};

			var error = function(error) {
				repository.growl(error[0].error, 'danger');
				$scope.isUploading = false;
				error.forEach(function(error) {
					var identifiedFile = _.find($scope.files, function(f) { return f.id === error.id; });
					if (identifiedFile) {
						identifiedFile.progressType = 'danger';
					}
				});
			};

			var success = function(result) {
				repository.commonService.setLastRegisterGuid(destination, result);
				repository.growl('Tegningen har blitt lagret', 'info');
				$scope.alreadyUploaded = true;
				savedFiles = _.map(result.savedFiles, 'guid');
				$scope.saveFeedback = translationService.translate('web-drawing-edit-successFeedback-FileChanged',
					'Tegningen har blitt lagret, videre behandling i desktop er nødvendig for å kunne vise tegningen i web (opprett svg i desktop)');
				$scope.isUploading = false;

			};

			if (isNew) {
				repository.createSingleWithBinary(repository.apiData.document.urlChunk, $scope.model, filesToUpload).then(success, error, updateFeedback);
			} else {
				repository.updateSingleWithBinary(repository.apiData.document.urlChunk, $scope.model, filesToUpload[0]).then(success, error, updateFeedback);
			}
		};
		
		$scope.ok = function () {
			$modalInstance.close();
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}
})();
