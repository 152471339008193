angular.module('Plania').factory('StandardThemeService', ['$modal', 'Repository', '$q', 'DwgThemeService', function ($modal, repository, $q, dwgThemeService) {
    var service = {};

    //removed status on standard theme, should investigate what we want to check and display when this theme is activated


    service.getLegends = function () {
        return null;
    };

    service.onClick = function (guid) {
        var deferred = $q.defer();
        var tabs = {
            area: { visible: repository.authService.hasReadAccess(repository.apiData.area.prefix), active: true },
            checklist: { visible: repository.authService.hasReadAccess(repository.apiData.WorkOrderXArea.prefix), active: false },
        };

        $modal.open({
            templateUrl: 'app/dwg/views/dwgSelectionModal.html',
            size: 'lg',
            controller: 'DwgSelectionModalController',
            animation: false,
            resolve: {
                params: function () { return { guid: guid, tabInfo: tabs }; }
            }
        }).result.then(function (result) {
            deferred.resolve({});
        }, function (error) {
        	if (error === 'cancel') deferred.resolve({});
        	else deferred.reject();
        });
        return deferred.promise;
    };

	service.getStatus = function (areas) {
		dwgThemeService.removeAreaIndicators(areas);
		dwgThemeService.removePeriodicMarkers(areas);
		dwgThemeService.removeRequestMarkers(areas);
		dwgThemeService.removeEquipmentCategoryLabels(areas);

        var defer = $q.defer();
        defer.resolve();
        return defer.promise;
        //repository.createMultiple(repository.apiData.WorkOrderXAreaStatus.url, areas)
        //        .then(function (result) {
        //            updateIndicatorColours(result);
        //        }).catch(function (error) {
        //            repository.growl(error, 'danger');
        //        });
    };
    return service;
}]);
