(function () {
	angular.module('Plania').controller('CauseController', ['$scope', 'Repository', '$stateParams', 'TranslationService', 'ngTableParams', '$modal', 'ListService', controller]);

	function controller($scope, repository, $stateParams, translationService, ngTableParams, $modal, listService) {
		$scope.isUpdate = $scope.navigation.current.name === 'cause.edit';
		$scope.model = {};

		$scope.resourceColumns = [
			'ResourceGroup.Id', 'ResourceGroup.Description', 'ResourceGroup.Guid'
		];

		var getCardTables = function () {
			$scope.resourceTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: { 'ResourceGroup.Id': 'asc' },
				filter: { PropertyFilter: [{ Property: "GuidCause", Operator: "=", value: $scope.model.Guid }] }
			},
				{
					total: 0,
					counts: [10, 20, 50],
					filterDelay: 10,
					getData: function ($defer, params) {
						$scope.loadingResources = true;

						repository.GetPaginated(repository.apiData.resourceGroupCause.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify($scope.resourceColumns))
							.then(function (result) {
								$scope.loadingResources = false;
								$scope.resourceTable.settings().total = result.TotalCount;
								$scope.resourceTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							},
							function (error) {
								$scope.loadingResources = false;
								repository.growl(error, 'danger');
							});
					}
				});
		};

		$scope.addResource = function () {
			$modal.open({
				templateUrl: 'app/common/views/multipleSelectModal.html',
				controller: 'MultipleSelectModalController',
				resolve: {
					modalParams: function () {
						return {
							title: 'Velg ressurs',
							columns: [
								{ Title: translationService.translate('web-cause-resource-id'), Property: 'Id' },
								{ Title: translationService.translate('web-cause-resource-description'), Property: 'Description' },
							],
							useObjects: false,
							url: repository.apiData.resourceGroup.url,
							sorting: { Id: 'asc' },
							filter: {},
							alreadySavedGuids: function () {
								var selectedGuids = [];
								$scope.resourceTable.data.forEach(function (entity) {
									selectedGuids.push(entity.GuidResourceGroup);
								});
								return selectedGuids;
							}()
						};
					}
				}
			}).result.then(function (selectedEntities) {
				var resourceXCause = [];
				selectedEntities.forEach(function (entity) {
					resourceXCause.push({ GuidCause: $scope.model.Guid, GuidResourceGroup: entity });
				});
				repository.createMultipleWithGuid(repository.apiData.resourceGroupCause.url, $scope.model.Guid, JSON.stringify(resourceXCause)).then(function (result) {
					repository.growl('Ressurs(er) har blitt lagt til', 'success');
					$scope.resourceTable.reload();
				});
			});
		};

		$scope.getPropertyValue = function (row, column) {
			return listService.GetPropertyValue(row, column);
		};

		$scope.removeResource = function (resourceCause) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-cause-resourceGroup-message', "Ressurs vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-cause-resourceGroup-button-confirm', 'Ja, fjern ressurs'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.resourceGroupCause.url, resourceCause.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-cause-resourcegroup-success', 'Ressurs ble fjernet!'), result, "success");
						$scope.resourceTable.reload();
					});
			});
		};

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.Cause, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Cause, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.Cause);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Cause);
			}
		};

		if ($scope.isUpdate) {
			repository.getSingle(repository.apiData.cause.url, $stateParams.guid).then(
				function (response) {
					$scope.model = response.Data;
					updateAccess();
					getCardTables();
					$scope.reload = true;
				}, function (error) {
					repository.growl(error, 'danger');
				}
			);
		}

		$scope.update = function (destination) {
			var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('update-cause-success', 'Årsak har blitt oppdatert.') : translationService.translate('create-cause-success', 'Årsak har blitt opprettet.'), 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.isUpdate) {
				repository.updateSingleDictionary(repository.apiData.cause.url, $scope.model).then(success, error);
			} else {
				repository.createSingleDictionary(repository.apiData.cause.url, $scope.model).then(success, error);
			}
		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-cause-message', "Årsak vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-cause-button-confirm', 'Ja, fjern årsak'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.cause.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-cause-success', 'Årsak ble fjernet!'), result, "success");
						$scope.goBack('cause.list', { menuGuid: $scope.navigation.params.menuGuid });
					}, function (error) {

						var swalMessage = error.Message;
						if (error.IsDeactivateable) {
							swalMessage += '<br/><br/>Vil du deaktivere årsaken?';
						}
						swal({
							title: "Kunne ikke slette årsaken!",
							text: swalMessage,
							type: "error",
							html: true,
							showCancelButton: true,
							confirmButtonText: error.IsDeactivateable ? "Deaktiver" : "Ok",
							cancelButtonText: "Avbryt",
							closeOnConfirm: true,
							closeOnCancel: true
						}, function (isConfirm) {
							window.onkeydown = null;
							window.onfocus = null;
							if (isConfirm) {
								$scope.model.IsDeactivated = true;
								$scope.model.Id = ('#' + $scope.model.Id).substring(0, 20);
								$scope.update('cause.list');
							}
						});
					});
			});
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});
	}
})();
