(function () {
    angular.module('Plania').service('authInterceptorService', ['$q', '$injector', 'config', function ($q, $injector, apiConfig) {
        var authInterceptorServiceFactory = {};
        var $http;

        var request = function (config) {
            if (config.skipAuth) return config;
            if (config.url.indexOf('windowslogin') !== -1) return config;
			if (config.url.indexOf(apiConfig.baseUrl) === -1) return config;
            document.execCommand('ClearAuthenticationCache', 'false');
            var authService = $injector.get('authService');
            config.headers = config.headers || {};
            config.headers.Authorization = authService.getPlaniaToken().access;
            return config;
        };

        var retryHttpRequest = function (config, deferred) {
            $http = $http || $injector.get('$http');
            $http(config).then(function (response) {
                deferred.resolve(response);
            }, function (response) {
                deferred.reject(response);
            });
        };

        var responseError = function (rejection) {
            if (rejection.config.skipAuth) return $q.reject(rejection);

            var deferred = $q.defer();
            var authService = $injector.get('authService');
            var accessToken = authService.getPlaniaToken().access;
            if (rejection.config.headers.Authorization !== accessToken) {
                rejection.config.headers.Authorization = accessToken;
                retryHttpRequest(rejection.config, deferred);
            } else {
                deferred.reject(rejection);
            }
            return deferred.promise;
        };



        authInterceptorServiceFactory.request = request;
        authInterceptorServiceFactory.responseError = responseError;

        return authInterceptorServiceFactory;
    }]);



})
();
