(function() {
	angular.module('Plania').controller('PrintReportController', ['$scope', '$stateParams', '$localStorage',
	function controller($scope, $stateParams, $localStorage) {

		var reportParams = $stateParams.reportParams ? $stateParams.reportParams : $localStorage.reportParams;

		$scope.printReport = true;
		$scope.reportName = $stateParams.reportName;
		$scope.reportParams = JSON.parse(reportParams);
		delete $localStorage.reportParams;
	}]);
})();
