(function () {
	angular.module('Plania').controller('CleaningCompletionModalController', ['$scope', 'Repository', 'params', '$modalInstance', controller]);

	function controller($scope, repository, params, $modalInstance) {
		$scope.model = {
			Guid: params.guid
		};

		var columns = [
			"Area.Id",
			"Area.Description",
			"Area.GuidBuilding",
			"Area.GuidCleaningTeam",
			"Area.Building.Id",
			"Area.Building.Description",
			"Area.ResourceGroup.Id",
			"Area.ResourceGroup.Description",
			"CleaningTask.Id",
			"CleaningTask.Description",
			"CleaningTask.CleaningType",
			"CleaningTask.RecurrenceRule",
			"CleaningTask.Recurrence",
			"Person.FirstName",
			"Person.LastName",
			"User1.RealName"
			];

		repository.getSingle(repository.apiData.cleaningCompletion.url, $scope.model.Guid, JSON.stringify(columns)).then(
			function (result) {
				if (result.Data) {
					$scope.model = result.Data;
				}
			}, function (error) {
				repository.growl(error, 'danger');
			}
		);

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}
})();
