(function () {
	angular.module('Plania').controller('TranslationListController', ['$scope', 'Repository', 'ngTableParams', '$modal', controller]);

	function controller($scope, repository, ngTableParams, $modal) {
		//invalidate language cache
		$scope.invalidateLanguageCache = function () {
			repository.deleteSingle(repository.apiData.translate.url, "cache").then(function () {
				repository.growl('Cache har blitt invalidert', 'success');
			});
		};

		$scope.translationTable = new ngTableParams({
			page: 1,
			count: 20,
			filter: {}
		}, {
				total: 0,
				counts: [20, 50, 100],
				getData: function ($defer, params) {
					repository.GetPaginated(repository.apiData.translate.url, params.page() - 1, params.count(), params.sorting(), params.filter()).then(
						function (result) {
							params.total(result.TotalCount);
							$defer.resolve(result.List);

							if (result.List.length > 0)
								$scope.tableHeaders = _.map(result.List[0].LanguageXWebText, function (o) { return o.Language.Country; });
						},
						function (error) {
							repository.growl(error, 'danger');
						});
				}
			});

		$scope.openTranslationModal = function (webText) {
			$modal.open({
				templateUrl: 'app/translation/views/translationModal.html',
				controller: 'TranslationModalController',
				size: 'lg',
				resolve: {
					params: function () {
						return {
							webText: webText
						};
					}
				}
			}).result.then(function (result) {
				$scope.preSelected = webText;
				$scope.translationTable.reload();
			});
		};
	}
})();
