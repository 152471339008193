(function () {
	angular.module('Plania').controller('StandardTextController', ['$scope', 'Repository', '$stateParams', 'TranslationService', controller]);

	function controller($scope, repository, $stateParams, translationService) {
		$scope.isUpdate = $scope.navigation.current.name === 'standardText.edit';
		$scope.model = {};
        var updateAccess = function () {
            if ($scope.isUpdate) {
                var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
                $scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.StandardText, checkOtherDO);
                $scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.StandardText, checkOtherDO);
            } else {
                $scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.StandardText);
                $scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.StandardText);
            }
        };

		if ($scope.isUpdate) {
			repository.getSingle(repository.apiData.standardText.url, $stateParams.guid).then(
                function (response) {
                	$scope.model = response.Data;
                    updateAccess();
                }, function (error) {
                	repository.growl(error, 'danger');
                }
            );
		}

		$scope.update = function (destination) {
			var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('update-standardText-success', 'StandardText har blitt oppdatert.') : translationService.translate('create-standardText-success', 'StandardText har blitt opprettet.'), 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.isUpdate) {
			    repository.updateSingleDictionary(repository.apiData.standardText.url, $scope.model).then(success, error);
			} else {
			    repository.createSingleDictionary(repository.apiData.standardText.url, $scope.model).then(success, error);
			}

		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-costcenter-message', "StandardText vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-standardText-button-confirm', 'Ja, fjern StandardText'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.standardText.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-standardText-success', 'StandardText ble fjernet!'), result, "success");
						$scope.goBack('standardText.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
        };

        $scope.$on($scope.events.newSelection, function () {
            updateAccess();
        });
	}

})();
