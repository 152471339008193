angular.module('Plania').factory('RequestThemeService', ['$modal', 'Repository', '$q', 'DwgMarkerService', '$timeout', 'DwgThemeService', function ($modal, repository, $q, markerService, $timeout, dwgThemeService) {
	var service = {};

	var hasExtraCleaningTask = '#428bca';
	var hasInfectionCleaning = '#FFC107';
	var hasExitCleaning = '#00BCD4';
	var exitCleaningDischarged = '#000000';

	var updateIndicatorColours = function (statuses) {
		statuses.forEach(function (status) {
			var elements = $('#' + status.GuidArea).children();

			if (elements[0]) {
				var path = elements[0];

				$(path).attr('stroke-width', '0');
				$(path).attr('stroke-opacity', '0');
				$(path).attr('stroke', '');
				$(path).attr('stroke-dasharray', '0');
				$(path).attr('stroke-linecap', '');
				$(path).attr('fill', '');
				$(path).attr('fill-opacity', '0');

				if (status.HasAdditionalCleaning) {
					$(path).attr('stroke-width', '100');
					$(path).attr('stroke-opacity', '1');
					$(path).attr('stroke', hasExtraCleaningTask);
					$(path).attr('fill', hasExtraCleaningTask);
					$(path).attr('fill-opacity', '0.3');
				}

				if (status.ExitGuidAreaXCleaningTask) {
					$(path).attr('stroke-width', '100');
					$(path).attr('stroke-opacity', '1');
					$(path).attr('stroke', hasExitCleaning);
					$(path).attr('fill', hasExitCleaning);

					if (status.ExitCleaningRequestStatus === 'Discharged') {
						$(path).attr('fill', '#008EA1');
						$(path).attr('fill-opacity', '0.8');
						$(path).attr('stroke', exitCleaningDischarged);
					}
					else if (status.ExitCleaningRequestStatus === 'Confirmed') {
						$(path).attr('fill-opacity', '0.5');
					}
					else {
						$(path).attr('fill-opacity', '0.15');
					}
					$(path).data('exitGuidAreaXCleaningTask', status.ExitGuidAreaXCleaningTask);
				}
				else {
					$(path).removeData('exitGuidAreaXCleaningTask');
				}

				if (status.HasInfectionCleaning) {
					$(path).attr('stroke-width', '100');
					$(path).attr('stroke-opacity', '1');
					$(path).attr('stroke', hasInfectionCleaning);
					$(path).attr('fill', hasInfectionCleaning);
					$(path).attr('fill-opacity', '0.4');
				}
			}

		});
	};

	service.hideMarkers = function () {
		$('#marker-coordinates').attr('visibility', 'hidden');
	};

	service.showMarkers = function () {
		$('#marker-coordinates').attr('visibility', 'visible');
	};

	var updateMarkers = function (areaGuids) {
		areaGuids.forEach(function (guid) {
			var elements = $('#' + guid).children();

			if (elements[0]) {
				var path = elements[0];
				markerService.addRequestMarker(path, guid);
			}
		});
	};

	service.getLegends = function () {
        var legends = [
			{ label: 'Aktive tilleggsoppgaver', legendClass: 'legend-primary' },
			{ label: 'Smittevask', legendClass: 'legend-warning' },
			{ label: 'Klart utreisevask', legendClass: 'legend-exitCleaning-discharged' },
			{ label: 'Bekreftet utreisevask', legendClass: 'legend-info' },
			{ label: 'Mulig utreisevask', legendClass: 'legend-info-2' },
			{ label: 'Aktive meldinger', imageSrc: 'images/svg-marker.png' },
        ];
        return legends;
	};

	service.onClick = function (guid, type, data) {
		var deferred = $q.defer();

		if (type === 'marker') {
			$modal.open({
				templateUrl: 'app/request/views/requestModal.html',
				controller: 'RequestModalController',
				animation: false,
				resolve: {
					params: function () { return { guidArea: guid }; }
				}
			}).result.then(function (result) {
				deferred.resolve();
			});
		} else {
			var tabs = {
				cleaningInfo: { visible: repository.authService.hasReadAccess(repository.apiData.areaXCleaningTask.prefix), active: false },
				technicalRequest: { visible: repository.authService.hasCreateAccess(repository.apiData.request.prefix), active: false },
				cleaningRequest: { visible: repository.authService.hasCreateAccess(repository.apiData.areaXCleaningTask.prefix), active: true },
			};

			var openDwgSelectionModal = function () {
				$modal.open({
					templateUrl: 'app/dwg/views/dwgSelectionModal.html',
					size: 'lg',
					controller: 'DwgSelectionModalController',
					animation: false,
					resolve: {
						params: function () { return { guid: guid, tabInfo: tabs, data: data }; }
					}
				}).result.then(function (result) {
					deferred.resolve({ update: true });
				}, function (error) {
					if (error === 'cancel') deferred.resolve({});
					else deferred.reject();
				});
			};

			if (data.exitGuidAreaXCleaningTask) {
				$modal.open({
					templateUrl: 'app/cleaning/views/editExitCleaning.html',
					controller: 'ExitCleaningController',
					animation: false,
					resolve: {
						params: function () { return { guidAreaXCleaningTask: data.exitGuidAreaXCleaningTask }; }
					}
				}).result.then(function (result) {
					deferred.resolve({ update: true });
				}, function (error) {
					if (error === 'cancel') deferred.resolve({});
					else if (error === 'openDwgSelection') $timeout(function () { openDwgSelectionModal(); }, 50);
					else deferred.reject();
				});
			}
			else
				openDwgSelectionModal();
		}
		return deferred.promise;
	};

	service.getStatus = function (areas, guidDrawing) {
		dwgThemeService.removeEquipmentCategoryLabels(areas);

		var requestFilter = {
			PropertyFilter: [
				{ Property: 'Status', Operator: '=', Value: '0' },
				{ Operand: 'OR', Property: 'Status', Operator: '=', Value: '1' },
				{ Operand: 'AND', Property: 'WorkOrder.EndDate', Operator: '=', Value: '' },
				{ Operand: 'AND', Property: 'Area.GuidDrawing', Operator: '=', Value: guidDrawing }
			]
		};

		repository.GetPaginated(repository.apiData.request.url, 0, 1000, {}, requestFilter, '', JSON.stringify(['Guid'])).then(function (result) {
			dwgThemeService.removePeriodicMarkers(areas);
			dwgThemeService.removeRequestMarkers(areas);

			var areaGuids = [];
			if (!result.List) return;
			result.List.forEach(function (request) {
				if (!_.contains(areaGuids, request.GuidArea))
					areaGuids.push(request.GuidArea);
			});
			updateMarkers(areaGuids);
		}, function (error) {
			repository.growl(error, 'danger');
		});

		return repository.createMultiple(repository.apiData.cleaningStatus.url + 'cleaningStatus', areas).then(function (result) {
			dwgThemeService.removeAreaIndicators(areas);
			updateIndicatorColours(result);
			return result;
            });
	};

	service.handleMultipleAreas = function (areas) {
		var deferred = $q.defer();
		$modal.open({
			templateUrl: 'app/cleaning/views/multipleDwgRequests.html',
			size: 'lg',
			controller: 'MultipleDwgRequestController',
			animation: false,
			resolve: {
				params: function () { return { guids: areas }; }
			}
		}).result.then(function (result) {
			deferred.resolve({ update: true });
		}, function (error) {
			if (error === 'cancel') deferred.resolve({});
			else deferred.reject();
		});

		return deferred.promise;
	};

	service.editMultipleAreas = function(areas) {
		var deferred = $q.defer();
		$modal.open({
			templateUrl: 'app/cleaning/views/editMultipleDwgRequests.html',
			size: 'lg',
			controller: 'EditMultipleDwgRequestController',
			animation: false,
			resolve: {
				params: function () { return { guids: areas }; }
			}
		}).result.then(function (result) {
			deferred.resolve({ update: true });
		}, function (error) {
			if (error === 'cancel') deferred.resolve({});
			else deferred.reject();
		});

		return deferred.promise;
	};

	return service;
}]);
