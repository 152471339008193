(function () {
	angular.module('Plania').controller('TranslationModalController', ['$scope', 'Repository', 'params', '$modalInstance', 'TranslationService', '$q', '$modal', controller]);

	function controller($scope, repository, params, $modalInstance, translationService, $q, $modal) {
		var helpTextWasUpdated = false;

		if (!params.webText && params.systemName) {
			repository.getSingle(repository.apiData.translate.url, params.systemName).then(
				function (result) {
					$scope.model = angular.copy(result);
					$scope.original = angular.copy($scope.model.LanguageXWebText);
				}, repository.showError);
		} else {
			$scope.model = angular.copy(params.webText);
			$scope.original = angular.copy($scope.model.LanguageXWebText);
		}

		var updateTranslation = function (translation) {
			var deferred = $q.defer();

			repository.updateSingleDictionary(repository.apiData.translate.url, translation).then(function (result) {
				deferred.resolve(result);
			}, function (error) {
				deferred.reject(error);
			});

			return deferred.promise;
		};

		$scope.editHelpText = function (translation) {
			var originalTranslation = _.find($scope.original, { "Guid": translation.Guid });
			$modal.open({
				templateUrl: 'app/translation/views/editHelpTextModal.html',
				controller: 'EditHelpTextModalController',
				size: 'lg',
				resolve: {
					params: function () {
						return {
							systemName: params.systemName,
							languageXWebText: originalTranslation
						};
					}
				}
			}).result.then(function (result) {
				helpTextWasUpdated = true;
				var idx = _.findIndex($scope.model.LanguageXWebText, { "Guid": result.Guid });
				if (idx !== -1) {
					setHelpTextFromResult($scope.model.LanguageXWebText[idx], result);
					setHelpTextFromResult($scope.original[idx], result);
				}
			});
		};

		function setHelpTextFromResult(model, result) {
			model.HasHelpText = result.HasHelpText;
			model.DefaultTranslatedHelpText = result.DefaultTranslatedHelpText;
			model.TranslatedHelpText = result.TranslatedHelpText;
		}

		$scope.save = function () {
			var promises = [];
			for (var i = 0; i < $scope.model.LanguageXWebText.length; i++) {
				if ($scope.model.LanguageXWebText[i].TranslatedValue !== $scope.original[i].TranslatedValue) {
					promises.push(updateTranslation($scope.model.LanguageXWebText[i]));
				}
			}

			if (promises.length > 0) {
				$q.all(promises).then(function () {
					repository.growl("Oversettelse oppdatert", 'success');
					params.webText = $scope.model;
					$modalInstance.close({ model: $scope.model, translationUpdated: true });
				}).catch(function (error) {
					repository.growl(error, 'danger');
				});
			} else {
				if (!helpTextWasUpdated)
					$modalInstance.close('nothing to update');
				else
					$modalInstance.close({ model: $scope.model, helpTextUpdated: helpTextWasUpdated });
			}
		};

		$scope.cancel = function () {
			if (!helpTextWasUpdated)
				$modalInstance.dismiss('canceled');
			else
				$modalInstance.close({ model: $scope.model, helpTextUpdated: helpTextWasUpdated });
		};
	}
})();
