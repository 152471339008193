angular.module('Plania').directive('plEqualHeightColumns', [function () {
	return {
	    restrict: 'A',
	    scope:{
	    },
	    link: function link(scope, element) {
	        var columns = element.find('>[class*="col-"]');

	        var maxHeight = 0;

	        //for (var col in columns) {
	        //    var height = $(columns[col]).height;
	        //    if (height > maxHeight)
	        //        maxHeight = height;
	        //}

	        //for (var col in columns) {
	        //    $(columns[col]).height(maxHeight);
	        //}

	        console.log(columns);
	    }
	};
}]);
