(function () {

    angular.module('Plania').controller('ForgotPasswordController', ['$scope', '$state', 'Repository', 'authService', 'TranslationService', controller]);


    function controller($scope, $state, repository, authService, translationService) {

		$scope.isWaitingForService = false;

		$scope.resetPassword = function () {
            $scope.isWaitingForService = true;

            if (!$scope.email) {
                repository.growl(translationService.translate('web-login-noEmail-message', 'Skriv inn email.') , 'danger');
                $scope.isWaitingForService = false;
                return;
            }

		    authService.forgotPassword($scope.email).then(
				function (message) {
				    repository.growl(message, 'success');
				    $state.transitionTo('login');
				}, function (message) {
				    repository.growl(message, 'danger');
				});
		};
	}
})();
