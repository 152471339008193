(function () {
	angular.module('Plania').controller('AccountingModalController', ['$scope', 'Repository', 'modalParams', '$modalInstance', 'TranslationService', '$modal', 'ngTableParams', 'ListService', controller]);
	function controller($scope, repository, modalParams, modalInstance, translationService, $modal, ngTableParams, listService) {
		if (modalParams.DimensionNumber >= 0) {
			$scope.model = {
				DimensionNumber: modalParams.DimensionNumber
			};
		}
		else if (modalParams.dim) {
			$scope.model = {
				Guid: modalParams.dim.Guid,
				Id: modalParams.dim.Id,
				Description: modalParams.dim.Description,
				DimensionNumber: modalParams.dim.DimensionNumber
			};
		}
		$scope.isLastDimension = modalParams.isLastDimension;
		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.accountingXAccountingDimensionColumns = [];
		$scope.accountingXAccountingDimensionColumns.AddColumn = function (translationId, defaultText, property, propertyType) {
			this.push({ Position: this.length, Title: translationService.translate(translationId, defaultText), Property: property, PropertyType: propertyType });
			return this;
		};

		$scope.accountingXAccountingDimensionColumns
			.AddColumn('web-accounting-id', "Id", 'Accounting1.Id', 'string')
			.AddColumn('web-accounting-description', "Beskrivelse", 'Accounting1.Description', 'string');

		var getCardTables = function () {
			if (!modalParams.isLastDimension) {
				$scope.accountingXAccountingDimensionTable = new ngTableParams({
					page: 1,
					count: 10,
					sorting: { /* 'Id': 'asc' */ },
					filter: { GuidParentAccounting: $scope.model.Guid }
				},
					{
						total: 0,
						counts: [10, 20, 50],
						filterDelay: 10,
						getData: function ($defer, params) {
							var columns = [];
							$scope.accountingXAccountingDimensionColumns.forEach(function (col) {
								columns.push(col.Property);
							});

							repository.GetPaginated(repository.apiData.accountingXAccounting.url,
								params.page() - 1,
								params.count(),
								params.sorting(),
								params.filter(),
								"",
								JSON.stringify(columns))
								.then(function (result) {
									$scope.loadingAccounting = false;
									$scope.accountingXAccountingDimensionTable.settings().total = result.TotalCount;
									$scope.accountingXAccountingDimensionTable.settings().filterDelay = 500;
									$defer.resolve(result.List);
								},
								function (error) {
									$scope.loadingAccounting = false;
									repository.growl(error, 'danger');
								});
						}
					});
			}
		};

		getCardTables();

		$scope.addAccounting = function () {
			$modal.open({
				templateUrl: 'app/common/views/multipleSelectModal.html',
				controller: 'MultipleSelectModalController',
				resolve: {
					modalParams: function () {
						return {
							title: 'Velg dimensjoner',
							columns: [
								{ Title: translationService.translate('web-accountingDimension-id'), Property: 'Id' },
							],
							useObjects: true,
							url: repository.apiData.accounting.url,
							sorting: { Id: 'asc' },
							filter: { PropertyFilter: [{ Property: 'DimensionNumber', Operator: '=', Value: ($scope.model.DimensionNumber + 1) }] },
							alreadySavedGuids: function () {
								var selectedGuids = [];
								if ($scope.accountingXAccountingDimensionTable) {
									$scope.accountingXAccountingDimensionTable.data.forEach(function (entity) {
										selectedGuids.push(entity.GuidAccounting);
									});
								}
								return selectedGuids;
							}()
						};
					}
				}
			}).result.then(function (selectedEntities) {
				var arrayOfAccountingXAccountingDimensions = [];
				selectedEntities.forEach(function (entity) {
					arrayOfAccountingXAccountingDimensions.push({ GuidParentAccounting: $scope.model.Guid, GuidChildAccounting: entity.Guid });
				});
				repository.createMultipleWithGuid(repository.apiData.accountingXAccounting.url, $scope.model.Guid, JSON.stringify(arrayOfAccountingXAccountingDimensions)).then(function (result) {
					repository.growl('Dimensjon(er) har blitt lagt til på dimensjon', 'success');
					$scope.accountingXAccountingDimensionTable.reload();
				});
			});
		};

		$scope.removeAccounting = function (accounting) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-accountingXAccounting-message', 'Dimensjonen vil bli permanent fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-supplier-accountingXAccounting-button-confirm', 'Ja, fjern dimensjonen!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.accountingXAccounting.url, accounting.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-supplier-accountingXAccounting-success', 'Dimensjonen ble fjernet!'), result, "success");
						$scope.accountingXAccountingDimensionTable.reload();
					});
			});
		};


		$scope.isUpdate = modalParams.dim ? true : false;

		$scope.saveAccounting = function () {
			var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('update-accounting-success', 'Dimensjon har blitt oppdatert.') : translationService.translate('create-accounting-success', 'Dimensjon har blitt opprettet.'), 'success');
				modalInstance.close(result);
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.isUpdate) {
				repository.updateSingleDictionary(repository.apiData.accounting.url, $scope.model).then(success, error);
			} else {
				repository.createSingleDictionary(repository.apiData.accounting.url, $scope.model).then(success, error);
			}

		};

		$scope.cancel = function () {
			modalInstance.dismiss("Avbryt");
		};
	}

})();
