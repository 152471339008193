(function () {
	angular.module('Plania').controller('DwgSelectionModalController', ['$scope', 'Repository', 'params', '$stateParams', '$modalInstance', 'TranslationService', controller]);

	function controller($scope, repository, params, $stateParams, modalInstance, translationService) {
		$scope.model = {};
		$scope.showChecklistList = true;

		$stateParams.guid = params.guid;
		$stateParams.isModal = true;

		$scope.guidArea = params.guid;
		$scope.task = params.task;
		$scope.tab = params.tabInfo;
		$scope.extraData = params.data;

		$scope.select = function (tabName) {
			for (var key in $scope.tab) {
				if ($scope.tab.hasOwnProperty(key))
					$scope.tab[key].active = false;
			}
			$scope.tab[tabName].active = true;
		};

		repository.getSingle(repository.apiData.area.url, $scope.guidArea).then(function (result) {
			$scope.model = result.Data;
		});

		repository.getSingle(repository.apiData.person.url, $scope.userInfo.guidPerson, JSON.stringify(["ResourceGroup.Id"])).then(function (result) {
			$scope.person = result.Data;
		});

		if ($scope.tab.controlList) {
			$scope.controlListParams = {
				guidArea: $scope.guidArea
			};
			var filter = {
				PropertyFilter: [
					{ Property: 'WorkOrder.StartDate', Operator: '<=', Value: moment().endOf('day').toISOString() },
					{ Operand: 'OR', Property: 'WorkOrder.StartDate', Operator: '=', Value:''},
					{ Property: 'GuidArea', Operator: '=', Value: $scope.guidArea },
					{ Property: 'GuidWorkOrder', Operator: '<>', Value: null },
					{ Property: 'WorkOrder.EndDate', Operator: '=', Value: '' }]
			};

			repository.GetPaginated(repository.apiData.controlListXEntity.url, 0, 10, {}, filter).then(function (result) {
				$scope.controlLists = result.List;
			}, function (error) {
				repository.growl(error, 'danger');
			});
		}

		$scope.checklistCompleted = function (completionParams) {
			$scope.ok(completionParams);
		};

		$scope.ok = function (result) {
			modalInstance.close(result);
		};

		$scope.cancel = function () {
			modalInstance.dismiss('cancel');
		};

		$scope.toggleParentReload = function () {
			$scope.parentReload = !$scope.parentReload;
		};
	}
})();
