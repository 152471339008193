(function () {
	var app = angular.module('Plania');
	app.directive('plEntityLinks', function () {
		return {
			restrict: 'E',
			scope: {
				guid: '=guid', // guid of the entity
				entity: '=entity', // Prefix
				displayLimit: '=displayLimit', // Number - Limits the amount of visible links. Will show a "Show more" button if totalCount is over this.
			},
			controller: ['$scope', 'Repository', controller],
			templateUrl: 'app/entityLink/views/plEntityLinksDirective.html'
		};
	});

	function controller(scope, repository) {
		scope.collapse = { isEntitiesCollapsed: false };

		var entityLinkFilter = {
			GuidEntity: scope.guid,
			PropertyFilter: [
				{
					Property: 'EntityType',
					Operator: '=',
					Value: scope.entity
				}, {
					Property: 'Type',
					Operator: '<=',
					Value: '0'
				}
			]
		};

		var columns = ['Name', 'Url', 'Description', 'Type', 'IndexPosition', 'EntityType', 'UseUrlEncoding', 'ActionRule', 'HttpHeaders', 'Filter'];

		repository.GetPaginated(repository.apiData.entityLink.url, 0, 10000, { 'IndexPosition': 'asc' }, entityLinkFilter, null, JSON.stringify(columns)).then(function (result) {
			scope.totalCount = result.TotalCount;
			scope.entityLinks = result.List.filter(function (link) { return link.Visible; });
		}, function (error) {
			repository.growl(error, 'danger');
		});
	}
})();
