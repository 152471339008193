(function () {
	angular.module('Plania').controller('PriorityController', ['$scope', 'Repository', '$stateParams', 'TranslationService', 'ngTableParams', '$modal', 'ListService', controller]);

	function controller($scope, repository, $stateParams, translationService, ngTableParams, $modal, listService) {
		$scope.isUpdate = $scope.navigation.current.name === 'priority.edit';
		$scope.model = { DeadlinePeriodUnit: 'Undefined', EntityType: "Undefined" };

		$scope.entityTypes = [
            { key: repository.apiData.workOrder.prefix, value: translationService.translate('web-enum-prefix-workorder')},
            { key: repository.apiData.condition.prefix, value: translationService.translate('web-enum-prefix-condition')}
		];

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.Priority, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Priority, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.Priority);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Priority);
			}
		};

		if ($scope.isUpdate) {
			repository.getSingle(repository.apiData.priority.url, $stateParams.guid).then(
				function (response) {
					$scope.model = response.Data;
					updateAccess();
				}, function (error) {
					repository.growl(error, 'danger');
				}
			);
		}

		$scope.update = function (destination) {
			var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('update-priority-success', 'Prioritet har blitt oppdatert.') : translationService.translate('create-priority-success', 'Prioritet har blitt opprettet.'), 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack('priority.' + destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.isUpdate) {
				repository.updateSingleDictionary(repository.apiData.priority.url, $scope.model).then(success, error);
			} else {
				repository.createSingleDictionary(repository.apiData.priority.url, $scope.model).then(success, error);
			}

		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-priority-message', "Prioriteten vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-priority-button-confirm', 'Ja, fjern Prioriteten'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.priority.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-priority-success', 'Prioriteten ble fjernet!'), result, "success");
						$scope.goBack('priority.list', { menuGuid: $scope.navigation.params.menuGuid });
					}, function (error) {

						var swalMessage = error.Message;
						if (error.IsDeactivateable) {
							swalMessage += '<br/><br/>Vil du deaktivere prioriteten?';
						}
						swal({
							title: "Kunne ikke slette prioriteten!",
							text: swalMessage,
							type: "error",
							html: true,
							showCancelButton: true,
							confirmButtonText: error.IsDeactivateable ? "Deaktiver" : "Ok",
							cancelButtonText: "Avbryt",
							closeOnConfirm: true,
							closeOnCancel: true
						}, function (isConfirm) {
							window.onkeydown = null;
							window.onfocus = null;
							if (isConfirm) {
								$scope.model.IsDeactivated = true;
                                $scope.model.Description = ('#' + $scope.model.Description).substring(0, 20);
								$scope.update('priority.list');
							}
						});
					});
			});
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});
	}
})();
