(function () {
    angular.module('Plania').controller('AreaXEntityModalController', ['$scope', '$modalInstance', 'params', 'Repository', controller]);

    function controller($scope, $modalInstance, params, repository) {
        $scope.isUpdate = params.guidAreaXEntity ? true : false;

        $scope.areaXEntity = { GuidArea: params.guidArea };

        var setProperties = function () {
            var areaXEntity = $scope.areaXEntity;
            if (areaXEntity.Area1) {
                $scope.area = areaXEntity.Area1.Id + ' - ' + areaXEntity.Area1.Description;
            }

            if (areaXEntity.Service) {
                $scope.service = areaXEntity.Service.Id + ' - ' + areaXEntity.Service.Description;
            }

            if (areaXEntity.Article) {
                $scope.article = areaXEntity.Article.Id + ' - ' + areaXEntity.Article.Description;
            }
        };

        if ($scope.isUpdate) {
            var columns = ['Area1.Id', 'Area1.Description', 'Article.Id', 'Article.Description', 'Service.Id', 'Service.Description'];
            repository.getSingle(repository.apiData.areaXEntity.url, params.guidAreaXEntity, JSON.stringify(columns)).then(function (result) {
                $scope.areaXEntity = result.Data;
                setProperties();
                $scope.reload = true;
                setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
            });
        } else {
			$scope.areaXEntity.Quantity = 1;
	        $scope.activateAutocomplete = true;
        }

        $scope.$watch('areaXEntity.EntityType', function (newValue, oldValue) {
            if (newValue === oldValue) return;

            var entityType = typeof ($scope.areaXEntity.EntityType) === 'string' ? parseInt($scope.areaXEntity.EntityType) : $scope.areaXEntity.EntityType;

            if (entityType === 13) {
                delete $scope.areaXEntity.GuidService;
                delete $scope.areaXEntity.GuidArticle;
                delete $scope.service;
                delete $scope.article;
            } else if (entityType === 14) {
                delete $scope.areaXEntity.GuidRoom;
                delete $scope.area;
            }
        });

        $scope.onAreaSelect = function (area) {
            $scope.areaXEntity.GuidRoom = area.Guid;
            $scope.areaXEntity.Id = area.Id;
            $scope.areaXEntity.Description = area.Description;
            $scope.areaXEntity.RentalPricePerMonth = area.RentalPricePerMonth;
            $scope.area = area.Id + ' - ' + area.Description;
        };

        $scope.onArticleSelect = function (article) {
            $scope.areaXEntity.GuidArticle = article.Guid;
            $scope.areaXEntity.Id = article.Id;
            $scope.areaXEntity.Description = article.Description;
            $scope.areaXEntity.RentalPricePerMonth = 0;

            if (article.Service) {
                $scope.areaXEntity.GuidService = article.Service.Guid;
                $scope.service = article.Service.Id + ' - ' + article.Service.Description;
            }
        };

        $scope.save = function () {
            var success = function (result) {
                $modalInstance.close();
            };

            var error = function (error) {
                repository.growl(error, 'danger');
            };

            if ($scope.isUpdate) {
                repository.updateSingleDictionary(repository.apiData.areaXEntity.url, $scope.areaXEntity).then(success, error);
            } else {
                repository.createSingleDictionary(repository.apiData.areaXEntity.url, $scope.areaXEntity).then(success, error);
            }
        };

        $scope.cancel = function () {
            $modalInstance.dismiss('canceled');
        };
    }
})();
