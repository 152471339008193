(function () {
    angular.module('Plania').controller('CreateWebProfileUserController', ['$scope', '$modalInstance', 'params', 'Repository', 'ngTableParams', controller]);

    function controller($scope, $modalInstance, params, repository, ngTableParams) {

        var filter = {};
        if (params.excludeGuids && params.excludeGuids.length > 0) {
            filter.PropertyFilter = [{ Property: 'Guid', Operator: 'notIn', Value: params.excludeGuids.join(",") }, { Property: 'GuidUserGroup', operator: '=', value: "" }];
        } else {
            filter.PropertyFilter = [{ Property: 'GuidUserGroup', operator: '=', value: "" }];
        }



		$scope.userTable = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { 'RealName': 'asc' },
            filter:filter
			//filter: { PropertyFilter: [{Property: 'GuidUserGroup', operator: '=', value: ""}] }
		},
		{
		    total: 0,
            paginationMaxBlocks:5,
			counts: [10, 25, 50],
			getData: function ($defer, params) {
				repository.GetPaginated(repository.apiData.user.url, params.page() - 1, params.count(), params.sorting(), params.filter()).then(
					function (result) {
						params.total(result.TotalCount);
						$defer.resolve(result.List);
					},
					function (error) {
					    repository.growl(error, 'danger');
					});
			}
		});

		var selectedUsers = [];

		$scope.saveUser = function () {
			repository.createMultipleWithGuid(repository.apiData.userXWebProfile.url, params.guidWebProfile, selectedUsers).then(function (result) {
		        repository.growl('Brukeren har blitt lagt til profilen', 'success');
		        $modalInstance.close('OK');
		    });
		};

		$scope.addUser = function (guid) {
		    if (!_.contains(selectedUsers, guid))
		        selectedUsers.push(guid);
		    else {
		        for (var i = 0; i < selectedUsers.length; i++) {
		            var p = selectedUsers[i];
		            if (p === guid) {
		                selectedUsers.splice(i, 1);
		                return;
		            }
		        }
		    }
		};

		$scope.isUserSelected = function (guid) {
		    return _.contains(selectedUsers, guid);
		};

		$scope.cancel = function () {
		    $modalInstance.dismiss('canceled');
		};
	}

})();
