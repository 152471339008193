(function () {
	angular.module('Plania').directive('plMaxLength', [
		'$animate', '$localStorage', 'RegisteredFieldService', function ($animate, $localStorage, registeredFieldService) {
			return {
				restrict: 'A',
				scope: {
					plMaxLength: '=',
					ignoreViewMode: '='
				},
				link: function (scope, element, attrs) {

					var applyRule = function (fieldRule) {
						if (fieldRule.MaxLength && fieldRule.MaxLength > 0) {
							var inputLengthElement = element.find('input');
							if (inputLengthElement && inputLengthElement.length > 0)
								inputLengthElement.attr('maxlength', fieldRule.MaxLength);

							inputLengthElement = element.find('textarea');
							if (inputLengthElement && inputLengthElement.length > 0)
								inputLengthElement.attr('maxlength', fieldRule.MaxLength);
						}
					};

					var checkFieldrule = function () {
						if (!scope.plMaxLength) return;

						registeredFieldService.getFieldRuleByEntityAndColumn(scope.plMaxLength).then(function (plMaxLength) {
							applyRule(scope.onGetValidityRules ? scope.onGetValidityRules(plMaxLength) : plMaxLength);
						}, function (error) {
							console.log(error);
						});
					};

					checkFieldrule();
				},
			};
		}
	]);
})();
