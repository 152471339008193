(function () {
	angular.module('Plania').controller('LogController', ['$scope', 'Repository', '$stateParams', 'ngTableParams', '$modal', 'TranslationService', controller]);

	function controller($scope, repository, $stateParams, ngTableParams, $modal, translationService) {
		$scope.update = $scope.navigation.current.name === 'log.edit';
		$scope.model = {};

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.Log, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Log, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.Log);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Log);
			}
		};

		updateAccess();

		if ($scope.update) {
			repository.getSingle(repository.apiData.log.url, $stateParams.guid).then(
                function (response) {
                	var log = response.Data;
                	$scope.model = log;
                	$scope.fieldRules = response.FieldRules;
                	$scope.reload = true;
                	updateAccess();
                },
                function (error) {
                	repository.growl(error, 'danger');
                });
		} else {
			repository.getSingle(repository.apiData.registeredField.url, repository.commonService.prefix.Log).then(function (result) {
				$scope.fieldRules = result;
				$scope.reload = true;
			});
		}

		$scope.onPostCodeSelect = function (postalInfo) {
			if (!postalInfo) {
				$scope.model.PostalCode = '';
				$scope.model.PostalArea = '';
			} else {
				$scope.model.PostalCode = postalInfo.ZipCode;
				$scope.model.PostalArea = postalInfo.PostalArea;
				$scope.postalInfo = postalInfo;
			}
		};
	}
})();
