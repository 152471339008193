(function () {
	angular.module('Plania').controller('MultipleSelectModalController', ['$scope', '$modalInstance', 'modalParams', 'Repository', 'ngTableParams', 'TranslationService', 'ListService', controller]);

	function controller($scope, $modalInstance, modalParams, repository, ngTableParams, translationService, listService) {
		$scope.allRowsSelected = false;
		$scope.modalParams = modalParams;

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
        };

        $scope.visibleColumns = _.filter($scope.modalParams.columns, function (c) { return !c.Hidden; });
		if (!modalParams.filter)
			modalParams.filter = {};

		$scope.itemTable = new ngTableParams({
			page: 1,
			count: 10,
			sorting: modalParams.sorting,
			filter: modalParams.filter
		},
			{
				total: 0,
				counts: [10, 25, 50],
				paginationMaxBlocks: 5,
				getData: function ($defer, params) {
					var filter = angular.copy(params.filter());
					_.remove(filter.PropertyFilter,
						function (prop) {
							return prop.isDisabled;
						});

					var columns = null;
					if (modalParams.columns) {
						columns = _.map(modalParams.columns, 'Property');
					}
					repository.GetPaginated(modalParams.url, params.page() - 1, params.count(), params.sorting(), filter, null, JSON.stringify(columns)).then(
						function (result) {
							params.total(result.TotalCount);

							$defer.resolve(result.List);
						},
						function (error) {
							repository.growl(error, 'danger');
						});
				}
			});

		$scope.selectedItems = [];

		$scope.save = function () {
			if (modalParams.useObjects) {
				$modalInstance.close($scope.selectedItems);
			} else {
				$modalInstance.close(_.map($scope.selectedItems, 'Guid'));
			}
		};

		$scope.addSelectedItem = function (object) {
			if (!$scope.isItemSelected(object)) {
				if (!$scope.alreadySaved(object.Guid))
					$scope.selectedItems.push(object);
			} else {
				for (var i = 0; i < $scope.selectedItems.length; i++) {
					if ($scope.selectedItems[i].Guid === object.Guid) {
						$scope.selectedItems.splice(i, 1);
						return;
					}
				}
			}
		};

		$scope.isItemSelected = function (object) {
			return _.find($scope.selectedItems, function (o) { return o.Guid === object.Guid; });
		};

		$scope.alreadySaved = function (guid) {
			return _.includes(modalParams.alreadySavedGuids, guid);
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}

})();
