(function () {
	angular.module('Plania').directive('plFieldrule', [
		'$animate', '$localStorage', 'RegisteredFieldService', function ($animate, $localStorage, registeredFieldService) {
			return {
				restrict: 'A',
				scope: {
					fieldRule: '=plFieldrule',
					customRule: '=customRule',
					ignoreViewMode: '=ignoreViewMode',
					onGetValidityRules: '=onGetValidityRules' // if defined, observer handler to which the resolved field rule (from API) is passed to, to allow custom post processing of rules
				},
				link: function (scope, element, attrs) {

					var isViewMode = function () {
						if (scope.ignoreViewMode) return false;
						return scope.$parent && scope.$parent.viewMode;
					};

					var applyRule = function (fieldRule) {
						if (fieldRule.Hidden || (fieldRule.ExternallyHidden && $localStorage.userData.isExternalUser)) {
							$animate.addClass(element, 'ng-hide');
						} else {
							if (element.hasClass('ng-hide'))
								$animate.removeClass(element, 'ng-hide');
						}

						if (fieldRule.Mandatory) {
							var labelElement = element.find('label');
							if (labelElement.length > 0) {
								if (isViewMode())
									$animate.removeClass(labelElement, 'mandatory');
								else
									$animate.addClass(labelElement, 'mandatory');
							} else {
								var cardElement = element.find('h2');
								if (cardElement && cardElement.length > 0) {
									if (isViewMode())
										$animate.removeClass(cardElement, 'mandatory');
									else
										$animate.addClass(cardElement, 'mandatory');
								}
							}
						}

						if (fieldRule.MaxLength && fieldRule.MaxLength > 0) {
							var inputLengthElement = element.find('input');
							if (inputLengthElement && inputLengthElement.length > 0)
								inputLengthElement.attr('maxlength', fieldRule.MaxLength);

							inputLengthElement = element.find('textarea');
							if (inputLengthElement && inputLengthElement.length > 0)
								inputLengthElement.attr('maxlength', fieldRule.MaxLength);
						}

						if (fieldRule.Disabled && !isViewMode()) {
							var inputElement = element.find('input');
							if (inputElement && inputElement.length > 0)
								inputElement.attr('disabled', 'disabled');

							inputElement = element.find('textarea');
							if (inputElement && inputElement.length > 0)
								inputElement.attr('disabled', 'disabled');

							inputElement = element.find('select');
							if (inputElement && inputElement.length > 0)
								inputElement.attr('disabled', 'disabled').trigger('chosen:updated');
						}
					};

					var checkFieldrule = function () {
						if (scope.customRule) {
							applyRule(scope.customRule);
							return;
						}

						if (!scope.fieldRule) return;

						registeredFieldService.getFieldRuleByEntityAndColumn(scope.fieldRule).then(function (fieldRule) {
							applyRule(scope.onGetValidityRules ? scope.onGetValidityRules(fieldRule) : fieldRule);
						}, function (error) {
							console.log(error);
						});
					};

					if (!scope.ignoreViewMode) {
						scope.$watch('$parent.viewMode', function (newValue, oldValue) {
							if (newValue === oldValue) return;
							checkFieldrule();
						});
					}

					// some rules depend on owner (ex.: accounting)
					// TODO: find a better way to monitor possibly dynamic validity rule change
					scope.$watch('$parent.modelDto.dataOwner', function (newValue, oldValue) {
						if (newValue === oldValue) return;
						checkFieldrule();
					});

					checkFieldrule();
				},
			};
		}
	]);
})();
