(function () {
	angular.module('Plania').controller('RegisteredFieldsController', ['$scope', 'Repository', 'TranslationService', '$modal', 'RegisteredFieldService', controller]);

	function controller($scope, repository, translationService, $modal, registeredFieldService) {
		$scope.filter = {};
		var allFieldRules;

		var createPrefixWithTranslation = function (prefix) {
			return {
				guidPrefix: prefix,
				translatedValue: translationService.translate('web-enum-prefix-' + prefix, prefix)
			};
		};

		var filterUnknownPrefix = function (prefix) {
			return !_.isString(prefix);
		};

		repository.getSingleWithNoParameter(repository.apiData.entityTypeInfo.url).then(function (result) {
			if (!result) return;

			$scope.entityTypeGroups = _.map(result, function (entityTypeGroup) {
				entityTypeGroup.prefixes = _.map(_.reject(entityTypeGroup.Prefixes, filterUnknownPrefix), createPrefixWithTranslation);
				delete entityTypeGroup.Prefixes;
				return entityTypeGroup;
			});
		});

		$scope.formatFieldName = function (fieldName) {
			return fieldName.replace('Guid', '');
		};

		$scope.editFieldRule = function (fieldRule) {
			$modal.open({
				templateUrl: 'app/registeredFields/registeredFieldEditModal.html',
				controller: 'RegisteredFieldEditModalController',
				size: 'sm',
				resolve: {
					params: function () {
						return {
							fieldRule: fieldRule,
						};
					}
				}
			}).result.then(function (result) {

				if (result.reset) {
					repository.deleteSingle(repository.apiData.registeredField.url, result.rule.Guid).then(
						function (result) {
							registeredFieldService.getRegisteredField($scope.selectedPrefix, true).then(function (result) {
								allFieldRules = result;
								filterFieldRules();
								repository.growl("Tilbakestillt", "success");
							});

						});
				} else {
					repository.updateSingleDictionary(repository.apiData.registeredField.url, result.rule).then(
						function (result) {
							repository.growl("Suksess", "success");
						}, repository.showError);
				}
			});
		};

		var filterFieldRules = function () {
			_.each(allFieldRules, function (property) {
				property.webTranslationSystemName = 'web-' + property.EntityType + "-";
				if (property.DomainFieldName.toLowerCase().startsWith('guid')) {
					property.webTranslationSystemName += property.DomainFieldName.substr(4);
				}
				else {
					property.webTranslationSystemName += property.DomainFieldName;
				}
			});


			$scope.fieldRules = _.filter(allFieldRules,
				function (property) {
					var show = true;

					var systemNameSearch = property.webTranslationSystemName;
					if (show && $scope.filter.searchString) {
						show =
							(property.DomainFieldName.toLowerCase().indexOf($scope.filter.searchString.toLowerCase()) > -1 ||
								translationService
								.translate(systemNameSearch, null, null)
									.toLowerCase()
									.indexOf($scope.filter.searchString.toLowerCase()) >
								-1);
					}
					if (show && $scope.filter.Mandatory) {
						show = property.Mandatory;
					}
					if (show && $scope.filter.Unique) {
						show = property.Unique;
					}
					if (show && $scope.filter.NotEditableInListView) {
						show = property.NotEditableInListView;
					}
					if (show && $scope.filter.Hidden) {
						show = property.Hidden;
					}
					if (show && $scope.filter.Private) {
						show = property.Private;
					}
					if (show && $scope.filter.History) {
						show = property.History;
					}
					if (show && $scope.filter.AvailableInListView) {
						show = property.AvailableInListView;
					}
					if (show && $scope.filter.ExternallyHidden) {
						show = property.ExternallyHidden;
					}

					return show;
				});
		};


		$scope.setSelectedPrefix = function (prefix) {
			registeredFieldService.getRegisteredField(prefix, true).then(function (result) {
				$scope.selectedPrefix = prefix;
				$scope.selectedPrefixHeader = translationService.translate('web-enum-prefix-' + prefix, prefix);
				allFieldRules = result;
				filterFieldRules();
			});
		};

		$scope.getModuleIcon = function (entityTypeGroupName) {
			return repository.moduleService.getModuleIcon(entityTypeGroupName);
		};

		$scope.$watch('filter', function (newValue, oldValue) {
			if (newValue === oldValue) return;

			filterFieldRules();
		}, true);
	}
})();
