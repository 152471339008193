
// =========================================================================
// Base controller for common functions
// =========================================================================
angular.module('Plania').controller('MainController', ['$scope', '$timeout', '$state', '$rootScope', controller]);

function controller($scope, $timeout, $state, $rootScope) {

	var vm = this;

    // Detact Mobile Browser
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        angular.element('html').addClass('ismobile');
        localStorage.setItem('ma-layout-status', 1);
    }
    // By default Sidbars are hidden in boxed layout and in wide layout only the right sidebar is hidden.
	vm.sidebarToggle = {
        left: false,
        right: false
    };

    if (!localStorage.getItem('ma-layout-status')) {
        localStorage.setItem('ma-layout-status', 1);
    }

    // By default template has a boxed layout
	vm.layoutType = localStorage.getItem('ma-layout-status');

    // For Mainmenu Active Class
	vm.$state = $state;

	var shouldUseFullscreen = function () {
        return $state.includes('map') || $state.includes('dwg.view') || $state.includes('document.viewPdf') || $state.includes('ifcFile.view');
	};
	vm.showFullscreen = shouldUseFullscreen();

	$rootScope.$on('$stateChangeSuccess', function () {
		vm.showFullscreen = shouldUseFullscreen();
	});

    //Close sidebar on click
	vm.sidebarStat = function (event) {
        if (!angular.element(event.target).parent().hasClass('active')) {
            vm.sidebarToggle.left = false;
        }
    };

}
