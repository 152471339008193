(function () {
	var app = angular.module('Plania');

	app.directive('plCategoryGridProperty', [function (TranslationService) {
		return {
			restrict: 'E',
			require: '^plCategoryGrid',
			scope: {
				property: '=property',
				selectedProperty: '=selectedProperty',
				selectProperty: '=selectProperty'
			},
			link: function (scope, element, attrs, gridCtrl) {
				scope.$parent.attachWidget(element, attrs, scope.property);

				scope.removeWidget = function () {
					swal({
						title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
						text: translationService.translate('web-swal-dashboard-widget-message', "Modulen vil bli permanent fjernet!"),
						type: "warning",
						showCancelButton: true,
						confirmButtonColor: "#f44336",
						confirmButtonText: translationService.translate('web-swal-dashboard-widget-confirm', 'Ja, fjern modulen'),
						cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
						closeOnConfirm: false
					}, function () {
						window.onkeydown = null;
						window.onfocus = null;
						swal(translationService.translate('web-swal-dashboard-widget-success', 'Modulen ble fjernet!'), '', "success");
						scope.$parent.removeWidget(element, attrs);
					});
				};
			},
			templateUrl: 'app/common/directives/dynamicProperty/plcategorygridProperty.html',
			controller: ['$scope', '$element', 'TranslationService', controller],
		};

	}]);

	var controller = function ($scope, $element, translationService) {
	};

})();
