(function () {
	angular.module('Plania').factory('EnumService', ['TranslationService', '$q', 'Repository', enumService]);

	function enumService(translationService, $q, repository) {
		var service = {};

		var enumValues = {};

		service.unit = [
			{ value: "$Th$", translatedValue: translationService.translate('web-enum-unit-hour', 'timer') },
			{ value: "$Mkg$", translatedValue: translationService.translate('web-enum-unit-kilogram', 'kg') },
			{ value: "$Vl$", translatedValue: translationService.translate('web-enum-unit-liter', 'liter') },
			{ value: "$Qp$", translatedValue: translationService.translate('web-enum-unit-quantity', 'stk') },
		];

		service.timeUnit = {
			Undefined: -1,
			Day: 0,
			Week: 1,
			Month: 2,
			Year: 3,
			HourOfUse: 4,
			Second: 5,
			Minute: 6,
			Hour: 7,
			Once: 8,
		};

		service.setTimeUnitOnRecurrence = function (recurrenceRule) {
			switch (recurrenceRule.Unit) {
				case translationService.translate('web-enum-timeunit-day'):
					recurrenceRule.Unit = service.timeUnit.Day;
					break;
				case translationService.translate('web-enum-timeunit-week'):
					recurrenceRule.Unit = service.timeUnit.Week;
					break;
				case translationService.translate('web-enum-timeunit-month'):
					recurrenceRule.Unit = service.timeUnit.Month;
					break;
				case translationService.translate('web-enum-timeunit-year'):
					recurrenceRule.Unit = service.timeUnit.Year;
					break;
			}
		};


		service.EnumNumberToString = function (enumType, number) {
			var deferred = $q.defer();
			if (typeof number !== 'number')
				return number;

			service.GetEnumValues(enumType).then(function(result) {
				var enumOption = _.find(result, function(enumOptions) { return enumOptions.Value === number; });
				if (enumOption)
					deferred.resolve(enumOption.StringValue);
				else
					deferred.reject('Could not find valid enum');
			});
			return deferred.promise;
		};

		service.GetEnumValues = function (enumType) {
			var deferred = $q.defer();

			if (enumValues[enumType]) {
				deferred.resolve(enumValues[enumType]);
			} else {
				repository.getSingle(repository.apiData.enum.url, enumType).then(
					function (result) {
						enumValues[enumType] = result;
						deferred.resolve(enumValues[enumType]);
					},
					function (error) {
						deferred.reject('Kunne ikke finne enum: ' + enumType);
					});
			}

			return deferred.promise;
		};


		service.getTimeUnitDate = function (date, timeUnit, timeUnitValue) {
			if (date !== typeof (Date)) {
				date = new Date(date);
			}
			switch (timeUnit) {
				case 'Undefined':
					return date;
				case 'Day':
					return new Date(date.setDate(date.getDate() + timeUnitValue));
				case 'Week':
					return new Date(date.setDate(date.getDate() + (timeUnitValue * 7)));
				case 'Month':
					return new Date(date.getFullYear(), date.getMonth() + timeUnitValue, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
				case 'Year':
					return new Date(date.getFullYear() + timeUnitValue, date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
				case 'HourOfUse':
					return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours() + timeUnitValue, date.getMinutes(), date.getSeconds());
				case 'Second':
					return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds() + timeUnitValue);
				case 'Minute':
					return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes() + timeUnitValue, date.getSeconds());
				case 'Hour':
					return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours() + timeUnitValue, date.getMinutes(), date.getSeconds());
				case 'Once':
					return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
				default:
			}
		};

		return service;
	}
})();
