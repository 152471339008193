(function () {
	angular.module('Plania').controller('ResourceGroupController', ['$scope', 'Repository', '$stateParams', 'TranslationService', 'ngTableParams', '$modal', 'ListService', controller]);

	function controller($scope, repository, $stateParams, translationService, ngTableParams, $modal, listService) {
		$scope.isUpdate = $scope.navigation.current.name === 'resourceGroup.edit';

        var updateAccess = function () {
            if ($scope.isUpdate) {
                var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
                $scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.ResourceGroup, checkOtherDO);
                $scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.ResourceGroup, checkOtherDO);
            } else {
                $scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.ResourceGroup);
                $scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.ResourceGroup);
            }
        };

		if ($scope.isUpdate) {
			repository.getSingle(repository.apiData.resourceGroup.url, $stateParams.guid).then(
                function (response) {
                	$scope.model = response.Data;
                	updateAccess();
                }, function (error) {
                	repository.growl(error, 'danger');
                }
            );
		}

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.update = function (destination) {
			var success = function (result) {
			    repository.growl($scope.isUpdate ? translationService.translate('update-resourceGroup-success', 'Ressursgruppe har blitt oppdatert.') : translationService.translate('create-resourceGroup-success', 'Ressursgruppen har blitt opprettet.'), 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.isUpdate) {
				repository.updateSingleDictionary(repository.apiData.resourceGroup.url, $scope.model).then(success, error);
			} else {
				repository.createSingleDictionary(repository.apiData.resourceGroup.url, $scope.model).then(success, error);
			}

		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-resourceGroup-message', "Ressursgruppen vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-resourceGroup-button-confirm', 'Ja, fjern Ressursgruppen'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.resourceGroup.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-resourceGroup-success', 'Ressursgruppen ble fjernet!'), result, "success");
						$scope.goBack('resourceGroup.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};

        $scope.$on($scope.events.newSelection, function () {
            updateAccess();
        });
	}

})();
