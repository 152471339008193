(function () {
	angular.module('Plania').controller('AreaXCleaningTaskController', ['$scope', 'Repository', 'modalParams', '$modalInstance', '$filter', 'EnumService', 'TranslationService', controller]);

	function controller($scope, repository, modalParams, $modalInstance, $filter, enumService, translationService) {
		$scope.model = {};
		$scope.model.CleaningTask = {};
		$scope.model.Area = {};
		var columns = [
            'Area.Id',
            'Area.Description',
            'ResourceGroup.Id',
            'Person.FirstName',
            'Person.LastName',
			'CleaningTask.Id',
			'CleaningTask.CleaningType'
		];

		repository.getSingle(repository.apiData.areaXCleaningTask.url, modalParams.Guid, JSON.stringify(columns)).then(
            function (result) {
            	$scope.model = result.Data;
            	if ($scope.model.Person)
					$scope.person = $scope.model.Person.FirstName + " - " + $scope.model.Person.LastName;

				$scope.isUpdatePause = $scope.model.PausedFromDate && $scope.model.PausedToDate;
				if (!$scope.isUpdatePause && modalParams.SuggestedPause) {
					$scope.model.PausedFromDate = modalParams.SuggestedPause.From;
					$scope.model.PausedToDate = modalParams.SuggestedPause.To;
				}
            });

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "person":
					filter.PropertyFilter = [{ Property: 'DoesCleaningTasks', Value: 1, Operator: '=' }];
					if ($scope.model.GuidCleaningTeam)
						filter.PropertyFilter.push({ Property: 'GuidResourceGroup', Value: $scope.model.GuidCleaningTeam, Operator: '=' });
					return filter;
				case "resourceGroup":
					filter.PropertyFilter = [{ Property: 'DoesCleaningTasks', Value: 1, Operator: '=' }];
					return filter;
			}
		};

		$scope.ok = function () {
			var toSave = angular.copy($scope.model);
			delete toSave.Recurrence;
			delete toSave.Area;

			if (toSave.PausedToDate) {
				toSave.PausedToDate = new Date(toSave.PausedToDate).toISOString();
			}
			if (toSave.PausedFromDate) {
				toSave.PausedFromDate = new Date(toSave.PausedFromDate).toISOString();
			}

			if (toSave.RecurrenceRule && toSave.RecurrenceRule.Unit) {
				enumService.setTimeUnitOnRecurrence(toSave.RecurrenceRule);
			}

			toSave.AttributeValues.forEach(function (attribute) {
				if (attribute.ValueType === 'Date' && typeof (attribute.DateValue) !== 'string') {
					if (attribute.DateValue)
						attribute.DateValue = attribute.DateValue.toISOString();
				}
			});

			repository.updateSingleDictionary(repository.apiData.areaXCleaningTask.url, toSave).then(function (result) {
				$modalInstance.close('Renholdsoppgaven har blitt oppdatert.');
			}, function(error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.removePause = function() {
			$scope.model.PausedFromDate = null;
			$scope.model.PausedToDate = null;
			$scope.model.PausedReason = null;

			repository.updateSingleDictionary(repository.apiData.areaXCleaningTask.url, $scope.model).then(function (result) {
				$modalInstance.close('Renholdsoppgaven har blitt oppdatert.');
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};
	}
})();












