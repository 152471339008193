(function () {
	angular.module('Plania').controller('ProjectMilestoneModalController', ['$scope', 'Repository', 'modalParams', '$modalInstance', 'TranslationService', '$modal', 'ngTableParams', 'ListService', controller]);
	function controller($scope, repository, modalParams, modalInstance, translationService, $modal, ngTableParams, listService) {

        $scope.model = {};
        $scope.isUpdate = false;
        $scope.isTemplate = modalParams.IsTemplate;

        if (modalParams.Milestone) {

            $scope.model = {
                Guid: modalParams.Milestone.Guid,
                Id: modalParams.Milestone.Id,
                Description: modalParams.Milestone.Description,
                DueDate: modalParams.Milestone.DueDate,
                EndDate: modalParams.Milestone.EndDate,
                GuidProject: modalParams.Milestone.GuidProject
            };
            $scope.isUpdate = true;
        } else {
            $scope.model.GuidProject = modalParams.GuidProject;
        }

        $scope.saveMilestone = function () {
            if ($scope.isUpdate) {
                repository.updateSingleDictionary(repository.apiData.projectMilestone.url, $scope.model).then(function (result) {
                    modalInstance.close();
                }).catch(function (error) {
                    swal('Feil ved lagring', error, 'error');
                });
            }
            else {
                repository.createSingleDictionary(repository.apiData.projectMilestone.url, $scope.model).then(function (result) {
                    modalInstance.close();
                }).catch(function (error) {
                    swal('Feil ved lagring', error, 'error');
                });
            }
        };
    }

})();
