(function () {
    angular.module('Plania').controller('MapController', ['$scope', 'Repository', 'leafletData', 'TranslationService', '$stateParams', '$localStorage', 'Constants', '$compile', '$window', '$location', controller]);

    function controller($scope, repository, leafletData, translationService, $stateParams, $localStorage, constants, $compile, $window, $location) {
        var menuParams = {};
        $scope.entityInfos = {};
        $scope.urlParams = $location.search();

		if ($scope.navigation.params.menuGuid)
			menuParams = repository.getMenuParameters($scope.navigation.params.menuGuid);
		$scope.mapData = {
			Norge: {
				lat: 63.43,
				lng: 10.40,
				zoom: 5
			},
			layers: {
				overlays: {
					markercluster: {
						name: 'MarkerCluster',
						type: 'markercluster',
                        layerOptions: {
							spiderfyDistanceMultiplier: 1.5,
							showCoverageOnHover: false,
							maxClusterRadius: 60
						},
						visible: true
					}
				}
			},
			defaults: {
				scrollWheelZoom: false,
				zoom: 1
			}
		};
		$scope.leafletData = leafletData;
		$scope.shouldCenterOnMarkers = true;

		$scope.themeSelections = [
			{
				label: translationService.translate('web-map-toolbar-selectTheme-Basicdata', 'Grunndata'),
				value: 'basicData'
			},
			{
				label: translationService.translate('web-map-toolbar-selectTheme-request', 'Meldinger'),
				value: 'request'
			},
			{
				label: translationService.translate('web-map-toolbar-selectTheme-workOrder', 'Arbeidsordre'),
				value: 'workOrder'
			}
		];

		$scope.requestFilterStatusChange = function (newValue) {
			$scope.panelFilter.request.status = newValue;
			if (!$scope.shouldCenterOnMarkers) {
				$scope.shouldCenterOnMarkers = true;
				onReloadData();
			}
		};

		var filterWatcher = function () { };
		function addFilterWatcher(watch) {
			basicDataWatcher = $scope.$watch(watch,
				function(newValue, oldValue) {
					if (newValue === oldValue) return;
                    onReloadData();
                }, true);
		}

        $scope.themeChanged = function (panelFilter) {
            filterWatcher();

            if ($scope.selectedTheme === 'basicData') {
                $scope.panelFilter = panelFilter ? panelFilter : { showBasicData: true, basicData: { showEstate: true, showBuilding: true, showEquipment: false } };
				addFilterWatcher('panelFilter.basicData');
			}
			if ($scope.selectedTheme === 'workOrder') {
                $scope.panelFilter = panelFilter ? panelFilter : { showWorkOrder: true };
				addFilterWatcher('panelFilter.workOrder');
			}
			if ($scope.selectedTheme === 'request') {
                $scope.panelFilter = panelFilter ? panelFilter : { showRequest: true, request: { status: [0, 1, 6] } };
				addFilterWatcher('panelFilter.request');
            }

            $location.search('theme', $scope.selectedTheme);
            $location.search("panelFilter", JSON.stringify($scope.panelFilter));
            $scope.shouldCenterOnMarkers = true;
			onReloadData();
		};

		if ($localStorage.generalOptions.MapSettings) {
            var settings = JSON.parse($localStorage.generalOptions.MapSettings);

            if (settings.entityInfo) {
                $scope.entityInfos = settings.entityInfo;
            }

			if (!settings.TileLayer && !settings.TileLayerOptions && !settings.GoogleApiKey) {
				// use default setting if nothing else is specified
                settings.TileLayer = constants.availableMaps[0].mapData.TileLayer;
                settings.TileLayerOptions = constants.availableMaps[0].mapData.TileLayerOptions;
			}
            var options = {};

            if (settings.Options) {
				if (settings.Options.Origin)
					options.origin = JSON.parse(settings.Options.Origin);
				if (settings.Options.Resolution)
					options.resolutions = JSON.parse(settings.Options.Resolution);
			}

			$scope.mapData.defaults = {
				tileLayer: settings.TileLayer,
				tileLayerOptions: settings.TileLayerOptions ? JSON.parse(settings.TileLayerOptions) : null
			};

			$scope.mapData.layers.baselayers = {
				osm: {
					name: $scope.mapData.defaults.tileLayerOptions.attribution,
					type: 'xyz',
					url: $scope.mapData.defaults.tileLayer
				}
			};

			if (settings.CrsCode && settings.Proj4Def && options)
                $scope.mapData.defaults.crs = new L.Proj.CRS(settings.CrsCode, settings.Proj4Def, options);
		}

		$scope.mapData.paths = {};// must be initialized here, or no path will ever show
		$scope.showFilter = false;
		$scope.panelFilter = { showBasicData: true };

		function getTotalBottomMargin(element) {
			var margin = 0;
			// getting CSS properties may eventually fail -> guard with try-catch to gently return what is already computed without exception
			try {
				for (var elem = element.parent(); elem && elem.css; elem = elem.parent()) {
					margin += parseInt(elem.css('padding-bottom'));
					margin += parseInt(elem.css('margin-bottom'));
					margin += parseInt(elem.css('border-bottom-width'));
				}
			}
			catch (e) { }
			return margin;
		}

        var resizeMap = function () {
            var map = $("#map");
            var currentHeight = map.height();
            var height = $(window).innerHeight() - map.offset().top - getTotalBottomMargin(map);
            if (currentHeight !== height) {
                map.height(height);
                leafletData.getMap("map").then(function (map) {
                    map.invalidateSize();
                    map._resetView(map.getCenter(), map.getZoom(), true);
                });
            }
        };

        leafletData.getMap("map").then(function () {
            setTimeout(function () {
                resizeMap();
            }, 500);
        });

        $window.addEventListener("resize", resizeMap());

        $scope.$on("$destroy", function () {
            $window.removeEventListener("resize", resizeMap());
        });

		function onReloadData() {

			leafletData.getMap("map").then(function (map) {
				var filters = {};

				// append panel filters to general filter
				for (var property in $scope.panelFilter)
					filters[property] = $scope.panelFilter[property];

				var sorting = {};
				//custom propertyfilters (svv customization etc, when building propertyfilter into panelfilter this needs to be merged instead of overwritten)
				filters.PropertyFilter = repository.mapService.getPropertyFilter('gisEntity', $stateParams);
				$scope.mapData.markers = {};
				$scope.mapData.paths = {};
                repository.GetPaginated(repository.apiData.gisManagement.url, 0, -1, sorting, filters, null, JSON.stringify([])).then(function (result) {
					$scope.mapData.markers = result.markers;
					$scope.mapData.paths = result.paths;
				});

                $scope.$on('leafletDirectiveMarker.click', function (event, args) {
                    var marker = $scope.mapData.markers[args.modelName];
					if ($scope.selectedTheme === 'workOrder') {

						marker.message = '<div ng-init="init(\'' + marker.entity + '\',\'' + marker.entityGuid + '\',\'' + marker.caption + '\')" ng-controller="WorkOrderMapMessageController" > <div ng-include="\'app/map/views/message.html\'"></div></div > ';
                    } else {
						marker.message = '<div class="map-message-info">' +
							'<h4 class="p-b-5 p-r-10 separator-line c-teal clickable" ng-click="navigation.go(\'' + marker.entity.toLowerCase() + '.edit\', {guid:\'' + marker.entityGuid + '\'})">' +
							'<i class="zmdi zmdi-pin"></i> ' + marker.caption +
							'</h4></div>';
					}
                });

                if ($scope.urlParams.lat) {
                    map.setView({ lat: $scope.urlParams.lat, lng: $scope.urlParams.lng }, $scope.urlParams.zoom);
                } else {
                    centerOnMarkers();
                }

                $location.search("panelFilter", JSON.stringify($scope.panelFilter));
                $scope.urlParams = $location.search();
			});
		}

        $scope.selectedTheme = $scope.urlParams.theme ? $scope.urlParams.theme : "basicData";

        var panelFilter = $scope.urlParams.panelFilter ? JSON.parse($scope.urlParams.panelFilter) : null;

        //this will also call reloaddata and populate the map with markers based on current theme
        $scope.themeChanged(panelFilter);

        function centerOnMarkers() {
			if (_.isEmpty($scope.mapData.markers) && _.isEmpty($scope.mapData.paths)) {
				return;
			}
			leafletData.getMap("map").then(function (map) {
				//Get bounds and center to location
				var latLngs = [];

				for (var marker in $scope.mapData.markers) {
					if ($scope.mapData.markers[marker].lat && $scope.mapData.markers[marker].lng)
						latLngs.push(new L.LatLng($scope.mapData.markers[marker].lat, $scope.mapData.markers[marker].lng));
				}

				for (var path in $scope.mapData.paths) {
					if ($scope.mapData.paths[path].latlngs[0].lat && $scope.mapData.paths[path].latlngs[0].lng)
						latLngs.push(new L.LatLng($scope.mapData.paths[path].latlngs[0].lat, $scope.mapData.paths[path].latlngs[0].lng));
				}

				var bounds = new L.LatLngBounds(latLngs);
				var existingZoom = map.getZoom();
				//padding should force one zoom level earler if any markers are on the border
				map.fitBounds(bounds, { padding: [50, 50] });

				//hack to fix bug in angular leaflet directive setting wrong zoom level
				var zoom = map.getZoom();
				if (zoom === 0) {
					map.setZoom(existingZoom);
				}
			});
        }


        leafletData.getMap('map').then(function (map) {
            map.eachLayer(function (layer) {
                layer.on('spiderfied', function (a) {
                    var message;
                    if ($scope.selectedTheme === "workOrder") {

                        var guids = {
                            GuidBuilding: [],
                            GuidEstate: [],
                            GuidEquipment: []
                        };

                        a.markers.forEach(function (mark) {
                            if (mark.options.entity === "Building") {
                                guids.GuidBuilding.push(mark.options.entityGuid);
                            }
                            else if (mark.options.entity === "Estate") {
                                guids.GuidEstate.push(mark.options.entityGuid);
                            }
                            else if (mark.options.entity === "Equipment") {
                                guids.GuidEquipment.push(mark.options.entityGuid);
                            }
                        });

                        guids.GuidBuilding = guids.GuidBuilding.join(",");
                        guids.GuidEstate = guids.GuidEstate.join(",");
                        guids.GuidEquipment = guids.GuidEquipment.join(",");

                        var caption = translationService.translate('web-map-toolbar-selectTheme-workOrder', 'Arbeidsordre');

                        message = $compile('<div ng-init="init(\'' + a.markers[0].options.entity.toLowerCase() + '\',\'' + a.markers[0].options.entityGuid + '\',\'' + caption + '\',\'' + false + '\',\'' + true + '\',\'' + guids.GuidBuilding + '\',\'' + guids.GuidEstate + '\',\'' + guids.GuidEquipment + '\')" ng-controller="WorkOrderMapMessageController" > <div ng-include="\'app/map/views/message.html\'"></div></div > ')($scope);
                    }
                    else {
                        $scope.clustermarkers = a.markers.map(function (mark) {
                            return ({
                                entity: mark.options.entity,
                                entityGuid: mark.options.entityGuid,
                                caption: mark.options.caption
                            });
                        });
                        message = $compile('<map-cluster-popup></map-cluster-popup>')($scope);
                    }

                    a.cluster.closePopup();
                    a.cluster.bindPopup(message[0]);
                    // Wait for the leaflet app to fully render the content. Placement of popup will be misaligned without this, or using update.
                    setTimeout(function () {
                        a.cluster.openPopup();
                    }, 100);
                });
            });

            // Set position and zoom levels into the url
            map.on("moveend", function (m) {
                $location.search('zoom', map.getZoom());
                $location.search('lat', map.getCenter().lat);
                $location.search('lng', map.getCenter().lng);
            });

        });

		$scope.$on($scope.events.newSelection, function () {
			$scope.mapData.markers = {};
			$scope.mapData.paths = {};

			$scope.shouldCenterOnMarkers = true;
			onReloadData();
		});
	}
})();
