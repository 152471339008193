(function () {
	angular.module('Plania').controller('ContractItemModalController', ['$scope', '$modalInstance', 'params', 'Repository', 'TranslationService', 'ngTableParams', 'ListService', controller]);

	function controller($scope, $modalInstance, params, repository, translationService, ngTableParams, ListService) {
		$scope.isUpdate = !!params.Guid;
		$scope.model = {};
		$scope.modelDto = {};
		$scope.activateAutocomplete = false;

		var columns = [

		];

		var getEntity = function () {
			// for either update (with existing GUID) or insert (with default null GUID), fetch the whole entity to get proper FieldRules, which do not only depend on the entity type, but also on the current entity properties
			// (there is not performance loss compared to the old solution, since a new object already needed a request to fetch field rules anyway)
			return repository.getSingle(repository.apiData.contractItem.url, ($scope.isUpdate ? params.Guid : "00000000-0000-0000-0000-000000000000"), JSON.stringify(columns));
		};

		//TODO: Change to use normal repository functions
		getEntity().then(
			function (response) {
				// for the moment only use the response to get proper FieldRules, but still use the client entity rather than the returned one, since some of its fields have be prefilled on client side
				// TODO: find a way, either to post client entity (with its prefilled fields) to the API, or to move the whole prefill logic to the API (preferred),
				//       to be able to benefit from the sometimes complex dynamic prefill from the API, which would be difficult to maintain consistently on all clients as the product evolves
				var contractItem = $scope.isUpdate ? response.Data : params.contractItem;

				$scope.model = contractItem;

				$scope.fieldRules = response.FieldRules;
				$scope.reload = true;
				$scope.activateAutocomplete = true;


			}, function (error) {
				repository.growl(error, 'danger');
			}
		);

		$scope.onArticleSelect = function (article) {
			$scope.model.Id = article.Id;
			$scope.model.Description = article.Description;
			$scope.model.GuidArticle = article.Guid;
			$scope.model.UnitPrice = article.UnitPrice;
			$scope.model.Unit = article.Unit;

			$scope.model.GuidDepartment = article.GuidDepartment;
			$scope.model.GuidAccount = article.GuidAccount;
			$scope.model.GuidAccounting0 = article.GuidAccounting0;
			$scope.model.GuidAccounting1 = article.GuidAccounting1;
			$scope.model.GuidAccounting2 = article.GuidAccounting2;
			$scope.model.GuidAccounting3 = article.GuidAccounting3;
			$scope.model.GuidAccounting4 = article.GuidAccounting4;
		};

		$scope.update = function (destination) {
			var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('update-contractItem-success', 'Kontrakt objekt har blitt oppdatert.') : translationService.translate('create-contractItem-success', 'Kontrakt objekt har blitt opprettet.'), 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				$modalInstance.close();
				if (destination)
					$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				if (typeof (error) === "string")
					repository.growl(error, 'danger');
				else
					repository.growl(error.Data.Message, 'danger');
			};

			if ($scope.isUpdate)
				repository.updateSingleDictionary(repository.apiData.contractItem.url, $scope.model).then(success, error);
			else
				repository.createSingleDictionary(repository.apiData.contractItem.url, $scope.model).then(success, error);
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}
})();
