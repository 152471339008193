(function () {
    angular.module('Plania').controller('CreateIfcFileModalController', ['$scope', '$modalInstance', 'Repository', controller]);

    function controller($scope, $modalInstance, repository) {


        $scope.files = [];

        $scope.removeFile = function (index) {
            $scope.files.splice(index, 1);
        };

        $scope.onFileSelect = function ($files) {
            $scope.files = $scope.files.concat($files);
        };

        $scope.dragOverClass = function ($event) {
            var items = $event.dataTransfer.items;
            var hasFile = false;
            if (items) {
                for (var i = 0; i < items.length; i++) {
                    if (items[i].kind === 'file') {
                        hasFile = true;
                        break;
                    }
                }
            } else {
                hasFile = true;
            }
            return hasFile ? "drop-box-dragover" : "";
        };

        var addFileId = function () {
            $scope.files.forEach(function (r) {
                //Generate unique id for files used for upload progress update
                r.id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 16);
            });
        };

        $scope.upload = function () {
            $scope.isUploading = true;
            $scope.ifcFile.prefix = repository.apiData.ifcFile.prefix;

            addFileId();

            repository.createSingleWithBinary(repository.apiData.ifcFile.urlChunk, $scope.ifcFile, $scope.files).then(function (result) {
                $scope.isUploading = false;
                $scope.alreadyUploaded = true;

                var savedFiles = _.map(result.savedFiles, 'guid');
                $modalInstance.close(savedFiles);

            }, function (error) {
                repository.growl(error[0].error, 'danger');

                $scope.isUploading = false;
                error.forEach(function (error) {
                    var identifiedFile = _.find($scope.files, function (f) { return f.id === error.id; });
                    if (identifiedFile) {
                        identifiedFile.progressType = 'danger';
                    }
                });
            }, function (file) { //UPDATE
                var identifiedFile = _.find($scope.files, function (f) { return f.id === file.id; });
                if (identifiedFile) {
                    identifiedFile.total = file.total;
                    identifiedFile.count = file.count;
                    identifiedFile.progressType = file.count >= file.total ? 'success' : 'info';
                }
            });
        };

        $scope.ok = function () {
            $modalInstance.close();
        };

        $scope.cancel = function () {
            $modalInstance.dismiss();
        };
    }
})();
