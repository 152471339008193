(function () {
	angular.module('Plania').factory('ListService', ['$filter', '$q', 'Repository', 'ngTableParams', 'TranslationService', '$localStorage', 'ListCompressionService', '$state',
		function ($filter, $q, repository, ngTableParams, translationService, $localStorage, listCompressionService, $state) {
			var service = {};

			var tableCellMaxLength = 50;

			function isJson(str) {
				try {
					JSON.parse(str);
				} catch (e) {
					return false;
				}
				return true;
			}

			var modifyResult = function (entityData, resultList, columns, filter) {
				if (entityData.prefix === repository.apiData.areaXCleaningTask.prefix) {
					if (!resultList || !columns) return;

					resultList.forEach(function (data) {
						if (data.ExitCleaningRequestStatus === 'Possible') {
							data.ExitCleaningConfirmedDate = null;
						}
						else {
							data.ExitCleaningDecisionDate = null;
						}

						if (data.ExitCleaningRequestStatus === -1 || data.ExitCleaningRequestStatus === 'Undefined')
							data.ExitCleaningRequestStatus = null;
					});
				}
			};

			// Made it as a service to keep it synchronized with all implementations. Should probably be moved to a new service if we find more common functionalities for cleaning
			service.getNextCleaningDateString = function (task, usePeriodicEndDate) {
				if (!task.NextCleaningDate) return translationService.translate('web-areaXCleaningTask-NextCleaning-oneTimeTask', 'Engangsoppgave');

				if (usePeriodicEndDate && task.CleaningTask.CleaningType === 'PeriodicCleaning')
					return translationService.translate('web-areaXCleaningTask-periodicCleaning-within', 'Innen') + ' ' + $filter('date')(task.RecurrenceRule.EndDate, 'dd.MM.yyyy');

				var date = new Date(task.NextCleaningDate);
				date.setHours(0, 0, 0, 0);
				var today = new Date();
				today.setHours(0, 0, 0, 0);
				var tomorrow = new Date(today);
				tomorrow.setDate(tomorrow.getDate() + 1);

				if (date.getTime() === today.getTime())
					return translationService.translate('web-areaXCleaningTask-NextCleaning-today', 'I dag');
				else if (date.getTime() === tomorrow.getTime())
					return translationService.translate('web-areaXCleaningTask-NextCleaning-tomorrow', 'I morgen ');
				else if (date.getTime() < today.getTime())
					return translationService.translate('web-areaXCleaningTask-NextCleaning-expired', 'Utgått');
				else
					return $filter('date')(date, 'dd.MM.yyyy');
			};

			service.GetPropertyValue = function (row, column) {
				var properties = column.Property.split('.');
				var temp = row;

				properties.forEach(function (prop) {
					if (temp) {
						if (prop === 'Recurrence') {
							temp = temp.RecurrenceRule;
						} else {
							temp = temp[prop];
						}
					}
				});

				if (!temp && column.PropertyType !== 'bool' && temp !== 0) {
					return '';
				}

				//show checkmark instead of true/false
				if (column.PropertyType === 'bool') {
					if (temp) {
						return '<span class="text-success"><i class="zmdi zmdi-check zmdi-hc-2x"></i></span>';
					} else {
						return '';
					}
				}

				if (column.Property === 'Severity') {
					if (temp) {
						switch (temp) {
							case 'Error':
								return '<span class="text-danger"><i class="zmdi zmdi-alert-octagon zmdi-hc-lg"></i> ' + temp + '</span>';
							case 'Warning':
								return '<span class="text-warning"><i class="zmdi zmdi-alert-triangle zmdi-hc-lg"></i> ' + temp + '</span>';
							case 'Info':
								return '<span class="text-info"><i class="zmdi zmdi-info zmdi-hc-lg"></i> ' + temp + '</span>';
						}
					} else {
						return '';
					}
				}

				if (column.Property === 'CleaningEstimateYear' || column.Property === 'AverageCleaningPerYear') {
					if (temp) {
						return $filter('time')(temp, 'mm', 'hht mmm', false);
					} else {
						return 0;
					}
				}

				if (column.Property === 'ExitCleaningDecisionDate' || column.Property === 'ExitCleaningConfirmedDate') {
					if (temp) {
						return $filter('date')(temp, 'HH:mm');
					} else {
						return null;
					}
				}

				if (row.Prefix === repository.commonService.prefix.AreaXCleaningTask && column.Property === 'NextCleaningDate') {
					return service.getNextCleaningDateString(row);
				}

				if (row.Prefix === repository.commonService.prefix.WebMenu && column.Property === 'Reference') {
					if (temp) {
						if (typeof (temp) === 'string' && isJson(temp)) {
							var parsed = JSON.parse(temp);
							if (!parsed || !parsed.PropertyFilter) return temp;
							return parsed.PropertyFilter.map(function (item) { return item.Property + ' ' + item.Operator + ' ' + item.Value; }).join(', ');
						}
					}
				}

				if (column.PropertyType === 'date') {
					if (column.Property === 'CreationDate' || column.Property === 'UpdatedDate') {
						return $filter('date')(temp, 'dd.MM.yyyy, HH:mm:ss');
					} else {
						return $filter('date')(temp, 'dd.MM.yyyy');
					}
				}

				if (column.PropertyType === 'datetime') {
					return $filter('date')(temp, 'dd.MM.yyyy, HH:mm:ss');
				}

				//Limit to 2 decimals
				if (column.PropertyType === 'double' && column.Property !== 'CompletionRate' && column.Property !== 'PaymentOrderCoverageRate') {
					if (temp === 0)
						return 0;
					else
						return $filter('number')(temp, 2);
				}

				if (column.PropertyType === 'recurrence' || column.Property === 'Recurrence') {
					return $filter('plRecurrenceRuleFilter')(temp);
				}

				if (column.PropertyType === 'progress' || column.Property === 'CompletionRate') {
					if (column.Property === 'CompletionRate') {
						temp = Number((temp * 100).toFixed(0));
					}

					var progressValue = $filter('number')(temp, 0);

					var type = 'success';
					var returnValue = '';
					if (temp < 30) {
						returnValue += '<span class="text-danger">' + progressValue + '%</span>';
					} else if (temp < 60) {
						returnValue += '<span class="text-warning">' + progressValue + '%</span>';
					} else if (temp < 100) {
						returnValue += '<span class="text-info">' + progressValue + '%</span>';
					} else if (temp === 100) {
						type = 'success';
						returnValue += '<i class="zmdi zmdi-check zmdi-hc-lg text-success"></i> <span class="text-success">' + progressValue + '%</span>';
					}
					return returnValue;
				}

				if (column.Property === 'PaymentOrderCoverageRate') {
					temp = Number((Math.floor(temp * 100)).toFixed(0));
					if (row.ReinvoicingStatus && row.ReinvoicingStatus !== "None" && temp < 100)
						return '<span class="text-danger">' + temp + '%</span>';
					return '<span>' + temp + '%</span>';
				}

				if (column.PropertyType && column.PropertyType.startsWith('enum.')) {
					var enumType = column.PropertyType.split('.')[1];
					if (enumType) {
						var enumValues = temp.split(', ');
						var translatedList = [];
						enumValues.forEach(function (val) {
							translatedList.push(translationService.translate('web-enum-' + enumType + '-' + val));
						});
						return limitResult(translatedList.join(', '), tableCellMaxLength);
					}
				}

				//Limit result to 50 for better list views
				return limitResult(temp, tableCellMaxLength);

			};

			function limitResult(textField, maxLength) {
				var result = $filter('limitTo')(textField, maxLength);
				if (textField.length > maxLength) {
					return result + '...';
				} else {
					return result;
				}
			}

			service.getWebListViews = function (selectedSetup, prefix, overrideDefault, contextId, filters) {
				var deferred = $q.defer();

				repository.GetPaginated(repository.apiData.webListView.url, 0, 50, {}, { Prefix: prefix, ContextId: contextId }).then(function (result) {
					var listOptions = result.List;
					var defaultListSetup, selectedListSetup, preSelectedSetup;
					listOptions.forEach(function (setup) {
						if (setup.IsDefaultView && !overrideDefault) {
							defaultListSetup = setup;
						}

						if (selectedSetup && setup.Guid === selectedSetup.Guid) {
							preSelectedSetup = setup;
						}

						setup.Columns.forEach(function (col) {
							if (col.Filter && typeof col.Filter === 'string') {
								col.Filter = JSON.parse(col.Filter);
							}

							if (col.GroupBy) {
								col.title = col.Title;
								col.field = col.Property;
								col.groupable = col.Property;
								col.show = true;
							}
						});

						if (setup.Sorting && typeof (setup.Sorting) === 'string') {
							setup.Sorting = JSON.parse(setup.Sorting);
						}

						if (setup.IsPublicView ) {
							setup.isPublicViewText = translationService.translate('web-webListView-isPublicViewText-common', 'Felles');
						} else {
							setup.isPublicViewText = translationService.translate('web-webListView-isPublicViewText-personal', 'Personlig');
						}
					});

					var plStandard = service.getStandardView(prefix, filters, contextId);

					plStandard.IsDefaultView = defaultListSetup ? false : true;
					plStandard.EntityName = plStandard.EntityType;
					plStandard.isPublicViewText = translationService.translate('web-webListView-isPublicViewText-common', 'Felles');

					if (!plStandard.ContextId)
						plStandard.ContextId = 'List-' + plStandard.EntityType;

					if (!_.find(listOptions, function (o) { return o.Id === plStandard.Id; }))
						listOptions.unshift(plStandard);

					if (selectedSetup && selectedSetup.inMemory) {
						if (!_.findWhere(listOptions, { Guid: selectedSetup.Guid }) && selectedSetup.Id.toLowerCase() !== 'plania standard') {
							listOptions.push(selectedSetup);
						}
						selectedListSetup = selectedSetup;
					}
					else if (preSelectedSetup) {
						selectedListSetup = preSelectedSetup;
					} else if (defaultListSetup) {
						selectedListSetup = defaultListSetup;
					} else {
						selectedListSetup = listOptions[0];
					}

					deferred.resolve({
						listOptions: listOptions,
						selectedListSetup: selectedListSetup
					});
				}, function (error) {
					var plStandard = service.getStandardView(prefix, filters, contextId);
					plStandard.IsDefaultView = true;
					plStandard.EntityName = plStandard.EntityType;

					deferred.resolve({
						listOptions: [plStandard],
						selectedListSetup: plStandard
					});
				});

				return deferred.promise;
			};

			service.currentListSetup = {};

			service.updateSelectedListSetup = function (prefix, listSetup) {
				service.currentListSetup[prefix] = listSetup;
			};

			service.updateUrl = function (tableParams, listSetup) {
				var stateObject = {};
				stateObject.filter = tableParams.filter();
				delete stateObject.filter.selectedWebListView;
				stateObject.sorting = tableParams.sorting();

				var selectedListSetup = {};
				selectedListSetup.inMemory = true;
				selectedListSetup.Columns = _.map(listSetup.Columns,
					function (col) {
						return {
							Position: col.Position,
							Title: col.Title,
							Property: col.Property,
							PropertyType: col.PropertyType,
							AutocompleteService: col.AutocompleteService,
							AutocompleteProperty: col.AutocompleteProperty,
							Filter: col.Filter
						};
					});
				selectedListSetup.Guid = listSetup.Guid;
				selectedListSetup.Id = listSetup.Id;
				selectedListSetup.IsPublicView = listSetup.IsPublicView;
				selectedListSetup.IsDefaultView = listSetup.IsDefaultView;
				selectedListSetup.EntityName = listSetup.EntityName;
				selectedListSetup.EntityType = listSetup.EntityType;
				selectedListSetup.GroupBy = listSetup.GroupBy;
				selectedListSetup.Sorting = stateObject.sorting;

				stateObject.selectedListSetup = selectedListSetup;
				repository.location.search('filter', listCompressionService.encodeListUri(stateObject)).replace();
			};

			service.getNgTableParams = function (selectedListSetup, navigation, entityData, preSelected, filterParams) {
				service.updateSelectedListSetup(selectedListSetup.EntityName, selectedListSetup);

				if (!$localStorage.listParams)
					$localStorage.listParams = {};

				if (!$localStorage.listParams[entityData.prefix])
					$localStorage.listParams[entityData.prefix] = {};

				//Handling of filters
				var menuParams = filterParams || {};
				if (!menuParams.PropertyFilter)
					menuParams.PropertyFilter = [];

				if (navigation.params.menuGuid) {
					var loadedParams = repository.getMenuParameters(navigation.params.menuGuid);

					for (var property in loadedParams) {
						if (property === 'PropertyFilter')
							continue;

						// Webmenu option for "Users own selected list setup" can have Guid equal to empty. This will call API twice, and cause error.
						if (property === 'selectedWebListView' && loadedParams[property].Guid === '00000000-0000-0000-0000-000000000000')
							continue;

						if (Object.prototype.hasOwnProperty.call(loadedParams, property)) {
							menuParams[property] = loadedParams[property];
						}
					}

					if (loadedParams.PropertyFilter && loadedParams.PropertyFilter.length > 0) {
						menuParams.PropertyFilter = _.union(menuParams.PropertyFilter, loadedParams.PropertyFilter);
					}

					if (filterParams.searchString)
						menuParams.searchString = filterParams.searchString;
				}

				if (entityData.prefix === repository.apiData.userGroup.prefix)
					menuParams.PropertyFilter.push({ Property: 'IsUserGroup', Operator: '=', Value: 1 });

				if (entityData.prefix === repository.apiData.controlListItemAnswer.prefix) {
					menuParams.FilterAnswerList = true;
				}

				//Currently only used by workOrder and project to handle ActivityStatus filters
				if ((typeof menuParams.ActivityStatus === "undefined" || menuParams.ActivityStatus === null) && (entityData.prefix === repository.apiData.workOrder.prefix || entityData.prefix === repository.apiData.project.prefix)) {
					var filter = _.filter(menuParams.PropertyFilter, function (row) {
						switch (entityData.prefix) {
							case repository.apiData.workOrder.prefix:
								return row.Property.toLowerCase() === 'stage';
							case entityData.prefix === repository.apiData.project.prefix:
								return row.Property.toLowerCase() === 'projectstatus.stage';
						}
					});

					if (filter[0]) {
						var stage = filter[0];
						menuParams.ActivityStatus = (stage.Value === "1" &&
							(stage.Operator === '=' || stage.Operator === 'in' || stage.Operator === 'contains')) ||
							(stage.Value !== '1' && (stage.Operator !== '=' && stage.Operator !== 'in' && stage.Operator !== 'contains')) ? 'Active' : 'History';
					} else {
						switch (entityData.prefix) {
							case repository.apiData.workOrder.prefix:
								menuParams.ActivityStatus = $localStorage.workOrderActivityStatus;
								break;
							case repository.apiData.project.prefix:
								if ($state.current.name !== 'projectTemplate.list')
									menuParams.ActivityStatus = $localStorage.projectActivityStatus;
								break;
						}
					}

				}

				delete menuParams.Prefill;

				var items = new ngTableParams({
					page: $localStorage.listParams[entityData.prefix].page ? $localStorage.listParams[entityData.prefix].page : 1,
					count: $localStorage.listParams[entityData.prefix].count ? $localStorage.listParams[entityData.prefix].count : 20,
					sorting: selectedListSetup.Sorting,
					filter: menuParams
				},
					{
						total: 0,
						counts: [20, 50, 100, 200],
						filterDelay: 10,
						paginationMaxBlocks: 8,
						getData: function ($defer, params) {
							$defer.promise.id = Math.random().toString(36).substr(2);
							items.newestPromise = $defer.promise;

							selectedListSetup.VisibleColumns = _.filter(selectedListSetup.Columns, function (c) {
								if (c.Filter && c.Filter.some(function (f) { return f.Hidden; }))
									return false;
								return true;
							});

							while (items.data.length > 0)
								items.data.pop();
							var filter = _.cloneDeep(menuParams), columns = [];
							if (!filter.PropertyFilter)
								filter.PropertyFilter = [];
							service.currentListSetup[selectedListSetup.EntityName].Columns.forEach(function (col) {
								if (col.Filter)
									filter.PropertyFilter = filter.PropertyFilter.concat(col.Filter);
								columns.push(col.Property);
							});

							$localStorage.listParams[entityData.prefix] = { page: params.page(), count: params.count() };

							if (selectedListSetup.EntityName === repository.apiData.deviation.prefix || selectedListSetup.EntityName === repository.apiData.condition.prefix) {
								columns.push.apply(columns, ['Estate.Id', 'Estate.Description', 'Building.Id', 'Building.Description', 'Area.Id', 'Area.Description', 'Equipment.Id', 'Equipment.Description']);
								columns = _.uniq(columns);
							}

							if (selectedListSetup.EntityName === repository.apiData.controlListXEntity.prefix) {
								columns.push.apply(columns, ['ControlList.Color', 'ControlList.UseImage', 'ControlList.Guid']);
								columns = _.uniq(columns);
							}

							service.updateUrl(params, service.currentListSetup[selectedListSetup.EntityName]);

							// Grouped data should filter first on grouped value.
							var sorting = params.sorting();

							var listSetup = service.currentListSetup[entityData.prefix];
							if (listSetup.GroupBy) {
								var groupSort = {};
								groupSort[listSetup.GroupBy] = 'asc';

								// The sorted group need the first value to be the group column.
								for (var attrname in sorting) { groupSort[attrname] = sorting[attrname]; }
								sorting = groupSort;
								items.$params.sorting = groupSort;
							}

							repository.GetPaginated(entityData.url, params.page() - 1, params.count(), sorting, filter, preSelected, JSON.stringify(columns)).then(
								function (result) {
									if (items.newestPromise.id !== $defer.promise.id) {
										$defer.reject('not the most recent promise, ignoring result');
										return;
									}

									items.settings().total = result.TotalCount;
									items.settings().filterDelay = 500;

									if (result.List.length === 0 && result.TotalCount > 0) {
										items.page(1);
									}

									modifyResult(entityData, result.List, columns, filter);

									$defer.resolve(result.List);
									preSelected = null;
								},
								function (error) {
									repository.growl(error, 'danger');
								});
						}
					});

				return items;
			};

			service.getGroupName = function (prefix, obj) {
				if (!service.currentListSetup[prefix].GroupBy) {
					return false;
				}

				var attrs = service.currentListSetup[prefix].GroupBy.split('.');
				attrs.forEach(function (attr) {
					if (!obj) {
						obj = "";
					} else {
						obj = obj[attr];
					}

					if (prefix === 'AreaXCleaningTask' && attr === 'NextCleaningDate') {
						if (!obj) obj = translationService.translate('web-areaXCleaningTask-NextCleaningDate-oneTimeTask', 'Engangsoppgave');
						else {
							var date = new Date(obj);
							date.setHours(0, 0, 0, 0);
							var today = new Date();
							today.setHours(0, 0, 0, 0);
							var tomorrow = new Date(today);
							tomorrow.setDate(tomorrow.getDate() + 1);

							if (date.getTime() === today.getTime())
								obj = translationService.translate('web-areaXCleaningTask-NextCleaningDate-today', 'I dag');
							else if (date.getTime() === tomorrow.getTime())
								obj = translationService.translate('web-areaXCleaningTask-NextCleaningDate-tomorrow', 'I morgen ');
							else if (date.getTime() < today.getTime())
								obj = translationService.translate('web-areaXCleaningTask-NextCleaningDate-expired', 'Utgått');
							else
								obj = $filter('date')(date, 'dd.MM.yyyy');
						}
					}

				});

				return obj;
			};

			var addColumn = function (position, translationId, defaultText, property, propertyType) {
				return { Position: position, Title: translationService.translate(translationId, defaultText), Property: property, PropertyType: propertyType };
			};

			var getStandardViewInternal = function (prefix, filters, contextId) {
				switch (prefix) {
					case repository.apiData.equipment.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-building-id'), Property: 'Building.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidBuilding', AutocompleteService: 'building' },
								{ Position: 2, Title: translationService.translate('web-building-description'), Property: 'Building.Description', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidBuilding', AutocompleteService: 'building' },
								{ Position: 3, Title: translationService.translate('web-equipment-id'), Property: 'Id', PropertyType: 'string' },
								{ Position: 4, Title: translationService.translate('web-equipment-description'), Property: 'Description', PropertyType: 'string' },
								{ Position: 5, Title: translationService.translate('web-equipmentcategory-description'), Property: 'EquipmentCategory.Description', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidEquipmentCategory', AutocompleteService: 'equipmentCategories' },
								{ Position: 6, Title: translationService.translate('web-equipment-location'), Property: 'Location', PropertyType: 'string' },
								{ Position: 7, Title: translationService.translate('web-equipment-manufacturer'), Property: 'Manufacturer', PropertyType: 'string' },
								{ Position: 8, Title: translationService.translate('web-area-id'), Property: 'Area.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidArea', AutocompleteService: 'area' },
								{ Position: 9, Title: translationService.translate('web-area-description'), Property: 'Area.Description', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidArea', AutocompleteService: 'area' },
								{ Position: 10, Title: translationService.translate('web-supplier-description'), Property: 'Supplier.Description', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidSupplier', AutocompleteService: 'supplier' }
							]
						};
					case repository.apiData.building.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-building-id'), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-building-description'), Property: 'Description', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-buildingcategory-description'), Property: 'BuildingCategory.Description', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidBuildingCategory', AutocompleteService: 'buildingCategory' },
								{ Position: 4, Title: translationService.translate('web-building-address'), Property: 'Address', PropertyType: 'string' },
								{ Position: 5, Title: translationService.translate('web-building-postalcode'), Property: 'PostalCode', PropertyType: 'number' },
								{ Position: 6, Title: translationService.translate('web-building-postalarea'), Property: 'PostalArea', PropertyType: 'string' },
							]
						};
					case repository.apiData.buildingPerson.prefix:
						return {
							Sorting: { 'PersonRole.Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-personRole-id'), Property: 'PersonRole.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidPersonRole', AutocompleteService: 'personRole' },
								{ Position: 2, Title: translationService.translate('web-person-FirstName'), Property: 'Person.FirstName', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidPerson', AutocompleteService: 'person' },
								{ Position: 3, Title: translationService.translate('web-person-LastName'), Property: 'Person.LastName', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidPerson', AutocompleteService: 'person' },
								{ Position: 4, Title: translationService.translate('web-person-telephoneWork'), Property: 'Person.TelephoneWork', PropertyType: 'string' },
								{ Position: 4, Title: translationService.translate('web-person-cellphone'), Property: 'Person.Cellphone', PropertyType: 'string' },
								{ Position: 5, Title: translationService.translate('web-person-email'), Property: 'Person.Email', PropertyType: 'string' },
							]
						};
					case repository.apiData.document.prefix:
						return {
							Sorting: { CreationDate: 'desc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-document-id'), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-document-description'), Property: 'Description', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-documentcategory-id', 'Dokumentkategori'), Property: 'DocumentCategory.Description', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidDocumentCategory', AutocompleteService: 'documentCategory' },
								{ Position: 4, Title: translationService.translate('web-document-filename'), Property: 'FilePath', PropertyType: 'string' },
								{ Position: 5, Title: translationService.translate('web-document-creationdate', 'Opprettet dato'), Property: 'CreationDate', PropertyType: 'date' },
							]
						};
					case repository.apiData.documentXEntity.prefix:
						return {
							Sorting: { CreationDate: 'desc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-document-id'), Property: 'Document.Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-document-description'), Property: 'Document.Description', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-documentcategory-id', 'Dokumentkategori'), Property: 'Document.DocumentCategory.Description', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidDocumentCategory', AutocompleteService: 'documentCategory' },
								{ Position: 4, Title: translationService.translate('web-documentXentity-entity', 'Prefix'), Property: 'EntityType', PropertyType: 'enum.PreFix' },
								{ Position: 5, Title: translationService.translate('web-document-creationdate', 'Opprettet dato'), Property: 'CreationDate', PropertyType: 'date' },
							]
						};
					case repository.apiData.estate.prefix:
						return {
							Sorting: { Id: 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-estate-id'), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-estate-description'), Property: 'Description', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-estatecategory-description'), Property: 'EstateCategory.Description', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidEstateCategory', AutocompleteService: 'estateCategory', },
								{ Position: 4, Title: translationService.translate('web-estate-address'), Property: 'Address', PropertyType: 'string' },
								{ Position: 5, Title: translationService.translate('web-estate-postalcode'), Property: 'PostalCode', PropertyType: 'number' },
								{ Position: 6, Title: translationService.translate('web-estate-postalarea'), Property: 'PostalArea', PropertyType: 'string' },
							]
						};
					case repository.apiData.component.prefix:
						return {
							Sorting: { Id: 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-component-id'), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-component-description'), Property: 'Description', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-componentcategory-description'), Property: 'ComponentCategory.Description', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidComponentCategory', AutocompleteService: 'componentCategory' },
								{ Position: 4, Title: translationService.translate('web-component-unit'), Property: 'Unit', PropertyType: 'string' },
								{ Position: 5, Title: translationService.translate('web-component-updateddate', 'Oppdatert dato'), Property: 'UpdatedDate', PropertyType: 'date' },
							]
						};
					case repository.apiData.supplier.prefix:
						return {
							Sorting: { Id: 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-supplier-id'), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-supplier-description'), Property: 'Description', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-supplier-telephone'), Property: 'Telephone', PropertyType: 'string' },
								{ Position: 4, Title: translationService.translate('web-supplier-email'), Property: 'Email', PropertyType: 'string' },
								{ Position: 5, Title: translationService.translate('web-supplier-postalcode'), Property: 'PostalCode', PropertyType: 'number' },
								{ Position: 6, Title: translationService.translate('web-supplier-postalarea'), Property: 'PostalArea', PropertyType: 'string' },
							]
						};
					case repository.apiData.person.prefix:
						return {
							Sorting: { LastName: 'asc' },
							Columns: [
								//{ Position: 1, Title: translationService.translate('web-person-employeenr'), Property: 'EmployeeNr', PropertyType: 'string' },
								{ Position: 1, Title: translationService.translate('web-person-firstname'), Property: 'FirstName', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-person-lastname'), Property: 'LastName', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-person-email'), Property: 'Email', PropertyType: 'string' },
								{ Position: 4, Title: translationService.translate('web-person-position'), Property: 'Position', PropertyType: 'string' },
								{ Position: 5, Title: translationService.translate('web-person-telephonework'), Property: 'TelephoneWork', PropertyType: 'string' },
								{ Position: 6, Title: translationService.translate('web-person-cellphone'), Property: 'Cellphone', PropertyType: 'string' },
							]
						};
					case repository.apiData.area.prefix:
						var areaColumns = [];

						if (contextId === planiaUtils.contextIds.housing) {
							areaColumns = [
								{ Position: 1, Title: translationService.translate('web-housing-id', 'Leieobjekt nr'), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-area-address', 'Adresse'), Property: 'Address', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-housing-areaCategory', 'Leieobjekt type'), Property: 'AreaCategory.Description', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidAreaCategory', AutocompleteService: 'areaCategory' },
								{ Position: 4, Title: translationService.translate('web-area-RentalStatus', 'Status utleie'), Property: 'RentalStatus', PropertyType: 'enum.arearentalstatus' },
								{ Position: 5, Title: translationService.translate('web-building-postalcode', 'Postnr'), Property: 'Building.PostalCode', PropertyType: 'string' },
								{ Position: 6, Title: translationService.translate('web-building-postalarea', 'Poststed'), Property: 'Building.PostalArea', PropertyType: 'string' },
							];
						} else {
							areaColumns = [
								{ Position: 1, Title: translationService.translate('web-building-id', 'Byggnr'), Property: 'Building.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidBuilding', AutocompleteService: 'building' },
								{ Position: 2, Title: translationService.translate('web-building-description', 'Bygg'), Property: 'Building.Description', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidBuilding', AutocompleteService: 'building' },
								{ Position: 3, Title: translationService.translate('web-area-id'), Property: 'Id', PropertyType: 'string' },
								{ Position: 4, Title: translationService.translate('web-area-description'), Property: 'Description', PropertyType: 'string' },
								{ Position: 5, Title: translationService.translate('web-areacategory-description'), Property: 'AreaCategory.Description', PropertyType: 'autocomplete', AutocompleteProperty: 'guidAreaCategory', AutocompleteService: 'areaCategory' },
								{ Position: 6, Title: translationService.translate('web-area-computedgrossarea'), Property: 'ComputedGrossArea', PropertyType: 'double' },
								{ Position: 7, Title: translationService.translate('web-area-computednetarea'), Property: 'ComputedNetArea', PropertyType: 'double' },
								{ Position: 8, Title: translationService.translate('web-area-rentalstatus'), Property: 'RentalStatus', PropertyType: 'enum.arearentalstatus' },
							];
						}

						return {
							Sorting: { Id: 'asc' },
							Columns: areaColumns
						};
					case repository.apiData.drawing.prefix:
						return {
							Sorting: { Id: 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-drawing-id'), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-drawing-description'), Property: 'Description', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-drawing-filename'), Property: 'FilePath', PropertyType: 'string' },
							]
						};
					case repository.apiData.workOrder.prefix:
						return {
							Sorting: { Id: 'desc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-workorder-id'), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-workorder-type'), Property: 'Type', PropertyType: 'enum.workordertype' },
								{ Position: 3, Title: translationService.translate('web-building-id'), Property: 'Building.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidBuilding', AutocompleteService: 'building' },
								{ Position: 4, Title: translationService.translate('web-building-description'), Property: 'Building.Description', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidBuilding', AutocompleteService: 'building' },
								{ Position: 5, Title: translationService.translate('web-equipment-description'), Property: 'Equipment.Description', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidEquipment', AutocompleteService: 'equipment' },
								{ Position: 6, Title: translationService.translate('web-workorder-description'), Property: 'Description', PropertyType: 'string' },
								{ Position: 7, Title: translationService.translate('web-workorder-startdate'), Property: 'StartDate', PropertyType: 'date' },
								{ Position: 8, Title: translationService.translate('web-workorder-duedate'), Property: 'DueDate', PropertyType: 'date' },
								{ Position: 9, Title: translationService.translate('web-workorder-enddate'), Property: 'EndDate', PropertyType: 'date' },
							]
						};
					case repository.apiData.deviation.prefix:
						return {
							Sorting: { 'Id': 'desc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-deviation-id'), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-building-id'), Property: 'Building.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidBuilding', AutocompleteService: 'building' },
								{ Position: 3, Title: translationService.translate('web-building-description'), Property: 'Building.Description', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidBuilding', AutocompleteService: 'building' },
								{ Position: 4, Title: translationService.translate('web-equipment-id'), Property: 'Equipment.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidEquipment', AutocompleteService: 'equipment' },
								{ Position: 5, Title: translationService.translate('web-deviation-actionComment'), Property: 'ActionComment', PropertyType: 'string' },
								{ Position: 6, Title: translationService.translate('web-deviation-deadlineDate'), Property: 'DeadlineDate', PropertyType: 'date' },
								{ Position: 7, Title: translationService.translate('web-deviation-status'), Property: 'Status', PropertyType: 'enum.deviationstatus' }
							]
						};

					case repository.apiData.condition.prefix:
						return {
							Sorting: { 'Id': 'desc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-condition-id'), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-building-id'), Property: 'Building.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidBuilding', AutocompleteService: 'building' },
								{ Position: 3, Title: translationService.translate('web-building-description'), Property: 'Building.Description', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidBuilding', AutocompleteService: 'building' },
								{ Position: 4, Title: translationService.translate('web-equipment-id'), Property: 'Equipment.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidEquipment', AutocompleteService: 'equipment' },
								{ Position: 5, Title: translationService.translate('web-condition-actionComment', "Tiltaksbeskrivelse"), Property: 'ActionComment', PropertyType: 'string' },
								{ Position: 6, Title: translationService.translate('web-condition-status', 'Status'), Property: 'Status', PropertyType: 'enum.DeviationStatus', Filter: [{ Property: 'Status', Operator: '<>', Value: 3 }, { Property: 'Status', Operator: '<>', Value: 4 }] },
								{ Position: 7, Title: translationService.translate('web-condition-year', "År"), Property: 'Year', PropertyType: 'number' },
								{ Position: 8, Title: translationService.translate('web-condition-estimate', "Overslag"), Property: 'Estimate', PropertyType: 'double' },
								
							]
						};

					case repository.apiData.request.prefix:
						return {
							Sorting: { Id: 'desc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-request-id', 'Id'), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-building-id'), Property: 'Building.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidBuilding', AutocompleteService: 'building' },
								{ Position: 3, Title: translationService.translate('web-building-description'), Property: 'Building.Description', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidBuilding', AutocompleteService: 'building' },
								{ Position: 4, Title: translationService.translate('web-request-description'), Property: 'Description', PropertyType: 'string' },
								{ Position: 5, Title: translationService.translate('web-request-contactperson'), Property: 'ContactPerson', PropertyType: 'string' },
								{ Position: 6, Title: translationService.translate('web-request-status'), Property: 'Status', PropertyType: 'enum.requeststatus' },
								{ Position: 7, Title: translationService.translate('web-request-duedate'), Property: 'DueDate', PropertyType: 'date' },
								{ Position: 8, Title: translationService.translate('web-request-telephone'), Property: 'Telephone', PropertyType: 'string' },
							]
						};
					case repository.apiData.periodicTask.prefix:
						return {
							Sorting: { Id: 'desc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-periodictask-id'), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-building-id'), Property: 'Building.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidBuilding', AutocompleteService: 'building' },
								{ Position: 3, Title: translationService.translate('web-building-description'), Property: 'Building.Description', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidBuilding', AutocompleteService: 'building' },
								//{ Position: 4, Title: translationService.translate('web-equipment-id'), Property: 'Equipment.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidEquipment', AutocompleteService: 'equipment' },
								{ Position: 5, Title: translationService.translate('web-equipment-description'), Property: 'Equipment.Description', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidEquipment', AutocompleteService: 'equipment' },
								{ Position: 6, Title: translationService.translate('web-periodictask-description'), Property: 'Description', PropertyType: 'string' },
								{ Position: 7, Title: translationService.translate('web-periodictask-interval'), Property: 'Interval', PropertyType: 'string' },
								{ Position: 8, Title: translationService.translate('web-periodictask-period'), Property: 'Period', PropertyType: 'enum.timeunit' },
								{ Position: 9, Title: translationService.translate('web-periodictask-duedate'), Property: 'DueDate', PropertyType: 'date' },
							]
						};
					case repository.apiData.webProfile.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-webprofile-id', 'Id'), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-webprofile-description', 'Beskrivelse'), Property: 'Description', PropertyType: 'string' },
							]
						};
					case repository.apiData.webMenu.prefix:
						return {
							Sorting: { 'Name': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-webmenu-name'), Property: 'Name', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-webmenu-description'), Property: 'Description', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-webmenu-context'), Property: 'Context', PropertyType: 'string' },
								{ Position: 4, Title: translationService.translate('web-webmenu-reference'), Property: 'Reference', PropertyType: 'string' },
							]
						};
					case repository.apiData.webListView.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-webListView-id', 'Id'), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-webListView-EntityType', 'Entitet'), Property: 'Description', PropertyType: 'enum.PreFix' },
								{ Position: 3, Title: translationService.translate('web-webListView-IsPublicView', 'Felles'), Property: 'Description', PropertyType: 'string' },
								{ Position: 4, Title: translationService.translate('web-webListView-ContextId', 'Kontekst'), Property: 'ContextId', PropertyType: 'string' },
								{ Position: 5, Title: translationService.translate('web-webListView-UpdatedDate', 'Oppdatert dato'), Property: 'UpdatedDate', PropertyType: 'date' }
							]
						};
					case repository.apiData.buildingSelection.prefix:
						return {
							Sorting: { 'Description': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-buildingselection-description', 'Beskrivelse'), Property: 'Description', PropertyType: 'string' },
							]
						};
					case repository.apiData.controlList.prefix:
						return {
							Sorting: { 'Id': 'desc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-controlList-id', 'Id'), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-controlList-name', 'Navn'), Property: 'Name', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-controlList-description', 'Beskrivelse'), Property: 'Description', PropertyType: 'string' },
								{ Position: 4, Title: translationService.translate('web-controlList-isMandatory', 'Obligatorisk'), Property: 'IsMandatory', PropertyType: 'bool' },

							]
						};
					case repository.apiData.controlListXEntity.prefix:
						return {
							Sorting: { 'ClosedDate': 'desc' },
							Filter: { PropertyFilter: [{ Property: 'ClosedDate', Operator: '<>', Value: null }] },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-controlList-id', 'Id'), Property: 'ControlList.Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-controlList-name', 'Navn'), Property: 'ControlList.Name', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-controlList-description', 'Beskrivelse'), Property: 'ControlList.Description', PropertyType: 'string' },
								{ Position: 4, Title: translationService.translate('web-workOrder-id', 'Id'), Property: 'WorkOrder.Id', PropertyType: 'string' },
								{ Position: 4, Title: translationService.translate('web-workOrder-description', 'Beskrivelse'), Property: 'WorkOrder.Description', PropertyType: 'string' },
								{ Position: 5, Title: translationService.translate('web-controlListXEntity-ClosedDate', 'Fullført dato'), Property: 'ClosedDate', PropertyType: 'date' },
								{ Position: 6, Title: translationService.translate('web-controlListXEntity-User', 'Fullført av'), Property: 'User.RealName', PropertyType: 'string' },
							]
						};
					case repository.apiData.controlListItemAnswer.prefix:
						return {
							Sorting: { 'ControlListItem.Name': 'asc' },
							Filter: {},
							Columns: [
								{ Position: 1, Title: translationService.translate('web-ControlList-Name', 'Kontrolliste'), Property: 'ControlListXEntity.ControlList.Name', PropertyType: 'string' },
								{ Position: 1, Title: translationService.translate('web-ControlList-Description', 'Beskrivelse'), Property: 'ControlListXEntity.ControlList.Description', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-controlListItem-Name', 'Kontrollpunkt'), Property: 'ControlListItem.Name', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-controlListItemAnswer-Name', 'Svar'), Property: 'Value', PropertyType: 'string' },
								{ Position: 4, Title: translationService.translate('web-workorder-id', 'Ao nr.'), Property: 'ControlListXEntity.WorkOrder.Id', PropertyType: 'string' },
								{ Position: 5, Title: translationService.translate('web-workOrder-description', 'Beskrivelse'), Property: 'ControlListXEntity.WorkOrder.Description', PropertyType: 'string' },
							]
						};
					case repository.apiData.translate.prefix:
						return {
							Sorting: { 'WebText.SystemName': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-translation-SystemName', 'Systemnavn'), Property: 'WebText.SystemName', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-translation-defaultvalue', 'Standard verdi'), Property: 'DefaultTranslatedValue', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-translation-value', 'Verdi'), Property: 'TranslatedValue', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-translation-language', 'Språk'), Property: 'Language.Locale', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-translation-updateddate', 'Oppdatert dato'), Property: 'UpdatedDate', PropertyType: 'date' },

							]
						};
					case repository.apiData.log.prefix:
						return {
							Sorting: { 'CreationDate': 'desc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-log-severity', 'Severity'), Property: 'Severity', PropertyType: 'enum.logseverity' },
								{ Position: 2, Title: translationService.translate('web-log-message', 'Melding'), Property: 'Message', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-log-category', 'Kategori'), Property: 'Category', PropertyType: 'enum.RestrictedLogCategory', Filter: [{ Property: 'Category', Operator: '=', Value: 4 }] },
								{ Position: 4, Title: translationService.translate('web-log-creationdate', 'Opprettet dato'), Property: 'CreationDate', PropertyType: 'date' },
							]
						};
					case repository.apiData.dataOwner.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-dataowner-id', 'Klient ID'), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-dataowner-description', 'Beskrivelse'), Property: 'Description', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-dataowner-updateddate', 'Oppdatert dato'), Property: 'UpdatedDate', PropertyType: 'date' },
							]
						};
					case repository.apiData.webDashboard.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-dashboard-id', 'Id'), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-dashboard-description', 'Beskrivelse'), Property: 'Description', PropertyType: 'string' },
							]
						};
					case repository.apiData.userGroup.prefix:
						return {
							Sorting: { 'Realname': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-user-realname', 'Navn'), Property: 'RealName', PropertyType: 'string' },
							]
						};
					case repository.apiData.user.prefix:
						return {
							Sorting: { 'Username': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-user-username', 'Brukernavn'), Property: 'Username', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-user-realname', 'Navn'), Property: 'RealName', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-user-phonenumber', 'Telefon'), Property: 'PhoneNumber', PropertyType: 'string' },
								{ Position: 4, Title: translationService.translate('web-user-email', 'Epost'), Property: 'Email', PropertyType: 'string' },

							]
						};
					case repository.apiData.userXWebProfile.prefix:
						return {
							Sorting: { 'User.Username': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-userXWebprofile-IndexPosition', 'Rekkefølge'), Property: 'IndexPosition', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-user-username', 'Brukernavn'), Property: 'User.Username', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-webprofile-id', 'Id'), Property: 'WebProfile.Id', PropertyType: 'string' },
								{ Position: 4, Title: translationService.translate('web-webprofile-description', 'Beskrivelse'), Property: 'WebProfile.Description', PropertyType: 'string' },
							]
						};
					case repository.apiData.conditionType.prefix:
						return {
							Sorting: { 'Description': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-conditionType-description', "Tilstand"), Property: 'Description', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-conditionType-IsApproved', "Godkjent"), Property: 'IsApproved', PropertyType: 'bool' },
								{ Position: 3, Title: translationService.translate('web-conditionType-UpdatedDate', "Oppdatert Dato"), Property: 'UpdatedDate', PropertyType: 'date' },
							]
						};
					case repository.apiData.activityGroup.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-activityGroup-description', "Navn"), Property: 'Id', PropertyType: 'string' },
							]
						};

					case repository.apiData.standardText.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-standardText-Id', "Navn"), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-standardText-description', "Beskrivelse"), Property: 'Description', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-standardText-descriptivetext', "Tekst"), Property: 'DescriptiveText', PropertyType: 'string' },
								{ Position: 4, Title: translationService.translate('web-standardText-Oppdatert dato', "Oppdatert Dato"), Property: 'UpdatedDate', PropertyType: 'date' },

							]
						};

					case repository.apiData.deviationType.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-deviationType-Id', "ID"), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-deviationType-Deviation', "Avvik"), Property: 'Description', PropertyType: 'string' },
								{ Position: 1, Title: translationService.translate('web-deviationType-UpdatedDate', "Oppdatert Dato"), Property: 'UpdatedDate', PropertyType: 'date' },

							]
						};

					case repository.apiData.hourType.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-hourType-Id', "ID"), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-hourType-Description', "Beskrivelse"), Property: 'Description', PropertyType: 'string' },
								{ Position: 1, Title: translationService.translate('web-hourType-Price', "Pris"), Property: 'Price', PropertyType: 'double' },

							]
						};

					case repository.apiData.personRole.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-personRole-Id', "ID"), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-personRole-IsFireRelated', "Brann"), Property: 'IsFireRelated', PropertyType: 'bool' },
								{ Position: 3, Title: translationService.translate('web-personRole-IsElectroRelated', "Elektro"), Property: 'IsElectroRelated', PropertyType: 'bool' },
								{ Position: 4, Title: translationService.translate('web-personRole-IsHseRelated', "HMS"), Property: 'IsHseRelated', PropertyType: 'bool' },
								{ Position: 5, Title: translationService.translate('web-personRole-UpdatedDate', "Oppdatert Dato"), Property: 'UpdatedDate', PropertyType: 'date' },

							]
						};
					case repository.apiData.costCenter.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-costCenter-Id', "ID"), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-costcenter-description', "Description"), Property: 'Description', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-costcenter-UpdatedDate', "Oppdatert Dato"), Property: 'UpdatedDate', PropertyType: 'date' },

							]
						};
					case repository.apiData.department.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-department-Id', "ID"), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-department-description', "Description"), Property: 'Description', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-department-UpdatedDate', "Oppdatert Dato"), Property: 'UpdatedDate', PropertyType: 'date' },
							]
						};

					case repository.apiData.account.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-account-Id', "ID"), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-account-description', "Description"), Property: 'Description', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-account-UpdatedDate', "Oppdatert Dato"), Property: 'UpdatedDate', PropertyType: 'date' },
							]
						};
					case repository.apiData.entityPermissionProfile.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-entityPermissionprofile-Id', "ID"), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-entityPermissionprofile-description', "Description"), Property: 'Description', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-entityPermissionprofile-UpdatedDate', "Oppdatert Dato"), Property: 'UpdatedDate', PropertyType: 'date' },
							]
						};
					case repository.apiData.areaXCleaningTask.prefix:
						return {
							Sorting: { 'Area.Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-cleaning-id'), Property: 'Area.Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-cleaning-description', 'Romnavn'), Property: 'Area.Description', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-cleaning-cleaning', 'Renhold'), Property: 'CleaningTask.Id', PropertyType: 'string' },
								{ Position: 4, Title: translationService.translate('web-cleaning-cleaner', 'Renholder'), Property: 'Cleaner', PropertyType: 'string' },
								{ Position: 5, Title: translationService.translate('web-cleaning-team', 'Team'), Property: 'ResourceGroup.Id', PropertyType: 'string' },
								{ Position: 6, Title: translationService.translate('web-cleaning-nextCleaningDate', 'Utføres'), Property: 'NextCleaningDate', PropertyType: 'string' },
								{ Position: 7, Title: translationService.translate('web-cleaning-lastCompletedDate', 'Sist utført'), Property: 'LastCleanedDate', PropertyType: 'date' },
								{ Position: 8, Title: translationService.translate('web-cleaning-lastcompletedby', 'Sist utført av'), Property: 'LastCleanedBy', PropertyType: 'string' },
							]
						};
					case repository.apiData.cleaningQuality.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-cleaningQuality-Id', "ID"), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-cleaningQuality-description', "Beskrivelse"), Property: 'Description', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-cleaningQuality-note', "Kommentar"), Property: 'Note', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-cleaningQuality-UpdatedDate', "Oppdatert Dato"), Property: 'UpdatedDate', PropertyType: 'date' },
							]
						};
					case repository.apiData.cleaningQualityControl.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-cleaningQualityControl-Id', "ID"), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-cleaningQualityControl-description', "Beskrivelse"), Property: 'Description', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-cleaningQualityControl-Status', 'Status'), Property: 'Status', PropertyType: 'string', SortingDisabled: true },
								{ Position: 4, Title: translationService.translate('web-cleaningQualityControl-PercentComplete', 'Fremdrift'), Property: 'PercentComplete', PropertyType: 'progress', SortingDisabled: true },
								{ Position: 5, Title: translationService.translate('web-cleaningQualityControl-UpdatedDate', "Oppdatert Dato"), Property: 'UpdatedDate', PropertyType: 'date' },
							]
						};
					case repository.apiData.cleaningTask.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-cleaningTask-Id', "Navn"), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-cleaningTask-estimatedTime', "Renholdsnormtall"), Property: 'EstimatedTime', PropertyType: 'number' },
								{ Position: 3, Title: translationService.translate('web-cleaningTask-estimatedCost', "Estimert kost"), Property: 'EstimatedCost', PropertyType: 'double' },
								{ Position: 4, Title: translationService.translate('web-cleaningTask-UpdatedDate', "Oppdatert Dato"), Property: 'UpdatedDate', PropertyType: 'date' },
							]
						};
					case repository.apiData.cleaningCompletion.prefix:
						return {
							Sorting: { 'CompletionDate': 'desc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-cleaningCompletion-id', 'Romnummer'), Property: 'Area.Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-cleaningCompletion-description', 'Romnavn'), Property: 'Area.Description', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-cleaningCompletion-cleaning', 'Renhold'), Property: 'CleaningTask.Id', PropertyType: 'string' },
								{ Position: 4, Title: translationService.translate('web-cleaningCompletion-CompletionStatus', 'Status'), Property: 'CompletionStatus', PropertyType: 'enum.completionStatus' },
								{ Position: 5, Title: translationService.translate('web-cleaningCompletion-CompletionDate', 'Fullført dato'), Property: 'CompletionDate', PropertyType: 'date' },
							]
						};
					case repository.apiData.priority.prefix:
						return {
							Sorting: { 'Description': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-priority-Description', "Navn"), Property: 'Description', PropertyType: 'string' },
								{ Position: 1, Title: translationService.translate('web-priority-EntityType', "Entitetstype"), Property: 'EntityType', PropertyType: 'enum.prefix' },
								{ Position: 2, Title: translationService.translate('web-priority-UpdatedDate', "Oppdatert Dato"), Property: 'UpdatedDate', PropertyType: 'date' },
							]
						};
					case repository.apiData.customer.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-customer-Id', "Id"), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-customer-Description', "Beskrivelse"), Property: 'Description', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-customer-Telephone', "Telefon"), Property: 'Telephone', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-customer-Email', "Epost"), Property: 'Email', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-customer-StreetAddress', "Addresse"), Property: 'StreetAddress', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-customer-UpdatedDate', "Oppdatert Dato"), Property: 'UpdatedDate', PropertyType: 'date' },
							]
						};

					case repository.apiData.contract.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-contract-Id', "Id"), Property: 'Id', PropertyType: 'string' },
								{ Position: 1, Title: translationService.translate('web-contract-Description', "Beskrivelse"), Property: 'Description', PropertyType: 'string' },

							]
						};
					case repository.apiData.invoicingTerm.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-invoicing-term-Id', "Id"), Property: 'Id', PropertyType: 'string' },
							]
						};

					case repository.apiData.paymentTerm.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-payment-term-Id', "Id"), Property: 'Id', PropertyType: 'string' },
								{ Position: 1, Title: translationService.translate('web-payment-term-Days', "Days"), Property: 'Days', PropertyType: 'number' },

							]
						};

					case repository.apiData.paymentOrderForm.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-payment-order-form-Id', "Id"), Property: 'Id', PropertyType: 'string' },
							]
						};

					case repository.apiData.contractType.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-contract-type-Id', "Id"), Property: 'Id', PropertyType: 'string' },
							]
						};

					case repository.apiData.projectCategory.prefix:
						return {
							Sorting: { 'Description': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-projectCategory-Description', "Beskrivelse"), Property: 'Description', PropertyType: 'string' },
							]
						};
					case repository.apiData.projectType.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-priority-Id', "Navn"), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-priority-UpdatedDate', "Oppdatert Dato"), Property: 'UpdatedDate', PropertyType: 'date' },
							]
						};
					case repository.apiData.consequenceType.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-consequenceType-Id', "Navn"), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-consequenceType-UpdatedDate', "Oppdatert Dato"), Property: 'UpdatedDate', PropertyType: 'date' },
							]
						};
					case repository.apiData.consequence.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-consequence-Id', "Navn"), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-consequence-UpdatedDate', "Oppdatert Dato"), Property: 'UpdatedDate', PropertyType: 'date' },
							]
						};
					case repository.apiData.cylinderType.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-cylinderType-Id', "Navn"), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-cylinderType-UpdatedDate', "Oppdatert Dato"), Property: 'UpdatedDate', PropertyType: 'date' },
							]
						};
					case repository.apiData.article.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-article-Id', "Id"), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-article-Description', "Beskrivelse"), Property: 'Description', PropertyType: 'string' },
							]
						};
					case repository.apiData.resourceGroup.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-resourceGroup-Id', "Id"), Property: 'Id', PropertyType: 'string' },
								{ Position: 1, Title: translationService.translate('web-resourceGroup-Description', "Beskrivelse"), Property: 'Description', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-resourceGroup-CostPrHour', "Timerate"), Property: 'CostPrHour', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-resourceGroup-updateDate', "Oppdatert dato"), Property: 'UpdatedDate', PropertyType: 'date' },
							]
						};
					case repository.apiData.namedSelection.prefix:
						return {
							Sorting: { 'Value': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-namedSelection-value', "Verdi"), Property: 'Value', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-namedSelection-updatedDate', "Oppdatert dato"), Property: 'UpdatedDate', PropertyType: 'date' },
							]
						};
					case repository.apiData.alarmLog.prefix:
						return {
							Sorting: { 'CreationDate': 'desc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-alarmLog-EmailFrom', "Fra"), Property: 'EmailFrom', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-alarmLog-EmailTo', "Til"), Property: 'EmailTo', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-alarmLog-EmailCopyTo', "Kopi til"), Property: 'EmailCopyTo', PropertyType: 'string' },
								{ Position: 4, Title: translationService.translate('web-alarmLog-EmailSubject', "Emne"), Property: 'EmailSubject', PropertyType: 'string' },
								{ Position: 5, Title: translationService.translate('web-alarmLog-Status', "Status"), Property: 'Status', PropertyType: 'enum.alarmstatus' },
								{ Position: 6, Title: translationService.translate('web-alarmLog-SentDate', "Dato sendt"), Property: 'SentDate', PropertyType: 'date' },
								{ Position: 7, Title: translationService.translate('web-alarmLog-CreationDate', "Dato opprettet"), Property: 'CreationDate', PropertyType: 'date' }
							]
						};
					case repository.apiData.purchaseOrder.prefix:
						return {
							Sorting: { 'Id': 'desc' },
							Columns: [
								addColumn(1, 'web-purchaseOrder-Id', "Id", 'Id', 'string'),
								addColumn(2, 'web-purchaseOrder-Description', "Beskrivelse", 'Description', 'string'),
								addColumn(3, 'web-supplier-Description', "Leverandør", 'Supplier.Description', 'string'),
								addColumn(4, 'web-purchaseOrder-Status', "Status", 'Status', 'enum.purchaseorderstatus'),
								addColumn(4, 'web-purchaseOrder-Amount', "Beløp", 'Amount', 'double'),
								addColumn(5, 'web-purchaseOrder-OrderingDate', "Bestilt dato", 'OrderingDate', 'date'),
								addColumn(6, 'web-purchaseOrder-DeliveryDate', "Leveringsdato", 'DeliveryDate', 'date'),
								addColumn(7, 'web-purchaseOrder-OurReference', "Vår ref.", 'OurReference', 'string'),
							]
						};
					case repository.apiData.purchaseOrderItem.prefix:
						return {
							Sorting: { 'Id': 'desc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-purchaseOrder-Id'), Property: 'PurchaseOrder.Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-purchaseOrderItem-Id'), Property: 'Id', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-purchaseOrderItem-Description'), Property: 'Description', PropertyType: 'string' },
								{ Position: 4, Title: translationService.translate('web-supplier-Description', 'Leverandør navn'), Property: 'Supplier.Description', PropertyType: 'string' },
								{ Position: 5, Title: translationService.translate('web-purchaseOrderItem-OrderedQuantity', 'Bestilt antall'), Property: 'OrderedQuantity', PropertyType: 'number' },
								{ Position: 6, Title: translationService.translate('web-purchaseOrderItem-UnitPrice', 'Enhetspris'), Property: 'UnitPrice', PropertyType: 'double' },
								{ Position: 7, Title: translationService.translate('web-purchaseOrderItem-OrderedAmount', 'Bestilt beløp'), Property: 'OrderedAmount', PropertyType: 'double' },
								{ Position: 8, Title: translationService.translate('web-purchaseOrderItem-Status', 'Status'), Property: 'Status', PropertyType: 'enum.PurchaseOrderItemStatus' }
							]
						};
					case repository.apiData.purchaseOrderForm.prefix:
						return {
							Sorting: { 'Description': 'asc' },
							Columns: [
								addColumn(1, 'web-purchaseOrderForm-Description', "Beskrivelse", 'Description', 'string'),
								addColumn(2, 'web-purchaseOrderForm-FilePath', "Filsti", 'FilePath', 'string'),
								addColumn(3, 'web-purchaseOrderForm-WebReport', "Web rapport", 'Report.Name', 'string'),
							]
						};
					case repository.apiData.cost.prefix:
						return {
							Sorting: { 'InvoiceNumber': 'desc' },
							Columns: [
								addColumn(1, 'web-cost-InvoiceNumber', "Fakturanr.", 'InvoiceNumber', 'string'),
								addColumn(2, 'web-account-id', "Konto", 'Account.Id', 'string'),
								addColumn(3, 'web-account-description', "Beskrivelse", 'Account.Description', 'string'),
								addColumn(4, 'web-cost-amount', "Beløp", 'Amount', 'double'),
								addColumn(5, 'web-cost-InvoiceDate', "Fakturadato", 'InvoiceDate', 'date'),
								addColumn(6, 'web-supplier-description', "Leverandør navn", 'Supplier.Description', 'string'),
								addColumn(7, 'web-cost-vouchernumber', "Bilagsnr", 'VoucherNumber', 'string'),
							]
						};
					case repository.apiData.budget.prefix:
						return {
							Sorting: { 'Year': 'desc' },
							Columns: [
								addColumn(1, 'web-budget-Year', "År", 'Year', 'number'),
								addColumn(2, 'web-budget-Account', "Konto", 'Account.Id', 'string'),
								addColumn(3, 'web-budget-AccountDescription', "Konto Beskrivelse", 'Account.Description', 'string'),
								addColumn(4, 'web-budget-CostCenter', "Koststed Id", 'CostCenter.Id', 'string'),
								addColumn(5, 'web-budget-CostCenterDescription', "Koststed", 'CostCenter.Description', 'string'),
								addColumn(6, 'web-budget-Department', "Avdeling", 'Department.Id', 'string'),
								addColumn(7, 'web-budget-Budget', "Sum budjsett", 'SumBudget', 'double'),
								addColumn(8, 'web-budget-SumCost', "Sum kostnader", 'SumCost', 'double'),
								addColumn(9, 'web-budget-SumDisposal', "Til disposisjon", 'SumForDisposal', 'double'),
								//addColumn(10, 'web-budget-SumSpesified', "Sum spesifisert", 'SumRestSpecified', 'nummer'),
								//addColumn(11, 'web-budget-SumNotSpesified', "Sum uspesifisert", 'SumRestNotSpecified', 'nummer'),

							]
						};
					case repository.apiData.documentCategory.prefix:
						return {
							Sorting: { 'Description': 'asc' },
							Columns: [
								addColumn(1, 'web-documentCategory-Description', "Kategori dokument", 'Description', 'string'),
								addColumn(2, 'web-documentCategory-updateDate', "Oppdatert dato", 'UpdatedDate', 'date'),
							]
						};
					case repository.apiData.documentType.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								addColumn(1, 'web-documentType-Id', "Dok. type", 'Id', 'string'),
								addColumn(2, 'web-documentType-updateDate', "Oppdatert dato", 'UpdatedDate', 'date'),
							]
						};
					case repository.apiData.region.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								addColumn(1, 'web-region-Id', "Region", 'Id', 'string'),
								addColumn(2, 'web-region-updateDate', "Oppdatert dato", 'UpdatedDate', 'date'),
							]
						};

					case repository.apiData.equipmentOperatingHours.prefix:
						return {
							Sorting: { 'ReadDate': 'desc' },
							Columns: [
								addColumn(1, 'web-equipmentOperatingHours-equipment-Id', "Anleggs id", 'Equipment.Id', 'string'),
								addColumn(1, 'web-equipmentOperatingHours-equipment-Id', "Anleggs beskrivelse", 'Equipment.Description', 'string'),
								addColumn(1, 'web-equipmentOperatingHours-equipmentOperatingHourType-Id', "Id", 'EquipmentOperatingHourType.Id', 'string'),
								addColumn(2, 'web-equipmentOperatingHours-Value', "Verdi", 'Value', 'double'),
								addColumn(2, 'web-equipmentOperatingHours-ReadDate', "Avlest dato", 'ReadDate', 'date'),
							]
						};
					case repository.apiData.equipmentOperatingHourType.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								addColumn(1, 'web-equipmentOperatingHourType-Id', "Id", 'Id', 'string'),
								addColumn(2, 'web-equipmentOperatingHourType-updateDate', "Oppdatert dato", 'UpdatedDate', 'date'),
							]
						};
					case repository.apiData.customerGroup.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								addColumn(1, 'web-customerGroup-Id', "Kundegruppe", 'Id', 'string'),
								addColumn(2, 'web-customerGroup-updateDate', "Oppdatert dato", 'UpdatedDate', 'date'),
							]
						};
					case repository.apiData.customerCategory.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								addColumn(1, 'web-customerCategory-Id', "Kategori", 'Id', 'string'),
								addColumn(2, 'web-customerCategory-updateDate', "Oppdatert dato", 'UpdatedDate', 'date'),
							]
						};

					case repository.apiData.contractCategory.prefix:
						return {
							Sorting: { 'Description': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-contractCategory-Description', "Beskrivelse"), Property: 'Description', PropertyType: 'string' },
							]
						};
					case repository.apiData.customerLineOfBusiness.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								addColumn(1, 'web-customerLineOfBusiness-Id', "Kategori", 'Id', 'string'),
								addColumn(2, 'web-customerLineOfBusiness-updateDate', "Oppdatert dato", 'UpdatedDate', 'date'),
							]
						};
					case repository.apiData.sparePart.prefix:
						return {
							Sorting: { 'CreationDate': 'asc' },
							Columns: [
								addColumn(1, 'web-ComponentXSupplier-PartNumber', "Delenummer", 'ComponentXSupplier.PartNumber', 'string'),
								addColumn(2, 'web-Component-Id', "Id", 'Component.Id', 'string'),
								addColumn(3, 'web-Component-Description', "Navn", 'Component.Description', 'string'),
								addColumn(4, 'web-ComponentXSupplier-Manufacturer', "Fabrikat", 'ComponentXSupplier.Manufacturer', 'string'),
								addColumn(5, 'web-sparePart-Inventory', "Beholdning", 'Inventory', 'number'),
								addColumn(6, 'web-sparePart-Location', "Plassering", 'Location', 'string'),
								addColumn(7, 'web-Supplier-SupplierName', "Leverandørnavn", 'ComponentXSupplier.Supplier.Description', 'string'),
							]
						};
					case repository.apiData.mobileMenuProfile.prefix:
						return {
							Sorting: { 'Id': 'desc' },
							Columns: [
								addColumn(1, 'web-mobileMenuProfile-Id', "Id", 'Id', 'string'),
								addColumn(2, 'web-mobileMenuProfile-Description', "Beskrivelse", 'Description', 'string'),
							]
						};
					case repository.apiData.sparePartCounting.prefix:
						return {
							Sorting: { 'Id': 'desc' },
							Columns: [
								addColumn(1, 'web-sparePartCounting-Id', "Id", 'Id', 'string'),
								addColumn(2, 'web-sparePartCounting-Description', "Beskrivelse", 'Description', 'string'),
								addColumn(3, 'web-sparePartCounting-StartDate', "Start dato", 'StartDate', 'date'),
								addColumn(4, 'web-sparePartCounting-EndDate', "Fullført dato", 'EndDate', 'datetime'),
							]
						};
					case repository.apiData.sparePartCountingItem.prefix:
						return {
							Sorting: { 'CountedDate': 'desc' },
							Filter: { PropertyFilter: [{ Property: 'QuantityDeviation', Operator: '<>', Value: '0' }] },
							Columns: [
								addColumn(1, 'web-sparePartCountingItem-SparepartId', "Id", 'SparePart.Component.Id', 'string'),
								addColumn(2, 'web-sparePartCountingItem-SparepartDescription', "Beskrivelse", 'SparePart.Component.Description', 'string'),
								addColumn(3, 'web-sparePartCountingItem-CountedDate', "Telledato", 'CountedDate', 'datetime'),
								addColumn(4, 'web-sparePartCountingItem-UserCountedBy', "Talt av", 'UserCountedBy.RealName', 'string'),
								addColumn(5, 'web-sparePartCountingItem-RecordQuantity', "Forventet antall", 'RecordQuantity', 'string'),
								addColumn(5, 'web-sparePartCountingItem-PhysicalQuantity', "Talt antall", 'PhysicalQuantity', 'string'),
								addColumn(5, 'web-sparePartCountingItem-QuantityDeviation', "Avvik", 'QuantityDeviation', 'string'),
							]
						};
					case repository.apiData.sparePartWithdrawal.prefix:
						return {
							Sorting: { 'CreationDate': 'desc' },
							Columns: [
								addColumn(1, 'web-sparePart-component-Id', "Komponent Id", 'SparePart.Component.Id', 'string'),
								addColumn(2, 'web-sparePart-component-Description', "Komponentbeskrivelse", 'SparePart.Component.Description', 'string'),
								addColumn(3, 'web-sparePartWithdrawal-equipment-Id', "Anlegg Id", 'Equipment.Id', 'string'),
								addColumn(4, 'web-sparePartWithdrawal-equipment-Description', "Anleggs navn", 'Equipment.Description', 'string'),
								addColumn(5, 'web-sparePartWithdrawal-workorder-Id', "AO nr", 'WorkOrderXSparePart.WorkOrder.Id', 'string'),
								addColumn(6, 'web-sparePartWithdrawal-Quantity', "Antall tatt ut", 'Quantity', 'number'),
								addColumn(7, 'web-sparePartWithdrawal-Price', "Pris", 'WorkOrderXSparePart.Price', 'number'),
								addColumn(8, 'web-sparePartWithdrawal-WithdrawalDate', "Uttaks dato", 'WithdrawalDate', 'date'),
								addColumn(9, 'web-sparePartWithdrawal-User1', "Tatt ut av", 'User1.RealName', 'string'),
							]
						};

					case repository.apiData.cause.prefix:
						return {
							Sorting: { 'Id': 'desc' },
							Columns: [
								addColumn(1, 'web-cause-Id', "Årsak", 'Id', 'string'),
								addColumn(2, 'web-cause-creationDate', "Opprettet dato", 'CreationDate', 'date'),
							]
						};

					case repository.apiData.ifcFile.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-ifcfile-Id', "Id"), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-ifcfile-Description', "Beskrivelse"), Property: 'Description', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-ifcfile-building-id', "Byggnr."), Property: 'Building.Id', PropertyType: 'string' },
								{ Position: 4, Title: translationService.translate('web-ifcfile-building-description', "Byggnavn"), Property: 'Building.Description', PropertyType: 'string' },
							]
						};

					case repository.apiData.operationalMessage.prefix:
						return {
							Sorting: { 'DueDate': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-operationalMessage-Message', 'Melding'), Property: 'Message', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-operationalMessage-StartDate', 'Startdato'), Property: 'StartDate', PropertyType: 'date' },
								{ Position: 3, Title: translationService.translate('web-operationalMessage-Duedate', 'Fristdato'), Property: 'DueDate', PropertyType: 'date' },
								{ Position: 4, Title: translationService.translate('web-operationalMessage-person', 'Kontaktperson'), Property: 'Person.FirstName', PropertyType: 'string' },
							]
						};

					case repository.apiData.apiClient.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								addColumn(1, 'web-apiClient-Id', 'Id', 'Id', 'string'),
								addColumn(2, 'web-apiClient-Description', 'Navn', 'Description', 'string'),
								addColumn(3, 'web-apiClient-RefreshTokenLifetime', 'Utløpstid refresh token (minutt)', 'RefreshTokenLifetime', 'number'),
								addColumn(4, 'web-apiClient-AccessTokenLifetime', 'Utløpstid access token (minutt)', 'AccessTokenLifetime', 'number'),
								addColumn(5, 'web-apiClient-updatedDate', 'Oppdatert dato', 'UpdatedDate', 'date'),
							]
						};

					case repository.apiData.apiRequestLog.prefix:
						return {
							Sorting: { 'EntityTask.Id': 'asc' },
							Columns: [
								addColumn(1, 'web-apiRequestLog-entityTask-Id', 'Id', 'EntityTask.Id', 'string'),
								addColumn(2, 'web-apiRequestLog-entityTask-description', 'Navn', 'EntityTask.Description', 'string'),
								addColumn(3, 'web-apiClient-creationDate', 'Opprettet dato', 'CreationDate', 'date'),
								addColumn(4, 'web-apiClient-user-realName', 'Opprettet av', 'User.RealName', 'string'),
							]
						};

					case repository.apiData.scheduledJob.prefix:
						return {
							Sorting: { 'Type': 'asc', 'Id': 'asc' },
							Columns: [
								addColumn(1, 'web-scheduledJob-Description', 'Navn', 'Description', 'string'),
								addColumn(2, 'web-scheduledJob-JobType', 'Type', 'Type', 'enum.ScheduledJobType'),
								addColumn(3, 'web-scheduledJob-IsActive', 'Aktiv', 'IsActive', 'bool')
							]
						};

					case repository.apiData.emailTemplate.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-emailTemplate-Id', "Id"), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-emailTemplate-EntityType', 'Entitet'), Property: 'EntityType', PropertyType: 'enum.prefix' },
								{ Position: 3, Title: translationService.translate('web-emailTemplate-IsDefault', "Standard"), Property: 'IsDefault', PropertyType: 'bool' },
								{ Position: 4, Title: translationService.translate('web-emailTemplate-CreationDate', "Opprettet dato"), Property: 'CreationDate', PropertyType: 'date' },
							]
						};

					case repository.apiData.project.prefix:
						var projectColumns = [];

						if (contextId === planiaUtils.contextIds.projectTemplate) {
							projectColumns = [
								{ Position: 1, Title: translationService.translate('web-project-Id', "Prosjektnummer"), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-project-Description', 'Prosjektnavn'), Property: 'Description', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-project-Person1-FirstName', "Prosjektleder Fornavn"), Property: 'Person1.FirstName', PropertyType: 'string' },
								{ Position: 4, Title: translationService.translate('web-project-Person1-LastName', "Prosjektleder Etternavn"), Property: 'Person1.LastName', PropertyType: 'string' },
								{ Position: 5, Title: translationService.translate('web-project-CreationDate', "Opprettet dato"), Property: 'CreationDate', PropertyType: 'date' }
							];
						} else {
							projectColumns = [
								{ Position: 1, Title: translationService.translate('web-project-Id', "Prosjektnummer"), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-project-Description', 'Prosjektnavn'), Property: 'Description', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-project-building-id', "Byggnr"), Property: 'Building.Id', PropertyType: 'string' },
								{ Position: 4, Title: translationService.translate('web-project-building-description', "Byggnavn"), Property: 'Building.Description', PropertyType: 'string' },
								{ Position: 5, Title: translationService.translate('web-project-phase', "Fase"), Property: 'ProjectStatus.ProjectPhase.Id', PropertyType: 'string' },
								{ Position: 6, Title: translationService.translate('web-project-status', "Status"), Property: 'ProjectStatus.Id', PropertyType: 'string' },
								{ Position: 7, Title: translationService.translate('web-project-status-stage', "Stage"), Property: 'ProjectStatus.Stage', PropertyType: 'string' },
								{ Position: 8, Title: translationService.translate('web-project-Person1-FirstName', "Prosjektleder Fornavn"), Property: 'Person1.FirstName', PropertyType: 'string' },
								{ Position: 9, Title: translationService.translate('web-project-Person1-LastName', "Prosjektleder Etternavn"), Property: 'Person1.LastName', PropertyType: 'string' }
							];
						}

						return {
							Sorting: { 'Id': 'desc' },
							Columns: projectColumns
						};

					case repository.apiData.report.prefix:
						return {
							Sorting: { 'Id': 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-report-Id', "Id"), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-report-Type', 'Type'), Property: 'ReportType', PropertyType: 'enum.ReportType' },
								{ Position: 3, Title: translationService.translate('web-report-Name', 'Navn'), Property: 'Name', PropertyType: 'string' },
								{ Position: 4, Title: translationService.translate('web-report-Description', "Beskrivelse"), Property: 'Description', PropertyType: 'string' },
								{ Position: 5, Title: translationService.translate('web-report-IsDeactivated', "Deaktivert"), Property: 'IsDeactivated', PropertyType: 'bool' },
							]
						};

					case repository.apiData.entityLink.prefix:
						return {
							Sorting: { EntityType: 'asc', IndexPosition: 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-entityLink-EntityType', "Entitetstype"), Property: 'EntityType', PropertyType: 'enum.prefix' },
								{ Position: 2, Title: translationService.translate('web-entityLink-IndexPosition', 'Rekkefølge'), Property: 'IndexPosition', PropertyType: 'number' },
								{ Position: 3, Title: translationService.translate('web-entityLink-name', "Navn"), Property: 'Name', PropertyType: 'string' },
								{ Position: 4, Title: translationService.translate('web-entityLink-url', "Url"), Property: 'Url', PropertyType: 'string' }
							]
						};

					case repository.apiData.entityTask.prefix:
						return {
							Sorting: { Id: 'asc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-entityTask-id', "Id"), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-entityTask-description', 'Beskrivelse'), Property: 'Description', PropertyType: 'string' },
							]
						};

					case repository.apiData.integrationData.prefix:
						return {
							Sorting: { CreationDate: 'desc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-integrationData-Id', "Id"), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-integrationData-JobId', 'Job id'), Property: 'JobId', PropertyType: 'enum.IntegrationDataJobId' },
								{ Position: 3, Title: translationService.translate('web-IntegrationData-Status', "Status"), Property: 'Status', PropertyType: 'enum.IntegrationDataStatus' },
								{ Position: 4, Title: translationService.translate('web-IntegrationData-FileName', "Filnavn"), Property: 'FileName', PropertyType: 'string' },
								{ Position: 5, Title: translationService.translate('web-integrationdata-message', "Melding"), Property: 'Message', PropertyType: 'string' },
								{ Position: 6, Title: translationService.translate('web-integrationData-ProcessedDate', "Prosessert dato"), Property: 'ProcessedDate', PropertyType: 'datetime' },
								{ Position: 7, Title: translationService.translate('web-integrationData-CreationDate', "Opprettet dato"), Property: 'CreationDate', PropertyType: 'datetime' }
							]
						};

					case repository.apiData.webUserToken.prefix:
						return {
							Sorting: { "CreationDate": 'desc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-webUserToken-User-RealName', "Navn"), Property: 'User.RealName', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-webUserToken-User-Username', "Brukernavn"), Property: 'User.Username', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-webUserToken-ApiClient-ID', "Klient ID"), Property: 'ApiClient.Id', PropertyType: 'string' },
								{ Position: 4, Title: translationService.translate('web-webUserToken-CreationDate', 'Første innlogging'), Property: 'CreationDate', PropertyType: 'date' },
								{ Position: 5, Title: translationService.translate('web-webUserToken-RefreshTokenExpirationDate', 'Utgår'), Property: 'RefreshTokenExpirationDate', PropertyType: 'datetime' },
								{ Position: 6, Title: translationService.translate('web-webUserToken-LogoutReason', 'Kastet ut grunnlag'), Property: 'LogoutReason', PropertyType: 'enum.LogoutReason', Filter: [{ Property: 'LogoutReason', Operator: '<', Value: 1 }] },
								{ Position: 7, Title: translationService.translate('web-webUserToken-Fingerprint', 'Fingeravtrykk'), Property: 'Fingerprint', PropertyType: 'string' },
							]
						};

					case repository.apiData.workOrderXResourceGroup.prefix:
						return {
							Sorting: { "RegisteredDate": 'desc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-workOrderXResourceGroup-RegisteredDate', "Dato registrert"), Property: 'RegisteredDate', PropertyType: 'date' },
								{ Position: 2, Title: translationService.translate('web-workOrderXResourceGroup-RealTime', "Registrert tid"), Property: 'RealTime', PropertyType: 'double' },
								{ Position: 3, Title: translationService.translate('web-workOrderXResourceGroup-EstimatedTime', "Estimert tid"), Property: 'EstimatedTime', PropertyType: 'double' },
								{ Position: 4, Title: translationService.translate('web-workOrderXResourceGroup-workOrder-Id', "AO Id"), Property: 'WorkOrder.Id', PropertyType: 'string' },
								{ Position: 5, Title: translationService.translate('web-workOrderXResourceGroup-workOrder-Description', "AO Beskrivelse"), Property: 'WorkOrder.Description', PropertyType: 'string' },
								{ Position: 6, Title: translationService.translate('web-workOrderXResourceGroup-project-Id', "Prosjekt Id"), Property: 'WorkOrder.Project.Id', PropertyType: 'string' },
								{ Position: 7, Title: translationService.translate('web-workOrderXResourceGroup-project-Description', "Prosjektbeskrivelse"), Property: 'WorkOrder.Project.Description', PropertyType: 'string' },
								{ Position: 8, Title: translationService.translate('web-workOrderXResourceGroup-ResourceGroup-Id', "Ressurs Id"), Property: 'ResourceGroup.Id', PropertyType: 'string' },
								{ Position: 9, Title: translationService.translate('web-workOrderXResourceGroup-ResourceGroup-Description', "Ressurs Beskrivelse"), Property: 'ResourceGroup.Description', PropertyType: 'string' },
								{ Position: 10, Title: translationService.translate('web-workOrderXResourceGroup-hourType.Id', "Timetype Id"), Property: 'HourType.Id', PropertyType: 'string' },
								{ Position: 11, Title: translationService.translate('web-workOrderXResourceGroup-hourType.Description', "Timetype Beskrivelse"), Property: 'HourType.Description', PropertyType: 'string' },
							]
						};
					case repository.apiData.dataImport.prefix:
						return {
							Sorting: { "Id": 'desc' },
							Columns: [
								{ Position: 1, Title: translationService.translate('web-dataImport-Id', "Id"), Property: 'Id', PropertyType: 'string' },
								{ Position: 2, Title: translationService.translate('web-dataImport-Description', "Beskrivelse"), Property: 'Description', PropertyType: 'string' },
								{ Position: 3, Title: translationService.translate('web-dataImport-EntityType', "Entitet"), Property: 'EntityType', PropertyType: 'enum.PreFix' },
								{ Position: 4, Title: translationService.translate('web-dataImport-Status', "Status"), Property: 'Status', PropertyType: 'enum.DataImportStatus' },
								{ Position: 5, Title: translationService.translate('web-dataImport-UpdatedDate', "Oppdatert dato"), Property: 'UpdatedDate', PropertyType: 'date' }
							]
						};

					default:
						return {
							Id: 'Plania FEIL',
							Sorting: { 'Guid': 'asc' },
							EntityType: "Undefined",
							Columns: [
								{ Position: 1, Title: 'PLANIA ERROR', Property: 'Guid', PropertyType: 'string' }
							]
						};
				}
			};

			service.getStandardView = function (prefix, filters, contextId) {
				var standardView = getStandardViewInternal(prefix, filters, contextId);

				if (angular.isUndefined(standardView.Id))
					standardView.Id = 'Plania standard';

				if (angular.isUndefined(standardView.IsPublicView))
					standardView.IsPublicView = true;

				if (angular.isUndefined(standardView.IsPlaniaStandard))
					standardView.IsPlaniaStandard = true;

				if (angular.isUndefined(standardView.EntityType))
					standardView.EntityType = prefix;

				if (angular.isUndefined(standardView.ContextId))
					standardView.ContextId = contextId;

				return standardView;
			};

			return service;
		}]);
})();
