(function () {
    angular.module('Plania').controller('CreateSparePartModalController', ['$scope', 'Repository', '$modalInstance', '$localStorage', controller]);

    function controller($scope, repository, $modalInstance, $localStorage) {
        $scope.sparePart = {};
        $scope.component = {};
        $scope.componentSupplier = {};
        $scope.generalOptions = $localStorage.generalOptions;

        var showError = function (error) {
            if (typeof (error) === "string") {
                repository.growl(error, 'danger');
            } else {
                repository.growl(error.Data.Message, 'danger');
            }
        };

        $scope.save = function () {
            if ($scope.componentSupplier.Price) {
                $scope.componentSupplier.Price = $scope.componentSupplier.Price.replace(',', '.');
            }

            repository.createSingleDictionary(repository.apiData.component.url, $scope.component).then(function (result) {
                $scope.component.Guid = result.Guid;
                $scope.sparePart.GuidComponent = result.Guid;
                $scope.componentSupplier.GuidComponent = result.Guid;

                repository.createSingleDictionary(repository.apiData.componentSupplier.url, $scope.componentSupplier).then(function (result) {
                    $scope.sparePart.GuidComponentXSupplier = result.Guid;

                    repository.createSingleDictionary(repository.apiData.sparePart.url, $scope.sparePart).then(function (result) {
                        repository.growl('Reservedel har blitt opprettet', 'success');
                        repository.commonService.setLastRegisterGuid('sparePart.edit', result.Guid);
                        $modalInstance.dismiss('saved');
                        $scope.goBack('sparePart.edit', { guid: result.Guid, menuGuid: $scope.navigation.params.menuGuid });
                    }, function (error) {
                        showError(error);
                    });
                }, function (error) {
                    showError(error);
                });
            }, function (error) {
                showError(error);
            });
        };

        $scope.cancel = function () {
            $modalInstance.dismiss('canceled');
        };
    }
})();
