(function() {
    //'$http', 'config', 'genericRepository', '$q',
    angular.module('Plania').factory('MenuService', [
        'Repository', 'TranslationService', '$q',
        function service(repository, translationService, $q) {

            var publicMember = {};
            var createObjects = [];

            publicMember.getCreateObjects = function() {
                createObjects = [];
                if (repository.authService.hasCreateAccess(repository.commonService.prefix.Equipment))
                    createObjects.push({ text: translationService.translate('shortcut-new-equipment', 'Anlegg'), state: 'equipment.create' });
                if (repository.authService.hasCreateAccess(repository.commonService.prefix.WorkOrder))
                    createObjects.push({ text: translationService.translate('shortcut-new-workOrder', 'Arbeidsordre'), state: 'workOrder.create' });
                if (repository.authService.hasCreateAccess(repository.commonService.prefix.Building))
                    createObjects.push({ text: translationService.translate('shortcut-new-building', 'Bygg'), state: 'building.create' });
                if (repository.authService.hasCreateAccess(repository.commonService.prefix.Document))
                    createObjects.push({ text: translationService.translate('shortcut-new-document', 'Dokument'), state: 'document.create' });
                if (repository.authService.hasCreateAccess(repository.commonService.prefix.Estate))
                    createObjects.push({ text: translationService.translate('shortcut-new-estate', 'Eiendom'), state: 'estate.create' });
                if (repository.authService.hasCreateAccess(repository.commonService.prefix.Supplier))
                    createObjects.push({ text: translationService.translate('shortcut-new-supplier', 'Leverandør'), state: 'supplier.create' });
                if (repository.authService.hasCreateAccess(repository.commonService.prefix.Request))
                    createObjects.push({ text: translationService.translate('shortcut-new-request', 'Meldinger'), state: 'request.create' });
                if (repository.authService.hasCreateAccess(repository.commonService.prefix.PeriodicTask))
                    createObjects.push({ text: translationService.translate('shortcut-new-periodicTask', 'Periodisk rutine'), state: 'periodicTask.create' });
                if (repository.authService.hasCreateAccess(repository.commonService.prefix.Person))
                    createObjects.push({ text: translationService.translate('shortcut-new-person', 'Person'), state: 'person.create' });
                if (repository.authService.hasCreateAccess(repository.commonService.prefix.Area))
                    createObjects.push({ text: translationService.translate('shortcut-new-area', 'Rom'), state: 'area.create' });

                return createObjects;
            };

            publicMember.getShortcuts = function() {
	            return repository.getShortcuts();
            };

            return publicMember;
        }
    ]);
})();
