(function () {
    angular.module('Plania').directive('plCalendarWidget', ['TranslationService', function (translationService) {
        return {
            restrict: 'A',
            require: '^plDashboardGrid',
            scope: {
                widget: '=widget',
                edit: '=edit',
                saveFunction: '=saveFunction'
            },
            link: function (scope, element, attrs, gridCtrl) {
                scope.$parent.attachWidget(element, attrs);

                scope.removeWidget = function () {
                    swal({
                        title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
                        text: translationService.translate('web-swal-dashboard-calendar-message', "Kalenderen vil bli permanent fjernet!"),
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#f44336",
                        confirmButtonText: translationService.translate('web-swal-dashboard-calendar-confirm', 'Ja, fjern kalenderen'),
                        cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
                        closeOnConfirm: false
                    }, function () {
                        window.onkeydown = null;
                        window.onfocus = null;
                        swal(translationService.translate('web-swal-dashboard-calendar-success', 'Kalenderen ble fjernet!'), '', "success");
                        scope.$parent.removeWidget(element, attrs);
                    });
                };
            },
            templateUrl: 'app/dashboard/directives/widgets/calendarWidget.html',
            controller: ['$scope', 'TranslationService', 'Repository', '$modal', '$timeout', function ($scope, translationService, repository, modal, $timeout) {
                $scope.model = $scope.widget;

                $scope.eventSource = repository.apiData.workOrder.url;

                $scope.sharedController = {};

                //Event for resizing the calendar
                $('.grid-stack').on('resizestop', function (event, ui) {
                    if ($(event.target).find('#calendar-widget').length > 0) {
                        $timeout(function () {
                            $scope.sharedController.resizeCalendar(ui.element.height());
                        }, 350);
                    }
                });

                //Setting the height of the calendar after dom has rendered
                $timeout(function () {
                    var height = $(".grid-stack").find("[data-gs-id='" + $scope.model.Guid + "']").height();
                    $scope.sharedController.resizeCalendar(height);
                });

                $scope.editWidget = function () {
                    modal.open({
                        controller: 'AddWidgetModalController',
                        templateUrl: 'app/dashboard/addWidgetModal.html',
                        resolve: {
                            widget: function () {
                                return $scope.model;
                            }
                        }
                    }).result.then(function (widgetModel) {
                        $scope.saveFunction(false);
                    }, function () {
                        //Dismissed
                    });
                };
            }],
        };
    }]);
})();
