(function () {
    angular.module('Plania').controller('CreateUserEquipmentController', ['$scope', '$modalInstance', 'params', 'Repository', 'ngTableParams', 'TranslationService', controller]);

    function controller($scope, $modalInstance, params, repository, ngTableParams, translationService) {

        $scope.equipmentColumns =
        [
            { Position: 1, Title: translationService.translate('web-building-id'), Property: 'Building.Id', PropertyType: 'string' },
            { Position: 2, Title: translationService.translate('web-building-description'), Property: 'Building.Description', PropertyType: 'string' },
            { Position: 3, Title: 'Anleggs gruppe', Property: 'EquipmentGroup.Caption', PropertyType: 'string' },
            { Position: 4, Title: translationService.translate('web-equipment-id'), Property: 'Id', PropertyType: 'string' },
            { Position: 5, Title: translationService.translate('web-equipment-description'), Property: 'Description', PropertyType: 'string' },

        ];

		$scope.equipmentTable = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { id: 'asc' },
			filter: {
			    propertyFilter: [{ Property: 'IS_EQUIPMENT_GROUP', Operator: '=', Value: '1' }]
			}
		},
		{
			total: 0,
			counts: [10, 25, 50],
            paginationMaxBlocks:6,
			getData: function ($defer, params) {
			    $scope.isLoading = true;
			    var columns = [];
			    $scope.equipmentColumns.forEach(function (col) {
                    if(col.Property.indexOf('EquipmentGroup') === -1)
			            columns.push(col.Property);
			    });
				repository.GetPaginated(repository.apiData.equipment.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(
					function (result) {
						params.total(result.TotalCount);
						$scope.isLoading = false;
						$defer.resolve(result.List);
					    console.log(result.List);
					},
					function (error) {
					    repository.growl(error, 'danger');
					});
			}
		});

		$scope.selectedEquipments = [];

		$scope.saveEquipment = function () {

		    var userXEquipments = [];
            $scope.selectedEquipments.forEach(function(eq) {
                userXEquipments.push({ GuidUser: params.guidUser, GuidEquipment: eq });
            });

		    repository.createMultiple(repository.apiData.userEquipment.url, userXEquipments).then(function (result) {
		        repository.growl('Anlegget har blitt lagt til brukeren', 'success');
		        $modalInstance.close('OK');
		    });
		};

		$scope.addEquipment = function (guid) {
		    if (!_.contains($scope.selectedEquipments, guid))
		        $scope.selectedEquipments.push(guid);
		    else {
		        for (var i = 0; i < $scope.selectedEquipments.length; i++) {
		            var p = $scope.selectedEquipments[i];
		            if (p === guid) {
		                $scope.selectedEquipments.splice(i, 1);
		                return;
		            }
		        }
		    }
		};

		$scope.isEquipmentSelected = function (guid) {
		    return _.contains($scope.selectedEquipments, guid);
		};

		$scope.cancel = function () {
		    $modalInstance.dismiss('canceled');
		};
	}

})();
