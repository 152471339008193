(function () {

	var app = angular.module('Plania');

	app.directive('projectMilestone', function () {
		return {
			restrict: 'E',
			scope: {
				project: '=project',
				isExpanded: '=expanded',
				restrictEdit: '=restrictEdit'
			},
			controller: ['$scope', '$modal', 'Repository', '$rootScope', 'TranslationService', controller],
			templateUrl: 'app/projectMilestone/views/projectMilestoneDirective.html'
		};
	});

	function controller($scope, $modal, repository, $rootScope, translationService) {
		$scope.hasCreateAccess = $rootScope.hasCreateAccess('ProjectMilestone') && !$scope.project.ClosedDate;
		$scope.hasEditAccess = $rootScope.hasEditAccess('ProjectMilestone');
		$scope.hasDeleteAccess = $rootScope.hasDeleteAccess('ProjectMilestone');

		var getMilestones = function () {
			if (!$scope.project.Guid) {
				console.warn("Project milestone directive expected project guid, got: " + $scope.project.Guid);
				return;
			}

			repository.GetPaginated(repository.apiData.projectMilestone.url, 0, -1, { 'DueDate': 'asc', 'EndDate': 'asc' }, { PropertyFilter: [{ Property: 'GuidProject', Operator: '=', Value: $scope.project.Guid }] }, '', JSON.stringify(['Id', 'Description', 'DueDate', 'EndDate'])).then(function (result) {
				$scope.milestones = result.List;
			});
		};

		getMilestones();

		$scope.milestoneCreateModal = function () {
			$modal.open({
				templateUrl: "app/projectMilestone/views/editMilestoneModal.html",
				controller: "ProjectMilestoneModalController",
				resolve: {
					modalParams: function () {
						return { GuidProject: $scope.project.Guid, IsTemplate: $scope.project.IsTemplate };
					}
				}
			}).result.then(function (responseResult) {
				getMilestones();
			});
		};

		$scope.milestoneUpdateModal = function (milestone) {
			$modal.open({
				templateUrl: "app/projectMilestone/views/editMilestoneModal.html",
				controller: "ProjectMilestoneModalController",
				resolve: {
					modalParams: function () {
						return { Milestone: milestone, IsTemplate: $scope.project.IsTemplate };
					}
				}
			}).result.then(function (responseResult) {
				getMilestones();
			});
		};

		$scope.milestoneEndToday = function (milestone) {
			milestone.EndDate = moment().toDate();
			repository.updateSingleDictionary(repository.apiData.projectMilestone.url, milestone).then(function (result) {
				getMilestones();
				repository.growl(translationService.translate('web-swal-projectmilestone-endtoday-success', 'Milepælen ble fullført!'), 'success');
			}).catch(function (error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.milestoneDelete = function (milestone) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-projectmilestone-message', 'Milepælen vil bli permanent fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-supplier-projectmilestone-button-confirm', 'Ja, fjern milepælen!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.projectMilestone.url, milestone.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-projectmilestone-delete-success', 'Milepælen ble fjernet!'), result, "success");
						getMilestones();
					});
			});
		};

	}
})();
