(function () {
    angular.module('Plania').controller('AlarmLogController', ['$scope', 'Repository', '$stateParams', 'TranslationService', '$sce', '$filter', controller]);

    function controller($scope, repository, $stateParams, translationService, $sce, $filter) {
        $scope.isUpdate = $scope.navigation.current.name === 'alarmLog.edit';

        if ($scope.isUpdate) {
            repository.getSingle(repository.apiData.alarmLog.url, $stateParams.guid).then(
                function (response) {
                    $scope.model = response.Data;
                    $scope.model.EmailBody = decodeHtml($scope.model.EmailBody);
                }, function (error) {
                    repository.growl(error, 'danger');
                }
            );
        }

        var decodeHtml = function (html) {
            var tmpElement = document.createElement('textarea');
            tmpElement.innerHTML = html;
            return tmpElement.value;
        };

        $scope.getTrustedHtml = function (string) {
            if (!string) return '';
            return $sce.trustAsHtml(string);
        };

        $scope.isHtml = function (string) {
            return string ? /<[a-z][\s\S]*>/i.test(string) : true;
		};

		$scope.getNumberOfAttachments = function () {
			if (!$scope.model) return null;
			if (!$scope.model.Attachments) return 0;
			return $scope.model.Attachments.split(',').length;
		};

        $scope.update = function (destination) {
            var success = function (result) {
                repository.growl($scope.isUpdate ? translationService.translate('update-alarmLog-success', 'Alarm loggen har blitt oppdatert.') : translationService.translate('create-alarmLog-success', 'Alarm loggen har blitt opprettet.'), 'success');
                var guid = $scope.isUpdate ? $scope.model.Guid : result;
                repository.commonService.setLastRegisterGuid(destination, guid);
                $scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
            };

            var error = function (error) {
                if (typeof (error) === "string") {
                    repository.growl(error, 'danger');
                } else {
                    repository.growl(error.Data.Message, 'danger');
                }
            };

            if ($scope.isUpdate) {
                repository.updateSingle(repository.apiData.alarmLog.url, $scope.model).then(success, error);
            } else {
                repository.createSingle(repository.apiData.alarmLog.url, $scope.model).then(success, error);
            }
        };

        $scope.delete = function () {
            swal({
                title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
                text: translationService.translate('web-swal-alarmLog-message', "Alarm loggen vil bli permanent fjernet!"),
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f44336",
                confirmButtonText: translationService.translate('web-swal-alarmLog-button-confirm', 'Ja, fjern Alarm loggen'),
                cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
                showLoaderOnConfirm: true
            }, function () {
                window.onkeydown = null;
                window.onfocus = null;

                repository.deleteSingle(repository.apiData.alarmLog.url, $scope.model.Guid)
                    .then(function (result) {
                        swal(translationService.translate('web-swal-alarmLog-success', 'Alarm loggen ble fjernet!'), result, "success");
                        $scope.goBack('alarmLog.list', { menuGuid: $scope.navigation.params.menuGuid });
                    });
            });
        };
    }
})();
