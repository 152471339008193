(function () {
	angular.module('Plania').controller('RequestModalController', ['$scope', 'Repository', 'params', '$modalInstance', 'TranslationService', controller]);

	function controller($scope, repository, params, modalInstance, translationService) {
		var columns = ['Description', 'CreationDate', 'Status'];
		var filter = {};

		if (params.guidArea) {
			filter.PropertyFilter = [
				{ Operand: 'AND', Property: 'Status', Operator: '=', Value: '0' },
				{ Operand: 'OR', Property: 'Status', Operator: '=', Value: '1'},
                { Operand: 'AND', Property: 'WorkOrder.EndDate', Operator: '=', Value: '' },
                { Operand: 'AND', Property: 'GuidArea', Operator: '=', Value: params.guidArea },
			];
		}

		$scope.canNavigate = function () {
			return repository.authService.hasReadAccess(repository.commonService.prefix.Request);
		};

		$scope.goToRequest = function (request) {
			$scope.navigation.go('request.edit', { guid: request.Guid });
			$scope.ok();
		};

		repository.GetPaginated(repository.apiData.request.url, 0, 100, { 'CreationDate': 'desc' }, filter, null, JSON.stringify(columns)).then(function (result) {
			$scope.requests = result.List;
		});

		$scope.ok = function () {
			modalInstance.close();
		};

		$scope.cancel = function () {
			modalInstance.dismiss('cancel');
		};
	}
})();
