(function () {
    'use strict';

	angular.module('Plania').directive('planiaDecimal', [planiaDecimal]);

	// Makes an input field type="text" behave as type="number", but supporting both comma and punctuation which is buggy in Chrome
	function planiaDecimal() {
        return {
			restrict: 'A',
			require: 'ngModel',
			link: function (scope, element, attrs, ngModelController) {

				//convert data from view format to model format
				ngModelController.$parsers.push(function (input) {
					if (!input) return input;

					// Remove all charachters except: numeric, comma, punctuation and minus sign
					var transformedInput = input.replace(/[^\d.,-]/g, '');

					if (transformedInput !== input) {
						ngModelController.$setViewValue(transformedInput);
						ngModelController.$render();
					}

					// replacing comma with punctuation only for the ngModel object, so we can still display comma
					var ret = transformedInput.toString().trim().replace(',', '.');

					return parseFloat(ret);
				});

				//convert data from model format to view format
				ngModelController.$formatters.push(function (input) {
					var ret = (input) ? input.toString().replace('.', ',') : null;
					return ret;
				});
			}
        };
    }
})();
