(function () {
	angular.module('Plania').controller('SelectIconModalController', ['$scope', '$modalInstance', controller]);

	function controller($scope, $modalInstance, translationService) {
        $scope.availableIcons = [
            'alert-circle',
            'alert-octagon',
            'alert-polygon',
            'alert-triangle',
            'help',
            'info',
            'notifications-active',
            'notifications',
            'chart',
            'chart-donut',
            'assignment',
            'assignment-check',
            'thumb-up',
            'thumb-up-down',
            'case',
            'dns',
            'flag',
            'home',
            'puzzle-piece',
            'money',
            'money-box',
            'shopping-cart',
            'shopping-basket',
            'truck',
            'store',
            'shield-security',
            'shield-check',
            'check-all',
            'check',
            'plus',
            'plus-circle',
            'star',
            'comment-alert',
            'comment',
            'comments',
            'camera',
            'desktop-windows',
            'device-hub',
            'keyboard',
            'phone',
            'smartphone',
            'wifi',
            'arrow-right-top',
            'trending-down',
            'trending-flat',
            'trending-up',
            'arrows',
            'rotate-left',
            'rotate-right',
            'my-location',
            'pin',
            'apps',
            'view-dashboard',
            'view-column',
            'widgets',
            'grid',
            'view-list',
            'alarm',
            'alarm-check',
            'time-interval',
            'time',
            'time-countdown',
            'timer',
            'calendar',
            'fire',
			'flower-alt',
            'flash',
            'file-text',
            'file-plus',
            'file',
            'format-list-bulleted',
            'format-list-numbered',
            'copy'
        ];

		$scope.ok = function (selectedIcon) {
			$modalInstance.close(selectedIcon);
        };

        $scope.cancel = function () {
            $modalInstance.dismiss();
        };
	}
})();
