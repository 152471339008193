(function() {
	angular.module('Plania').controller('AccountController', ['$scope', 'Repository', '$stateParams', '$modal', 'ngTableParams', 'TranslationService', 'ListService', controller]);

	function controller($scope, repository, $stateParams, $modal, ngTableParams, translationService, listService) {
		$scope.isUpdate = $scope.navigation.current.name === 'account.edit';
        $scope.activateAutocomplete = false;
        $scope.model = {};

		$scope.getPropertyValue = function(entity, field) {
			return listService.GetPropertyValue(entity, field);
        };

        var updateAccess = function () {
            if ($scope.isUpdate) {
                var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
                $scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.Account, checkOtherDO);
                $scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Account, checkOtherDO);
            } else {
                $scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.Account);
                $scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Account);
            }
        };

		if($scope.isUpdate) {
			repository.getSingle(repository.apiData.account.url, $stateParams.guid).then(function(response) {
				$scope.model = response.Data;
                $scope.reload = true;
                updateAccess();
				getCardTables();
			}, function(error) {
				repository.growl(error, 'danger');
			}
		);
		}
        else {
            $scope.model.Type = 0;
			$scope.model.VatCode = 0;
		}

		// AccountXAccountingDimension
		{
			$scope.accountXAccountingDimensionColumns = [];
			$scope.accountXAccountingDimensionColumns.AddColumn = function(translationId, defaultText, property, propertyType) {
				this.push({ Position: this.length, Title: translationService.translate(translationId, defaultText), Property: property, PropertyType: propertyType });
				return this;
			};
			$scope.accountXAccountingDimensionColumns
				.AddColumn('web-accounting-id', "Id", 'Accounting.Id', 'string')
				.AddColumn('web-accounting-description', "Beskrivelse", 'Accounting.Description', 'string')
			;
			var getCardTables = function() {
				$scope.accountXAccountingDimensionTable = new ngTableParams({
					page: 1,
					count: 10,
					sorting: {/* 'Id': 'asc' */ },
					filter: { PropertyFilter: [{ Property: "GuidAccount", Operator: '=', Value: $scope.model.Guid }] }
				}, {
					total: 0,
					counts: [10, 20, 50],
					filterDelay: 10,
					getData: function($defer, params) {
						var columns = [];
						$scope.accountXAccountingDimensionColumns.forEach(function(col) {
							columns.push(col.Property);
						});

						repository.GetPaginated(repository.apiData.accountAccounting.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify(columns))
							.then(function(result) {
								$scope.loadingAccounting = false;
								$scope.accountXAccountingDimensionTable.settings().total = result.TotalCount;
								$scope.accountXAccountingDimensionTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							}, function(error) {
								$scope.loadingAccounting = false;
								repository.growl(error, 'danger');
							});
					}
				});
			};

			$scope.addAccounting = function() {
				$modal.open({
					templateUrl: 'app/common/views/multipleSelectModal.html',
					controller: 'MultipleSelectModalController',
					resolve: {
					    modalParams: function () {
							return {
								title: 'Velg dimensjoner',
								columns: [
									{ Title: translationService.translate('web-accountingDimension-id'), Property: 'Id' },
								],
								useObjects: true,
								url: repository.apiData.accounting.url,
								sorting: { Id: 'asc' },
								filter: {},
								alreadySavedGuids: function()
								{
									var selectedGuids = [];
									$scope.accountXAccountingDimensionTable.data.forEach(function(entity) {
										selectedGuids.push(entity.GuidAccounting);
									});
									return selectedGuids;
								}()
							};
						}
					}
				}).result.then(function(selectedEntities) {
					var arrayOfAccountXAccountingDimensions = [];
					selectedEntities.forEach(function(entity) {
						arrayOfAccountXAccountingDimensions.push({ GuidAccount: $scope.model.Guid, GuidAccounting: entity.Guid });
					});
					repository.createMultipleWithGuid(repository.apiData.accountAccounting.url, $scope.model.Guid, arrayOfAccountXAccountingDimensions).then(function(result) {
						repository.growl('Dimensjon(er) har blitt lagt til på kontoen', 'success');
						$scope.accountXAccountingDimensionTable.reload();
					});
				});
			};

			$scope.removeAccounting = function(accounting) {
				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-swal-accountXAccounting-message', 'Dimensjonen vil bli permanent fjernet!'),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-supplier-accountXAccounting-button-confirm', 'Ja, fjern dimensjonen!'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function() {
					window.onkeydown = null;
					window.onfocus = null;

					repository.deleteSingle(repository.apiData.accountAccounting.url, accounting.Guid)
						.then(function(result) {
							swal(translationService.translate('web-swal-supplier-accountXAccounting-success', 'Dimensjonen ble fjernet!'), result, "success");
							$scope.accountXAccountingDimensionTable.reload();
						});
				});
			};
		}


		// getCardTables();
		$scope.update = function(destination) {
			var success = function(result) {
				repository.growl($scope.isUpdate ? translationService.translate('update-account-success', 'Konto har blitt oppdatert.') : translationService.translate('create-account-success', 'Konto har blitt opprettet.'), 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function(error) {
				if(typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if($scope.isUpdate) {
				repository.updateSingleDictionary(repository.apiData.account.url, $scope.model).then(success, error);
			} else {
				repository.createSingleDictionary(repository.apiData.account.url, $scope.model).then(success, error);
			}

		};

		$scope.delete = function() {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-account-message', "Konto vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-account-button-confirm', 'Ja, fjern konto'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function() {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.account.url, $scope.model.Guid)
					.then(function(result) {
						swal(translationService.translate('web-swal-account-success', 'Konto ble fjernet!'), result, "success");
						$scope.goBack('account.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
        };

        $scope.$on($scope.events.newSelection, function () {
            updateAccess();
        });
	}

})();
