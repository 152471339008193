(function () {

	var app = angular.module('Plania');

	app.directive('entityAttribute', function () {
		return {
			restrict: 'E',
			scope: {
				guid: '=guid',
				guidColumn: '=guidColumn',
				isCollapsed: '=isCollapsed',
				onAttributesChanged: '&onAttributesChanged'
			},
			controller: ['$scope', 'Repository', '$modal', 'TranslationService', controller],
			templateUrl: 'app/common/directives/views/entityAttribute.html'
		};
	});

	function controller(scope, repository, modal, translationService) {
		scope.datePickers = {
		};

		scope.dateOptions = {
			"starting-day": 1
		};

		scope.open = function ($event, calendar) {
			scope.datePickers[calendar] = !scope.datePickers[calendar];
			$event.preventDefault();
			$event.stopPropagation();
		};
		scope.model = {};
		var columns = [
			'EntityAttribute.Id',
			'EntityAttribute.ValueType',
			'IndexPosition',
			'ValidityRule'
		];

		var getAttributes = function () {
			if (scope.guid) {
				//todo should probably make this a infinite loop in order to get all dynamic properties
				var filter = { PropertyFilter: [{ Property: scope.guidColumn, Operator: "=", Value: scope.guid }] };
				repository.GetPaginated(repository.apiData.entityXAttribute.url, 0, 500, { 'IndexPosition': 'asc' }, filter, "", JSON.stringify(columns))
	                .then(
	                    function (result) {
							scope.model = result.List;
							if (scope.onAttributesChanged) scope.onAttributesChanged();
	                    })
	                .catch(function (error) {
	                	console.log(error);
	                });
			}
		};
		getAttributes();

		scope.removeAttribute = function (item) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-entityXAttribute-message', "Attributtet vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-entityXAttribute-button-confirm', 'Ja, fjern attributtet'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.entityXAttribute.url, item.Guid)
					.then(function (result) {
						getAttributes();
						swal(translationService.translate('web-swal-entityXAttribute-success', 'Attributtet ble fjernet!'), result, "success");
						repository.growl('Attributtet har blitt fjernet', 'success');
					});
			});
		};

		var getNewIndexPosition = function() {
			if (!scope.model || !scope.model.length) return 0;
			var maxElement = _.max(scope.model, function(item) {
				return item.IndexPosition;
			});
			return maxElement.IndexPosition + 1;
		};

		scope.addAttribute = function () {
			scope.isCollapsed = false;
			modal.open({
				templateUrl: 'app/common/views/attributeModal.html',
				controller: 'AttributeModalController',
				size: 'md',
				resolve: {
					params: function () {
						return {
							isUpdate: false,
							guid: scope.guid,
							guidColumn: scope.guidColumn,
							indexPosition: getNewIndexPosition()
						};
					}
				}
			}).result.then(function () {
				getAttributes();
				scope.isCollapsed = false;
			}, function (msg) {
				scope.isCollapsed = false;
			});
		};

		scope.isMandatory = function (item) {
			if ((item.ValidityRule & 1) === 1)
				return true;
			return false;
		};

		scope.treeOptions = {
			dropped: function (event) {
				//update order of all dynamic properties affected by drag and drop. DraggedDown =
				var draggedDown = event.source.index > event.dest.index;
				var startIndex = draggedDown ? event.dest.index : event.source.index;
				var endIndex = draggedDown ? event.source.index : event.dest.index;
				for (var i = startIndex; i <= endIndex; i++) {
					var item = scope.model[i];
					item.IndexPosition = i;
					repository.updateSingleDictionary(repository.apiData.entityXAttribute.url, item);
				}
			}
		};

		scope.extra = {};
		scope.save = function (destination) {
			if (typeof scope.saveFunction === 'function') {
				scope.saveFunction(scope.destinationObject + "." + destination);
			}
		};
		scope.reload = true;
	}

})();
