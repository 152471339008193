(function () {
	angular.module('Plania').controller('IfcEditController', ['$scope', '$stateParams', 'Repository', 'TranslationService', controller]);

	function controller($scope, $stateParams, repository, translationService) {
		$scope.isUpdate = $scope.navigation.current.name === 'ifcFile.edit';
		$scope.activateAutocomplete = false;
		$scope.model = { Guid: $stateParams.guid };
		$scope.viewMode = $scope.isUpdate ? true : false;

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
		};

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.IfcFile, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.IfcFile, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.IfcFile);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.IfcFile);
			}
		};

		updateAccess();

		var setProperties = function () {
			var ifcFile = $scope.model;

			if (ifcFile.Building) $scope.building = $scope.getEntityCaption(ifcFile.Building);
		};

		if ($scope.isUpdate) {
			var columns = ["Building.Id", "Building.Description"];

			repository.getSingle(repository.apiData.ifcFile.url, $stateParams.guid, JSON.stringify(columns)).then(
				function (response) {
					$scope.model = response.Data;

					updateAccess();
					setProperties();
					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
				},
				function (error) {
					repository.growl(error, 'danger');
				});

		} else {

			$scope.activateAutocomplete = true;
		}

		$scope.update = function (destination) {
			var success = function (result) {
				repository.growl($scope.isUpdate ? "Bygg har blitt oppdatert." : 'Bygg har blitt opprettet', 'success');
				if (repository.commonService.getFilterData().selectedSelection.Guid) {
					repository.growl('Bygget har blitt lagt til gjeldende utvalg.', 'success');
				}
				var buildingGuid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, buildingGuid);
				$scope.goBack(destination, { guid: buildingGuid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.isUpdate) {
				if ($scope.barcode.Id) {
					repository.createSingle(repository.apiData.barcode.url, $scope.barcode)
						.then(function (result) {

						},
						function (error) {
							repository.growl(error, 'danger');
						});
				}

				repository.updateSingleDictionary(repository.apiData.building.url, $scope.model).then(success).catch(error);
			} else {
				$scope.model.BuildingXBuildingSelection = [
					{ GuidBuildingSelection: repository.commonService.getFilterData().selectedSelection.Guid }
				];
				repository.createSingleDictionary(repository.apiData.building.url, $scope.model).then(success, error);
			}
		};

		$scope.cancel = function () {
			$scope.goBack('ifcFile.list', { menuGuid: $scope.navigation.params.menuGuid });
		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-ifcFile-message', "IFC-filen vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-ifcFile-button-confirm', 'Ja, fjern IFC-filen'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.ifcFile.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-ifcFile-success', 'IFC-filen ble fjernet!'), result, "success");
						$scope.goBack('ifcFile.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});
	}
})();
