(function () {

	var app = angular.module('Plania');

	app.directive('projectPerson', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
                guidProperty: '=guidProperty',
                personProperty: '=personProperty',
                save: '=saveAction'
			},
			controller: ['$scope', '$rootScope', 'TranslationService', '$modal', 'AutoCompleteService', controller],
			templateUrl: 'app/project/views/projectPersonDirective.html'
		};
	});

	function controller($scope, $rootScope, translationService, $modal, autoCompleteService) {

        var getDefaultTitle = function() {
            switch ($scope.personProperty) {
            case 'Person2':
                return 'Prosjekteier';
            case 'Person1':
                return 'Prosjektleder';
            default:
                return 'Prosjektdeltaker';
            }
        };

        $scope.imageApiUrl = $rootScope.imageApiUrl;
        $scope.title = translationService.translate('web-project-' + $scope.personProperty, getDefaultTitle());
        $scope.person = $scope.model[$scope.personProperty];

        $scope.$watch('model.' + $scope.personProperty, function (newValue, oldValue) {
            $scope.person = newValue;
        });

        $scope.selectPerson = function () {
			$modal.open({
				templateUrl: 'app/common/views/tableModal.html',
				controller: 'TableModalController',
				resolve: {
					content: function () {
						return {
							header: translationService.translate('web-lookup-title', 'Oppslagsliste'),
							idTitle: translationService.translate('autocomplete-lookup-header-person', 'Navn'),
							data: autoCompleteService.persons,
							params: {},
							singleValue: true
						};
					}
				}
			}).result.then(function (selected) {
                $scope.model[$scope.personProperty] = selected.object;
                $scope.model[$scope.guidProperty] = selected.guid;
                $scope.save();
			}, function () {

			});
		};

		$scope.removePerson = function () {
			delete $scope.model[$scope.guidProperty];
            delete $scope.model[$scope.personProperty];
			$scope.save();
		};
	}
})();
