angular.module('Plania').factory('DwgMarkerService', [function () {
	var service = {};


	//this methods uses Centroid of polygon from https://en.wikipedia.org/wiki/Centroid#Centroid_of_polygon
	//but it fails on some drawings, identify errors befor using this (related to clockwise and anti clockwise)
	var getCenterCoordinates = function (coordinates) {
		var count = coordinates.length; //coordinates = list of objects with x and y
		var center = { x: 0, y: 0 }; //center coordinates will be stored in this object
		var polygonArea = 0.0;
		var currentX = 0.0;
		var currentY = 0.0;
		var nextX = 0.0;
		var nextY = 0.0;
		var partialPolygonArea = 0.0;
		var edges = 0.0;
		//complete the sum part of formula for x and y
		for (var i = 0; i < count - 1; i++) {
			currentX = coordinates[i].x;
			currentY = coordinates[i].y;
			nextX = coordinates[(i + 1) % count].x; //use modulus in order to make sure that the last coordinate is connected to the first
			nextY = coordinates[(i + 1) % count].y;
			partialPolygonArea = currentX * nextY - nextX * currentY;
			polygonArea += partialPolygonArea;
			center.x += ((currentX + nextX) * partialPolygonArea);
			center.y += ((currentY + nextY) * partialPolygonArea);
			edges += (nextX + currentX) * (nextY - currentY);
		}

		polygonArea *= 0.5;
		center.x /= (6.0 * polygonArea);
		center.y /= (6.0 * polygonArea);
		edges = edges / 2;
		if (edges < 0) {
			center.x = center.x * -1;
			center.y = center.y * -1;
		}


		return center;
	};

	var getCoordinatesFromElement = function (element) {
		var areaPathString = $(element).attr('d').replace('Z', '');
		var areaPaths = areaPathString.split(/(?=[LMC])/);
		var coordinates = [];
		var count = 0;

		areaPaths.forEach(function (area) {
			if (area[0] === "L" || area[0] === 'l' || area[0] === "M" || area[0] === 'm') {
				area = area.substring(1, area.length - 1).trim();
				var xy = area.split(/[ ,]/);
				count++;
				coordinates.push({ x: Number(xy[0]), y: Number(xy[1]) });
			}
		});

		return coordinates;
	};

	service.addPeriodicIcon = function (p, guidArea, progress) {
		var center = { x: 0, y: 0 };
		var scale = 55;

		var bBox = p.getBBox();
		center.x = ((bBox.x + (bBox.width / 2)) / scale);
		center.y = (((bBox.y + (bBox.height / 2)) / scale));

		var svg = $('#dwgDrawing').svg('get');
		var transformString = 'rotate(-90) scale(' + (scale * -1) + ', ' + (scale) + ') translate(' + (center.y) + ',' + (center.x) + ')';

		var markerGroup = svg.group($('#periodicMarker-coordinates'), guidArea + '_periodicMarker', { class: 'periodicMarker' });

		var strokePercentage = (progress * 15.71) / 100;
		var color = progress <= 50 ? 'green' : progress <= 75 ? 'yellow' : 'red';

		svg.circle(markerGroup, 0, 0, 2.5,
			{
				fill: 'transparent',
				stroke: color,
				strokeWidth: 5,
				transform: transformString,
				strokeDashArray: strokePercentage + ', 15.71',
			}
		);

		svg.circle(markerGroup, 0, 0, 5,
			{
				fill: 'transparent',
				stroke: '#103748',
				strokeWidth: 1,
				transform: transformString
			}
		);

	};

	service.addRequestMarker = function (p, guidArea) {
		var areaPathString = $(p).attr('d').replace('Z', '');
		var areaPaths = areaPathString.split(/(?=[LMC])/);
		var x = 0;
		var y = 0;
		var coordinates = [];
		var count = 0;
		areaPaths.forEach(function (area) {
			if (area[0] === "L" || area[0] === 'l' || area[0] === 'M' || area[0] === 'm') {
				area = area.substring(1, area.length - 1).trim();
				var xy = area.split(/[ ,]/);
				count++;
				coordinates.push({ x: Number(xy[0]), y: Number(xy[1]) });
			}
		});
		var center = { x: 0, y: 0 };
		var scale = 35;
		//if (coordinates.length > 4) { //if there's 4 or less walls dont use the advanced formula
		//	center = getCenterCoordinates(coordinates);
		//	center.x = center.x / scale;
		//	center.y = (center.y / scale) + 40;
		//} else {
		var bBox = p.getBBox();
		center.x = ((bBox.x + (bBox.width / 2)) / scale) * -1;
		center.y = (((bBox.y + (bBox.height / 2)) / scale) + 40) * -1;
		//}

		var svg = $('#dwgDrawing').svg('get');
		var path = svg.createPath();

		var transformString = 'rotate(180) scale(' + scale + ', ' + scale + ') translate(' + (center.x) + ',' + (center.y) + ')';

		var markerGroup = svg.group($('#marker-coordinates'), guidArea + '_marker', { class: 'marker' });
		svg.path(markerGroup,
			path.move(0, 40).curveQ(0, 40, -10, 15).curveC(-16, -7, 16, -7, 10, 15).curveQ(0, 40, 0, 40),
			{
				fill: 'url(' + location.href + '#markerGradient)',
				stroke: '#103748',
				'stroke-width': 1.2,
				class: 'marker-path',
				transform: transformString
			}
		);

		svg.circle(markerGroup, 0, 6, 1.4, {
			fill: 'white',
			stroke: 'none',
			class: 'marker-circle',
			transform: transformString
		});

		svg.rect(markerGroup, -1, 10, 2, 10, 0, 0, {
			fill: 'white',
			stroke: 'none',
			class: 'marker-rect',
			transform: transformString
		});
	};

	service.addEquipmentLabels = function (p, equipments, equipmentStyle) {
		var coordinates = getCoordinatesFromElement(p);
		var topLeft = { x: 0, y: 0 };
		var scale = 35;
		var topLeftCorner = getCorner(coordinates, 'topLeft');

		var bBox = p.getBBox();
		topLeft.x = ((topLeftCorner.x / scale) + 7.5);
		topLeft.y = ((topLeftCorner.y / scale) - 15) * -1;

		var svg = $('#dwgDrawing').svg('get');
		var transformString = 'rotate(180) scale(' + (scale * -1) + ', ' + (scale) + ') translate(' + (topLeft.x) + ',' + (topLeft.y) + ')';

		var markerGroup = svg.group($('#' + equipments[0].GuidArea),
			equipments[0].GuidArea + '_equipmentLabel',
			{ class: 'equipmentLabel' });
		//var markerGroup = $('#' + equipments[0].GuidArea);
		var offset = 12;
		var svgText = svg.createText();
		var equipmentsIdsText = [];

		
		var longestText = "";
		equipments.forEach(function (equipment) {
			// Use Awesomefont icon since I didn't manage to use Zmdi icons
			var text = '\uf068 ' + equipment.Id;
			equipmentsIdsText.push({ text: text, guid: equipment.GuidEquipmentCategory });
			if (text.length > longestText.length) {
				longestText = text;
			}
		});

		
		var canvas = document.createElement("canvas");
		var context = canvas.getContext("2d");
		context.font = 'x-small FontAwesome';
		context.scale = scale;
		var width = context.measureText(longestText).width + 6;
		var height = offset * equipmentsIdsText.length + 4;
		svg.rect(markerGroup, 0, 0, width, height, 0, 0, {
			fill: 'white',
			'stroke': 'black',
			'stroke-width': '0.5',
			transform: transformString
		});

		equipmentsIdsText.forEach(function (equipment) {
			svgText.span(equipment.text,
				{
					dx: 3,
					guidEquipmentCategory: equipment.guid,
					fill: equipmentStyle[equipment.guid]
				});
			
			svg.text(markerGroup,
				null,
				offset,
				svgText,
				{
					stroke: '#103748',
					class: 'marker-path',
					'stroke-width': '0px',
					'font-size': 'x-small',
					'font-family': 'roboto',
					transform: transformString
				});
			svgText.reset();
			offset += 12;

		});
	};

	service.addAreaIdLabel = function (p, area) {
		var coordinates = getCoordinatesFromElement(p);
		var topLeft = { x: 0, y: 0 };
		var scale = 35;
		var topLeftCorner = getCorner(coordinates, 'topLeft');

		topLeft.x = ((topLeftCorner.x) / scale + 2);
		topLeft.y = (((topLeftCorner.y) / scale) - 10) * -1;

		var svg = $('#dwgDrawing').svg('get');
		var transformString = 'rotate(180) scale(' + (-scale) + ', ' + (scale) + ') translate(' + (topLeft.x) + ',' + (topLeft.y) + ')';

		var markerGroup = svg.group($('#areaId-label-coordinates'), area.GuidArea + '_areaIdLabel', { class: 'areaIdLabel' });

		svg.text(markerGroup, null, null, area.AreaId,
			{
				fill: 'url(' + location.href + '#markerGradient)',
				stroke: '#103748',
				class: 'marker-path',
				'stroke-width': '0px',
				'font-size': 'x-small',
				'font-family': 'roboto',
				transform: transformString
			});
	};

	function getCorner(coordinates, pos) {
		var largestX = Math.max.apply(null, coordinates.map(function (c) { return c.x; }));
		var largestY = Math.max.apply(null, coordinates.map(function (c) { return c.y; }));

		var corner = { x: coordinates[0].x, y: coordinates[0].y };
		var breakPointValue;
		coordinates.forEach(function (cord) {
			var value = 0;
			if (pos === 'topLeft')
				value = cord.x + largestY - cord.y;
			else if (pos === 'bottomRight')
				value = largestX - cord.x + cord.y;
			else if (pos === 'topRight' || pos === 'bottomLeft')
				value = cord.x + cord.y;

			if (breakPointValue === undefined) breakPointValue = value;
			else if ((pos === 'topLeft' || pos === 'bottomRight') && value < breakPointValue) {
				corner.x = cord.x;
				corner.y = cord.y;
				breakPointValue = value;
			}
			else if ((pos === 'topRight' || pos === 'bottomLeft') && value > breakPointValue) {
				corner.x = cord.x;
				corner.y = cord.y;
				breakPointValue = value;
			}
		});
		return corner;
	}

	return service;
}]);
