(function () {

	angular.module('Plania').controller('AttributeModalController', ['$scope', 'Repository', 'params', '$modalInstance', '$modal', 'TranslationService', controller]);

	function controller(scope, repository, params, modalInstance, modal, translationService) {

		scope.isUpdate = params.isUpdate;
		scope.model = {};
		scope.model[params.guidColumn] = params.guid;
		scope.model.EntityAttribute = {};
		scope.model.IndexPosition = params.indexPosition;

		scope.valueTypes = [
			{ value: 'Text', label: translationService.translate('web-enum-FieldType-Text', 'Tekst') },
			{ value: 'Numeric', label: translationService.translate('web-enum-FieldType-Numeric', 'Tall') },
			{ value: 'Date', label: translationService.translate('web-enum-FieldType-Date', 'Dato') },
			{ value: 'Combo', label: translationService.translate('web-enum-FieldType-Combo', 'Nedtrekksliste') },
			{ value: 'CheckBox', label: translationService.translate('web-enum-FieldType-CheckBox', 'Sjekkboks') }
		];

		scope.model.EntityAttribute.ValueType = scope.valueTypes[0].value;

		var saveEntityXAttribute = function () {
			delete scope.model.EntityAttribute;
			repository.createSingleDictionary(repository.apiData.entityXAttribute.url, scope.model)
				.then(function (result) {
					modalInstance.close();
				});
		};

		scope.selectExistingEntityAttribute = function () {
			modal.open({
				templateUrl: 'app/common/views/singleSelectModal.html',
				controller: 'SingleSelectModalController',
				size: 'md',
				resolve: {
					modalParams: function () {
						return {
							title: translationService.translate('web-addExistingEntityAttribute', 'Legg til eksisterende attributt'),
							url: repository.apiData.entityAttribute.url,
							sorting: {},
							filter: {},
							columns: [
								{ title: 'Id', property: 'Id' },
								{ title: 'Felttype', property: 'ValueType', PropertyType: 'enum.FieldType' }
							]
						};
					}
				}
			})
				.result.then(function (item) {
					scope.model.EntityAttribute.Id = item.Id;
					scope.model.GuidEntityAttribute = item.Guid;
					var newValueType = _.find(scope.valueTypes, function (type) {
						return type.value === item.ValueTypeName;
					});
					scope.model.EntityAttribute.ValueType = newValueType.value;
				},
					function (msg) {
					});
		};

		scope.selectNamedSelection = function () {
			modal.open({
				templateUrl: 'app/common/views/singleSelectModal.html',
				controller: 'SingleSelectModalController',
				size: 'md',
				resolve: {
					modalParams: function () {
						return {
							url: repository.apiData.namedSelection.url,
							sorting: {},
							title: 'Nedtrekksfelt',
							filter: {},
							columns: [
								{ title: 'Nedtrekksfelt', property: 'Value' }
							]
						};
					}
				}
			})
				.result.then(function (item) {
					scope.model.EntityAttribute.GuidNamedSelection = item.Guid;
					scope.model.EntityAttribute.NamedSelection = item;
				},
					function (msg) {
					});
		};

		scope.save = function () {
			scope.model.ValidityRule = scope.model.IsMandatory ? 1 : 0;

			if (scope.model.EntityAttribute.ValueType !== 'Combo')
				delete scope.model.EntityAttribute.GuidNamedSelection;

			//if existing attribute id is used save entity_x_attribute
			if (scope.model.GuidEntityAttribute) {
				saveEntityXAttribute();
			} else {
				//if a new attribute should be created create it and save entity_x_attribute after
				delete scope.model.EntityAttribute.NamedSelection;
				repository.createSingleDictionary(repository.apiData.entityAttribute.url, scope.model.EntityAttribute).then(function (result) {
					scope.model.GuidEntityAttribute = result.Guid;
					saveEntityXAttribute();
				});
			}
		};

		scope.cancel = function () {
			modalInstance.dismiss('canceled');
		};
	}
})();
