angular.module('Plania').factory('CleaningThemeService', ['$modal', 'Repository', '$q', 'TranslationService', 'DwgMarkerService', 'DwgThemeService', function ($modal, repository, $q, translationService, markerService, dwgThemeService) {
	var service = {};

	var hasCleaningToday = '#2196F3';
	var hasCleaning = '#9E9E9E';
	var isCleaningTodayCompleted = '#5cb85c';
	var hasInfectionCleaning = '#FFC107';
	var hasExitCleaning = '#00BCD4';
	var exitCleaningDischarged = '#000000';

	var updateIndicatorColours = function (statuses) {
		statuses.forEach(function (status) {
			var elements = $('#' + status.GuidArea).children();

			if (elements[0]) {
				var path = elements[0];

				$(path).attr('stroke-dasharray', '0');
				$(path).attr('stroke-linecap', '');
				$(path).attr('stroke', '');
				$(path).attr('fill', '');

				if (status.HasCleaning) {
					$(path).attr('stroke', hasCleaning);
					$(path).attr('fill', hasCleaning);
					$(path).attr('stroke-width', '100');
					$(path).attr('stroke-opacity', '1');
					$(path).attr('fill-opacity', '0.3');
				}
				if (status.HasCleaningToday) {
					$(path).attr('stroke', hasCleaningToday);
					$(path).attr('fill', hasCleaningToday);
					$(path).attr('stroke-width', '100');
					$(path).attr('stroke-opacity', '1');
					$(path).attr('fill-opacity', '0.3');
				}
				if (status.IsCleaningTodayCompleted) {
					$(path).attr('stroke', isCleaningTodayCompleted);
					$(path).attr('fill', isCleaningTodayCompleted);
					$(path).attr('fill-opacity', '0.4');
				}

				if (status.DelayedDate) {
					$(path).attr('stroke', '#FF6700');
					$(path).attr('fill', '#FF6700');
					$(path).attr('fill-opacity', '0.4');
				}

				if (moment(status.DelayedDate).isBefore(moment().format('YYYY-MM-DD'))) {
					$(path).attr('stroke', '#F44336');
					$(path).attr('fill', '#F44336');
					$(path).attr('fill-opacity', '0.4');
				}

				if (status.ExitGuidAreaXCleaningTask) {
					$(path).attr('stroke-width', '100');
					$(path).attr('stroke-opacity', '1');
					$(path).attr('stroke', hasExitCleaning);
					$(path).attr('fill', hasExitCleaning);

					if (status.ExitCleaningRequestStatus === 'Discharged') {
						$(path).attr('fill-opacity', '0.8');
						$(path).attr('fill', '#008EA1');
						$(path).attr('stroke', exitCleaningDischarged);
					}
					else if (status.ExitCleaningRequestStatus === 'Confirmed') {
						$(path).attr('fill-opacity', '0.5');
					}
					else {
						$(path).attr('fill-opacity', '0.15');
					}
					$(path).data('exitGuidAreaXCleaningTask', status.ExitGuidAreaXCleaningTask);
				}
				else {
					$(path).removeData('exitGuidAreaXCleaningTask');
				}

				if (status.HasInfectionCleaning) {
					$(path).attr('stroke', hasInfectionCleaning);
					$(path).attr('fill', hasInfectionCleaning);
					$(path).attr('fill-opacity', '0.4');
				}

				if (status.HasAdditionalCleaning) {
					$(path).attr('stroke-dasharray', '250,250');
					$(path).attr('stroke-linecap', 'square');
				}

				if (status.PeriodicCleaningDueDate) {
					markerService.addPeriodicIcon(path, status.GuidArea, status.PeriodicCleaningCountdown);
				}
			}
		});
	};

	service.getLegends = function (areas, showAll) {
        var legends = [
            { label: 'Rom med renholdsoppgaver', legendClass: 'legend-gray' },
			{ label: 'Skal rengjøres i dag', legendClass: 'legend-primary' },
			{ label: 'Rengjorte rom', legendClass: 'legend-success' },
		];
		if (showAll) {
			legends.push({ label: 'Utsatt i dag', legendClass: 'legend-warning-2' });
			legends.push({ label: 'Tidligere utsatt', legendClass: 'legend-danger' });
			legends.push({ label: 'Klart utreisevask', legendClass: 'legend-exitCleaning-discharged' });
			legends.push({ label: 'Bekreftet utreisevask', legendClass: 'legend-info' });
			legends.push({ label: 'Mulig utreisevask', legendClass: 'legend-info-2' });
			legends.push({ label: 'Smittevask', legendClass: 'legend-warning' });
			legends.push({ label: 'Rom med tilleggsoppgaver', legendClass: 'legend-dashed' });
			legends.push({ label: 'Periodisk renhold', imageSrc: 'images/svg-periodicMarker.png' });
		}

		if (showAll || !areas || areas.length === 0) return legends;

		if (_.some(areas, function (area) { return area.DelayedDate; }))
			legends.push({ label: 'Utsatt i dag', legendClass: 'legend-warning-2' });

		if (_.some(areas, function (area) { return moment(area.DelayedDate).isBefore(moment().format('YYYY-MM-DD')); }))
			legends.push({ label: 'Tidligere utsatt', legendClass: 'legend-danger' });

		if (_.some(areas, function (area) { return area.ExitGuidAreaXCleaningTask; })) {
			if (_.some(areas, function (area) { return area.ExitCleaningRequestStatus === 'Discharged'; }))
				legends.push({ label: 'Klart utreisevask', legendClass: 'legend-exitCleaning-discharged' });
			if (_.some(areas, function (area) { return area.ExitCleaningRequestStatus === 'Confirmed'; }))
				legends.push({ label: 'Bekreftet utreisevask', legendClass: 'legend-info' });
			else
				legends.push({ label: 'Mulig utreisevask', legendClass: 'legend-info-2' });
		}

		if (_.some(areas, function (area) { return area.HasInfectionCleaning; }))
			legends.push({ label: 'Smittevask', legendClass: 'legend-warning' });

		if (_.some(areas, function (area) { return area.HasAdditionalCleaning; }))
			legends.push({ label: 'Rom med tilleggsoppgaver', legendClass: 'legend-dashed' });

		if (_.some(areas, function (area) { return area.PeriodicCleaningDueDate; }))
			legends.push({ label: 'Periodisk renhold', imageSrc: 'images/svg-periodicMarker.png' });

        return legends;
	};

	service.onClick = function (guid, type, data) {
		var deferred = $q.defer();

		if (type === 'marker') {
			$modal.open({
				templateUrl: 'app/request/views/requestModal.html',
				controller: 'RequestModalController',
				animation: false,
				resolve: {
					params: function () { return { guidArea: guid }; }
				}
			}).result.then(function (result) {
				deferred.resolve();
			});
		} else {
			var tabs = {
				cleaningCompletion: { visible: repository.authService.hasCreateAccess(repository.apiData.cleaningCompletion.prefix), active: true },
				cleaningInfo: { visible: repository.authService.hasReadAccess(repository.apiData.areaXCleaningTask.prefix), active: false },
				cleaningRequest: { visible: repository.authService.hasCreateAccess(repository.apiData.areaXCleaningTask.prefix), active: false },
				technicalRequest: { visible: repository.authService.hasCreateAccess(repository.apiData.request.prefix), active: false },
			};

			$modal.open({
				templateUrl: 'app/dwg/views/dwgSelectionModal.html',
				size: 'lg',
				controller: 'DwgSelectionModalController',
                animation:false,
				resolve: {
					params: function () { return { guid: guid, tabInfo: tabs, data: data }; }
				}
			}).result.then(function (result) {
				deferred.resolve({ update: true });
			}, function (error) {
				if (error === 'cancel') deferred.resolve({});
				else deferred.reject();
			});
		}
		return deferred.promise;
	};

	service.hideMarkers = function () {
		$('#marker-coordinates').attr('visibility', 'hidden');
	};

	service.showMarkers = function () {
		$('#marker-coordinates').attr('visibility', 'visible');
	};

	var updateMarkers = function (areaGuids) {
		areaGuids.forEach(function (guid) {
			var elements = $('#' + guid).children();

			if (elements[0]) {
				var path = elements[0];
				markerService.addRequestMarker(path, guid);
			}
		});
	};

	service.getStatus = function (areas, guidDrawing) {
		dwgThemeService.removeEquipmentCategoryLabels(areas);

		var guidAreas = _.uniq(_.map(areas, 'GuidArea'));

		var requestFilter = {
			PropertyFilter: [
				{ Property: 'Status', Operator: '=', Value: '0' },
				{ Operand: 'OR', Property: 'Status', Operator: '=', Value: '1' },
				{ Operand: 'AND', Property: 'WorkOrder.EndDate', Operator: '=', Value: '' },
				{ Operand: 'AND', Property: 'Area.GuidDrawing', Operator: '=', Value: guidDrawing }
			]
		};

		repository.GetPaginated(repository.apiData.request.url, 0, 1000, {}, requestFilter, '', JSON.stringify(['Guid'])).then(function (result) {
			dwgThemeService.removePeriodicMarkers(areas);
			dwgThemeService.removeRequestMarkers(areas);

			var areaGuids = [];
			result.List.forEach(function (request) {
				if (!_.contains(areaGuids, request.GuidArea))
					areaGuids.push(request.GuidArea);
			});
			updateMarkers(areaGuids);
		}, function (error) {
			repository.growl(error, 'danger');
		});

		return repository.createMultiple(repository.apiData.cleaningStatus.url + 'cleaningStatus', areas)
			.then(function (result) {
				dwgThemeService.removeAreaIndicators(areas);
				updateIndicatorColours(result);
				return result;
			});
	};

	service.handleMultipleAreas = function (areas) {
		var deferred = $q.defer();
		$modal.open({
			templateUrl: 'app/cleaning/views/multipleCleaningCompletions.html',
			size: 'lg',
			controller: 'MultipleCleaningCompletionsController',
			animation: false,
			resolve: {
				params: function () { return { guids: areas}; }
			}
		}).result.then(function (result) {
			deferred.resolve({ update: true });
		}, function (error) {
			if (error === 'cancel') deferred.resolve({});
			else deferred.reject();
		});

		return deferred.promise;
	};

	return service;
}]);
